export const defaultSeoDetails = {
  title: "",
  metaKeywords: [],
  description: "",
  // headings: {
  //   h1: [],
  //   h2: [],
  //   h3: [],
  //   h4: [],
  //   h5: [],
  //   h6: [],
  // },

  favicon: "",
  page: "",
};
export const defaultOgDetails = {
  locale: "",
  type: "",
  title: "",
  description: "",
  url: "",
  siteName: "",
  image: "",
};
export const defaultTwitterDetails = {
  card: "",
  title: "",
  description: "",
  creator: "",
  siteName: "",
  image: "",
  label1: "",
  data1: "",
};

export const filteredPages = (path) => {
  const pages = {
    "/seo" : ["landing","faq","contact us","term", "privacy" , "about us"],
    "/seo-stories" : ["destination-stories","story"],
    "/seo-packages" : ["package"],
    "/seo-themes": ["theme"],
    "/seo-things-todo" : ["destination-things-todo","things todo"],
    "/seo-places-to-visit": ["places to visit"],
    "/seo-destinations" : ["destination"],
    "/seo-groups" : ["group","group-tours"]
  }
  return pages[path]
  
}

export const pages = [
  "landing",
  "destination",
  "destination-things-todo",
  "destination-stories",
  // "destination-cities",
  "group",
  "faq",
  "theme",
  "about us",
  "term",
  "package",
  "group-tours",
  "contact us",
  "privacy",
  "things todo",
  "places to visit",
  "story",
  // "international",
  // "domestic",
  "all-stories",
];
