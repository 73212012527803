import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultSocialLinksDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { uploadFile } from "../../shared/services";
import SeoDetails from "./SocialDetails";
import SeoFormService from "./service";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {};
  return yup.object().shape(commonSchema);
};

const SocialLinkForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();
  const { control } = useForm();

  const [socialLinkDetails, setSocialLinkDetails] = useState(
    formType === "edit" ? { ...data } : defaultSocialLinksDetails
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate({
        ...socialLinkDetails,
      });

      const payload = {
        ...data,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await SeoFormService.addSocial(payload)
          : await SeoFormService.editSocial(payload, data._id);
      isSuccess && navigate("/socail-link");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSocialDetailsChange = (name, value) => {
    setSocialLinkDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setSocialLinkDetails((data) => ({
        ...data,
        ["socialIcon"]: res?.fileUrl,
      }));
    } catch (error) {}
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <SeoDetails
          socialLinkDetails={socialLinkDetails}
          handleUploadFile={handleUploadFile}
          handleSocialDetailsChange={handleSocialDetailsChange}
          formType={formType}
          control={control}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default SocialLinkForm;
