import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchEmployees = async ({ currentPageLimit, currentPage, searchKey }) => {
  try {
    const response = await axiosInstance.get(
      `/admins?limit=${currentPageLimit}&page=${currentPage}&adminName=${searchKey}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const sendInvite = async (payload) => {
  const token = adminInfoGetterService();
  try {
    const response = await axiosInstance.post(`/admins/invite-admin`, payload, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const deleteUser = async (id) => {
  try {
    const { status } = await axiosInstance.delete(`/admins/${id}`, null);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

// const logoutUser = async (id) => {
//   try {
//     const { status } = await axiosInstance.post(`/admin/logoutAllDevices`, {
//       id,
//     });
//     return { isSuccess: status === 200 };
//   } catch (e) {
//     return {
//       error: e.response.data || e.message,
//     };
//   }
// };

export const EmployeeService = {
  // fetchUsers: cancellableRequest(fetchUsers),
  // fetchTrainers: cancellableRequest(fetchTrainers),
  deleteUser,
  // undoUser,
  // toggleUserStatus,
  // updateBlockStatus,
  // logoutUser,
  // remarkUpdate,
  fetchEmployees,
};
