import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import {
  getFormattedDate,
  getCommonColDef,
  getFormattedTime,
  getFormattedOnlyDate,
} from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "firstName",
      headerName: "Name",
      valueGetter: (params) =>
        params.row.firstName ? params.row.firstName : "N.A",
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "phoneNo",
      headerName: "Phone",
      valueGetter: (params) =>
        params.row.phoneNo ? params.row.phoneNo : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      valueGetter: (params) =>
        params.row.createdAt
          ? getFormattedOnlyDate(params.row.createdAt)
          : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      valueGetter: (params) =>
        params.row.createdAt ? getFormattedTime(params.row.createdAt) : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => (params.row.status ? params.row.status : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "resolveQuery",
      headerName: "Update Status",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/enquiry-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/enquiry`,
              },
            })
          }
          variant="outlined"
        >
          Update
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions facility={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
