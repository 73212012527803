import { axiosInstance } from "../../shared/axios";
import { cancellableRequest } from "../../shared/utils";

const fetchStats = async () => {
  try {
    const response = await axiosInstance.get(`/admins/get-analytics`);
    return response;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const StatsService = {
  fetchStats,
};
export default StatsService;

