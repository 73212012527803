import {
  Button,
  Avatar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";
import { Link } from "react-router-dom";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/faq-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/faq`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    {
      field: "question",
      headerName: "Questions",
      valueGetter: (params) =>
        params.row.question ? params.row.question : "N.A",
      // ...getCommonColDef(),
      sortable: false,
      headerAlign: "center",
      flex: 1.2,
    },
    {
      field: "answer",
      headerName: "Answer",
      valueGetter: (params) => (params.row.answer ? params.row.answer : "N.A"),
      // ...getCommonColDef(),
      // width: 100,
      sortable: false,
      headerAlign: "center",
      flex: 1.2,
    },
    {
      field: "type",
      headerName: "Type",
      valueGetter: (params) => (params.row.type ? params.row.type : "N.A"),
      // ...getCommonColDef(),
      // width: 100,
      sortable: false,
      headerAlign: "center",
      flex: 1.2,
    },
    {
      field: "isFlag",
      headerName: "isFlag",
      renderCell: (param) => <Actions faq={param.row} />,
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1.2,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions banner={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
