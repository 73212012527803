import { axiosInstance } from "../../shared/axios";

const editGroupTour = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/packages/${id}`, payload);
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const GroupToursFormService = { editGroupTour };
export default GroupToursFormService;
