import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchGroup,
  pageChanged,
  pageLimit,
  locationChanged,
} from "./group.slice";
import { getColumns } from "./columns-def";
import "./style.css";
import { adminRoleGetterService } from "../../shared/services";

const GroupView = () => {
  const { data, loading, total, currentPage, currentPageLimit, location } =
    useSelector((state) => state.group);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const user_role = adminRoleGetterService();

  useEffect(() => {
    if (user_role !== "Super") {
      dispatch(locationChanged(user_role));
    }
    dispatch(fetchGroup());
  }, [dispatch, currentPage, currentPageLimit, location]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Nested Group</Typography>

        <Button
          sx={{ width: "7rem" }}
          onClick={() =>
            navigate("/nestedGroup-form?formType=add", {
              state: {
                prevPath: `/nestedGroup`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default GroupView;
