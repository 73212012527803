import React, { useState } from "react";
import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";
import { defaultEnquiryDetails } from "./constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CallEnquiryDetails from "./CallEnquiryDetails";
import CallEnquiryFormService from "./service";
import { getFormattedDate } from "../../shared/utils";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {
    // title: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const CallEnquiryForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [enquiryDetails, setEnquiryDetails] = useState(
    formType === "edit" ? { ...data } : defaultEnquiryDetails
  );

  const [startDate, setStartDate] = useState(
    formType === "edit" ? getFormattedDate(data.startDate) : ""
  );
  const [endDate, setEndDate] = useState(
    formType === "edit" ? getFormattedDate(data.endDate) : ""
  );
  const [noOfPersons, setNoOfPersons] = useState(
    formType === "edit"
      ? data.noOfPersons
      : {
          adults: "",
          kids: "",
          child: "",
        }
  );
  const [hotelsCategory, setHotelsCategory] = useState(
    formType === "edit"
      ? data.hotelsCategory
      : {
          rating: "",
          type: "",
        }
  );
  const [priceQuotation, setPriceQuotation] = useState(
    formType === "edit" ? data.priceQuotation : ""
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...enquiryDetails,
        },
        { abortEarly: false }
      );

      const payload = {
        packageId: formType === "edit" ? data.packageId._id : data.packageId,
        name: data.name,
        phoneNo: data.phoneNo,
        email: data.email,
        startDate: startDate,
        endDate: endDate,
        startLocation: data.startLocation,
        endLocation: data.endLocation,
        query: data.query,
        status: data.status,
        comments: data.comments,
        cab: data.cab,
        to: data.to,
        from: data.from,
        hotelsCategory: {
          rating: +hotelsCategory.rating,
          type: hotelsCategory.type,
        },
        noOfPersons: {
          adults: +noOfPersons.adults,
          child: +noOfPersons.child,
          kids: +noOfPersons.kids,
        },
        isGroup: data.isGroup,
        groupSize: data.groupSize,
        priceQuotation: +priceQuotation,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await CallEnquiryFormService.addEnquiry(payload)
          : await CallEnquiryFormService.updateEnquiry(payload, data._id);

      isSuccess && navigate("/call-enquiry");
      setError(error);
      error && handleError(error);
    } catch (error) {
      // handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setEnquiryDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleNoOfPersonsChange = (name, value) => {
    setNoOfPersons((prev) => ({ ...prev, [name]: value }));
  };
  const handleHotelCategoryChange = (name, value) => {
    setHotelsCategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <CallEnquiryDetails
          CallEnquiryDetails={enquiryDetails}
          handleDetailsChange={handleDetailsChange}
          handleNoOfPersonsChange={handleNoOfPersonsChange}
          handleHotelCategoryChange={handleHotelCategoryChange}
          priceQuotation={priceQuotation}
          setPriceQuotation={setPriceQuotation}
          hotelsCategory={hotelsCategory}
          noOfPersons={noOfPersons}
          formType={formType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default CallEnquiryForm;
