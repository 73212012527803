import React from "react";

import { Grid, TextField, Typography } from "@mui/material";

import "./style.css";
import { DesktopDatePicker } from "@mui/lab";
import { getFormattedDate } from "../../shared/utils";

const SelectableDateRange = ({
  selectedDateRange: { startDate, endDate },
  handleDateSelection = () => {},
}) => {
  return (
    <>
      <Typography className="date_title">Date</Typography>
      <Grid container justifyContent="space-between">
        <Grid item xs={2}>
          <TextField
            name="startDate"
            type="date"
            helperText="Start date"
            defaultValue="08-24-2023"
            value={startDate}
            InputProps={{
              min: new Date().toISOString().slice(0, 16),
            }}
            onChange={handleDateSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="endDate"
            defaultValue="08-24-2023"
            type="date"
            helperText="End date"
            value={endDate}
            onChange={handleDateSelection}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SelectableDateRange;
