import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultReviewDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import ReviewDetails from "./ReviewDetails";
import ReviewFormService from "./service";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {
    title: yup
      .string()
      .required()
      .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),

    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const ReviewForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [reviewDetails, setReviewDetails] = useState(
    formType === "edit" ? { ...data } : defaultReviewDetails
  );

  const [content, setContent] = useState(formType === "edit" ? data.content : "")

  if (data === undefined) {
    var prevDestinationName = "";
    var prevPackageName = "";
    var prevTitleName = "";
    // var prevReviewName = "";
    var prevRatingName = "";
  } else {
    var prevDestinationName = data.destinationId;
    var prevPackageName = data.packageId;
    var prevTitleName = data.title;
    // var prevReviewName = data.review;
    var prevRatingName = data.rating;
  }

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...reviewDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        ...data,
        review: content
      };
      const { error, isSuccess } =
        formType === "add"
          ? await ReviewFormService.addReview(payload, payload._id)
          : await ReviewFormService.editReview(payload, payload._id);
      isSuccess && navigate("/review");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setReviewDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <ReviewDetails
          handleDetailsChange={handleDetailsChange}
          formType={formType}
          prevDestinationName={prevDestinationName}
          title={prevTitleName}
          // review={prevReviewName}
          rating={prevRatingName}
          prevPackageName={prevPackageName}
          content={content}
          setContent={setContent}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default ReviewForm;
