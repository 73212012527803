import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Card,
  CardMedia,
  Grid,
  Rating,
  TextField,
  Typography,
  Button,
  Box,
} from "@mui/material";
import TourService from "../package-form/service";
import StoryFormService from "./service";
import MultiInput from "../../shared/SelectMultiOptions";
import CancelIcon from "@mui/icons-material/Cancel";
import Editor from "../../shared/Editor";
import { Cancel } from "@mui/icons-material";

const StoryDetails = ({
  prevDestinationName,
  prevTitleName,
  prevUserName,
  image,
  prevTagsName,
  handleDetailsChange,
  handleUploadFile,
  handleMultipleUploadFile,
  content,
  setContent,
  imageGallery,
  resetImage,
  user_role,
  formType,
  setProfileId,
}) => {
  // const { register, setValue } = useForm({});

  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [userSuggestion, setUserSuggestion] = useState([]);

  const [destination, setDestination] = useState("");
  const [user, setUser] = useState("");

  const options = ["things todo", "places to visit", "normal"];

  const [keywords, setKeywords] = useState(prevTagsName);

  useEffect(() => {
    handleDetailsChange("tags", keywords);
  }, [keywords]);

  const handleDestinationSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await TourService.destinationSuggestion(value);
    if (isSuccess) {
      setDestinationSuggestion(data?.data);
      if (data?.data?.length < 2)
        handleDetailsChange("destinationId", data?.data[0]?._id);
    }
  };

  const removeFile = () => {
    handleDetailsChange("image", "");
  };

  const handleUserSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await StoryFormService.profileSuggestion(value);
    if (isSuccess) {
      setUserSuggestion(data?.data);
      if (data?.data?.length < 2) setProfileId(data?.data[0]?._id);
    }
  };

  const [changed, setChanged] = useState(false);

  useEffect(() => {}, [changed]);

  useEffect(() => {
    handleDestinationSuggestion(destination);
    handleUserSuggestion(user);
  }, []);

  return (
    <>
      <Typography className="heading">Story Details</Typography>

      <Autocomplete
        id="status"
        options={options}
        value={"normal"}
        // value={searchKey}
        disabled
        onChange={(event, newValue) => handleDetailsChange("type", "normal")}
        renderInput={(params) => (
          <TextField {...params} label="Type" variant="outlined" fullWidth />
        )}
      />

      <hr />
      <br />

      <>
        <TextField
          label="Title"
          variant="outlined"
          defaultValue={prevTitleName}
          onChange={(e) => handleDetailsChange("title", e.target.value)}
          fullWidth
        />

        <Autocomplete
          id="destination"
          options={destinationSuggestion?.map((option) => option.name)}
          defaultValue={prevDestinationName.name}
          onChange={(event, newValue) => {
            handleDestinationSuggestion(newValue);
          }}
          disabled={user_role !== "Super" && formType === "edit"}
          getOptionLabel={(option) => (option ? option : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Destination"
              variant="outlined"
              fullWidth
            />
          )}
        />

        <Editor
          content={content}
          setContent={setContent}
          placeholder={"Content"}
        />

        <Grid item>
          <h4>Image</h4>
          {image ? (
            <>
              <CancelIcon
                sx={{
                  position: "relative",
                  top: "1.8rem",
                  left: "15.9rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={removeFile}
              />
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia component="img" image={image} />
              </Card>
              <br />
              <TextField
                helperText="select images"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={handleUploadFile}
              />
            </>
          ) : (
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          )}
        </Grid>

        {imageGallery.length > 0 && (
          <Grid item spacing={1} xs={12} sm={12} md={12}>
            <Typography>Images</Typography>
            <div style={{ display: "flex" }}>
              {imageGallery.map((item, index) => (
                <>
                  <Cancel
                    sx={{
                      position: "relative",
                      top: "-0.5rem",
                      left: "7rem",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => resetImage(index)}
                  />
                  <img src={item} height="100px" width="100px"></img>
                </>
              ))}
            </div>
          </Grid>
        )}

        <TextField
          type="file"
          inputProps={{
            multiple: true,
          }}
          helperText="Images"
          onChange={handleMultipleUploadFile}
        />

        {/* <Autocomplete
            id="status"
            options={options}
            defaultValue={prevTypeName}
            // value={searchKey}
            onChange={(event, newValue) =>
              handleDetailsChange("type", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type"
                variant="outlined"
                fullWidth
              />
            )}
          /> */}

        <MultiInput
          onChange={(e) => setKeywords(e.target.value)}
          setItinerary={setKeywords}
          itinerary={keywords}
          label="Add Tags"
        />

        <Autocomplete
          id="user"
          options={userSuggestion?.map((option) => option.name)}
          defaultValue={prevUserName?.name}
          onChange={(event, newValue) => {
            handleUserSuggestion(newValue);
          }}
          getOptionLabel={(option) => (option ? option : "")}
          renderInput={(params) => (
            <TextField {...params} label="User" variant="outlined" fullWidth />
          )}
        />
      </>
    </>
  );
};

export default StoryDetails;
