import React, { Fragment, useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";

import Editor from "../../shared/Editor";
import TourService, { GroupService } from "./service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { uploadFile, uploadMultiFile } from "../../shared/services";
import "./style.css";
import GroupDetails from "./GroupDetails";
import { adminRoleGetterService } from "../../shared/services";

const GroupForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();
  const user_role = adminRoleGetterService();

  const [groupId, setGroupId] = useState("");
  const [featImage, setFeatImage] = useState(data ? data.featuredImage : "");
  const [error, setError] = useState("");

  const [destinationId, setDestinationId] = useState(
    data ? data.destinationId?._id : ""
  );

  useEffect(() => {
    setValue("featuredImage", featImage);
  }, [featImage]);

  useEffect(() => {
    setValue("destinationId", destinationId);
  }, [data]);

  useEffect(() => {
    setGroupId(data ? data._id : "");
  }, [data]);

  const { control, handleSubmit, register, setValue, getValues } = useForm({
    defaultValues: {
      name: formType === "edit" ? data.name : "",
      destinationId: formType === "edit" ? data.destinationId._id : "",
      destinationName: formType === "edit" ? data.destinationId.name : "",
      packages:
        formType === "edit" ? data.packages?.map((item) => item._id) : [],
      packagesName:
        formType === "edit" ? data.packages?.map((item) => item.title) : "",
      description: formType === "edit" ? data.description : "",
      featuredImage: formType === "edit" ? data.featuredImage : featImage,
    },
  });

  const navigate = useNavigate();

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];
    let formData = new FormData();
    formData.append("file", files);
    let payload = formData;

    try {
      const { fileUrl, isSuccess, error } = await uploadFile(payload);
      isSuccess && setFeatImage(fileUrl);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleFormSubmit = async (data) => {
    const payload = {
      name: data.name,
      destinationId: data.destinationId,
      packages: data.packages,
      description: data.description,
      featuredImage: data.featuredImage ? data.featuredImage : "",
    };

    const { error, isSuccess } =
      formType === "add"
        ? await GroupService.addGroup(payload)
        : await GroupService.editGroup(payload, groupId);
    isSuccess && navigate("/nestedGroup");
    error && handleError(error);
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="package-form-container">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <GroupDetails
          groupDetails={getValues()}
          register={register}
          setValue={setValue}
          user_role={user_role}
          formType={formType}
          control={control}
          featImage={featImage}
          setFeatImage={setFeatImage}
          handleUploadFile={handleUploadFile}
        />

        <Button
          type="submit"
          className="package-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default GroupForm;
