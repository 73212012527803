import React, { useState, useEffect } from "react";

import {
  TextField,
  Autocomplete,
  Chip,
  Grid,
  Typography,
  MenuItem,
  IconButton,
  InputAdornment,
  Rating,
  Container,
  TextareaAutosize,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";

const TodaysTopDealDetails = ({
  todaysTopDealDetails: {
    title,
    days,
    nights,
    startlocation,
    endlocation,
    popular,
    groupTour,
    todayTopDeal,
  },
  handleDetailsChange,
  formType,
}) => {
  const TrueFalseOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];

  return (
    <>
      <Typography className="heading">Todays Top Deal Details</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            disabled
            onChange={(e) => handleDetailsChange("title", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            label="Days"
            variant="outlined"
            value={days}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            label="Night"
            variant="outlined"
            value={nights}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            label="Start Location"
            variant="outlined"
            value={startlocation}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            label="End Location"
            variant="outlined"
            value={endlocation}
            disabled
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            disabled
            defaultValue={groupTour}
            label="Group Tour"
            onChange={(e) => handleDetailsChange("groupTour", e.target.value)}
          >
            {TrueFalseOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            disabled
            defaultValue={popular}
            label="Group Tour"
            onChange={(e) => handleDetailsChange("popular", e.target.value)}
          >
            {TrueFalseOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            defaultValue={todayTopDeal}
            label="Group Tour"
            onChange={(e) =>
              handleDetailsChange("todayTopDeal", e.target.value)
            }
          >
            {TrueFalseOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default TodaysTopDealDetails;
