import React, { useEffect} from "react";

import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getColumns } from "./columns-def";
import {
  fetchSitemapData,
  pageChanged,
  pageLimit,
  removeSitemap,
  searchKeyChanged,
  updateSitemap,
} from "./sitemap.slice";
import { fetchDestination } from "../tours/tour.slice";
import "./style.css";

const SitemapView = () => {
  const {
    data,
    loading,
    total,
    currentPage,
    sortingInfo,
    searchKey,
    currentPageLimit,
  } = useSelector((state) => state.sitemap);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    dispatch(fetchSitemapData());
  }, [
    dispatch,
    currentPage,
    viewType,
    sortingInfo,
    searchKey,
    currentPageLimit,
  ]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Sitemap</Typography>
      </Grid>
      <Grid
        container
        className="userview-navbar"
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label={`Search by URL`}
            value={searchKey}
            onChange={(e) => {
              dispatch(searchKeyChanged(e.target.value))}}
            size="small"
            fullWidth
          />
        </Grid>
        <></>
        <Grid container xs={12} md={7} sm={7} sx={{display: "flex" , justifyContent : "space-between" , alignItems: "center"}}>
          <Grid item xs={3.5} md={3.5} sm={3.5}>
            <Button
              className="nav-button"
              onClick={() => {
                dispatch(pageChanged(0))
                dispatch(updateSitemap())
              }}
              variant="contained"
            >
              Refresh Sitemap
            </Button>
          </Grid>
          <Grid item xs={3.5} md={3.5} sm={3.5}>
            <Button
              className="nav-button"
              onClick={() => {
                dispatch(pageChanged(0))
                dispatch(removeSitemap())
              }}
              variant="contained"
            >
              Remove Sitemap
            </Button>
          </Grid>
          <Grid item xs={3.5} md={3.5} sm={3.5}>
            <Button
              className="nav-button"
              onClick={() =>
                window.open("https://travelhangouts.com/sitemap.xml")
              }
              variant="contained"
            >
              Check Sitemap
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => {
          console.log({newPage})
          dispatch(pageChanged(newPage))}}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default SitemapView;
