import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CodeSnippetService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchState: "",
};

export const fetchCodeSnippetData = createAsyncThunk(
  "social/fetchCodeSnippetData",
  async (viewType, thunkAPI) => {
    const { searchKey, currentPage, currentPageLimit, searchState } =
      thunkAPI.getState().codeSnippet;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchState,
    };
    const data = await CodeSnippetService.fetchCodeSnippetData(payload);

    return data;
  }
);

export const deleteCodeSnippet = createAsyncThunk(
  "codeSnippet/deleteCodeSnippet",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await CodeSnippetService.deleteCodeSnippet(id);
    if (isSuccess) {
      thunkAPI.dispatch(fetchCodeSnippetData());
    }
    return thunkAPI.rejectWithValue(error);
  }
);
export const updateStatus = createAsyncThunk(
  "codeSnippet/updateStatus",
  async ({ id, payload }, thunkAPI) => {
    const { isSuccess, error } = await CodeSnippetService.updateStatus({
      id,
      payload,
    });
    if (isSuccess) {
      thunkAPI.dispatch(fetchCodeSnippetData());
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const codeSnippetSlice = createSlice({
  name: "codeSnippet",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
    },
    searchStateChanged(state, action) {
      state.searchState = action.payload;
    },
    pageLeft(state) {
      state.searchState = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCodeSnippetData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchCodeSnippetData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchStateChanged,
  pageLeft,
  pageLimit,
} = codeSnippetSlice.actions;
export default codeSnippetSlice.reducer;
