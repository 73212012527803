import { axiosInstance } from "../../shared/axios";

const fetchCodeSnippetData = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  searchState,
}) => {
  try {
    const response = await axiosInstance.get(
      `/code-snippet?limit=${currentPageLimit}&page=${currentPage}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const deleteCodeSnippet = async (id = "") => {
  try {
    const { status } = await axiosInstance.delete(`/code-snippet/${id}`, null);
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};
const updateStatus = async ({ id, payload }) => {
  try {
    const { status } = await axiosInstance.put(`/code-snippet/${id}`, {
      payload,
    });
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const CodeSnippetService = {
  fetchCodeSnippetData,
  updateStatus,
  deleteCodeSnippet,
};
