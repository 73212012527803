import React, { useEffect, useState} from "react";

import {
  Button,
  Container,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  dataChanged,
  fetchRobotsFile,
  updateRobotsFile,
} from "./robots-file.slice";
import "./style.css";

const RobotsFileView = () => {
  const {
    data,
    loading,
  } = useSelector((state) => state.robotsFile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    dispatch(fetchRobotsFile());
  }, [
    dispatch,
    viewType
  ]);

  const handleChange = (event) => {
    dispatch(dataChanged(event.target.value))
  }

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Robots.txt File</Typography>
      </Grid>
      <Grid
        container
        className="userview-navbar"
        gap={2}
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <Grid item xs={12} sm={12} md={12}>
        <Typography sx={{fontWeight : "bold"}}>Content in Robots.txt File</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextareaAutosize
            minRows={12}
            maxRows={22}
            style={{width : "100%" , padding: "1rem"}}
            value={data}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Button
            className="nav-button"
            onClick={() => dispatch(updateRobotsFile({newrobotTxt : data}))}
            variant="contained"
          >
            Update File
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RobotsFileView;
