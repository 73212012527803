import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SitemapService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
};

export const fetchSitemapData = createAsyncThunk(
  "sitemap/fetchSitemap",
  async (viewType, thunkAPI) => {
    const {
      searchKey,
      currentPage,
      currentPageLimit,
      searchState,
    } = thunkAPI.getState().sitemap;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchState,
    };
    console.log({payload})
    const data = await SitemapService.fetchSitemap(payload);
    return data;
  }
);

export const updateSitemap = createAsyncThunk(
  "sitemap/updateSitemap" , 
  async ( _ , thunkAPI) => {
    const {isSuccess , error} = await SitemapService.updateSitemap();
    if (isSuccess) {
      thunkAPI.dispatch(fetchSitemapData());
      return isSuccess;
    }
    return thunkAPI.rejectWithValue(error);
  }
)

export const removeSitemap = createAsyncThunk(
  "sitemap/removeSitemap" , 
  async ( _ , thunkAPI) => {
    const {isSuccess , error} = await SitemapService.removeSitemap();
    if (isSuccess) {
      thunkAPI.dispatch(fetchSitemapData());
      return isSuccess;
    }
    return thunkAPI.rejectWithValue(error);
  }
)

const sitemapSlice = createSlice({
  name: "sitemap",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
      state.currentPage = 0
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSitemapData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchSitemapData.fulfilled, (state, action) => {
      console.log(action.payload , "action.payload")
      state.data = action.payload.data;
      state.total = action.payload.totalcount;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  searchKeyChanged,
  pageLimit,
} = sitemapSlice.actions;
export default sitemapSlice.reducer;
