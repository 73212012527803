import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchStoryData,
  fetchDestination,
  pageChanged,
  pageLeft,
  pageLimit,
  searchLocationChanged,
  searchTitleChanged,
  sortingChanged,
} from "./story.slice";
import { getColumns } from "./columns-def";
import "./style.css";
import { adminRoleGetterService } from "../../shared/services";

const PlaceView = () => {
  const {
    data,
    destinationData,
    loading,
    total,
    currentPage,
    searchKey,
    searchByTitle,
    searchByLocation,
    currentPageLimit,
    remarkStatus,
  } = useSelector((state) => state.story);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const viewType = params.get("view-type");
  const user_role = adminRoleGetterService();

  useEffect(() => {
    dispatch(fetchDestination());
  }, []);

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    if (user_role !== "Super") {
      dispatch(searchLocationChanged(user_role));
    }
    dispatch(fetchStoryData());
  }, [
    dispatch,
    currentPage,
    viewType,
    searchKey,
    searchByTitle,
    searchByLocation,
    currentPageLimit,
    remarkStatus,
  ]);

  const [open, setOpen] = useState(false);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Famous Places</Typography>
        <Button
          sx={{ width: "7rem" }}
          onClick={() =>
            navigate("/famous-places-form?formType=add", {
              state: {
                prevPath: `/famous-places`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>

      <Grid
        container
        className="userview-navbar"
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <Grid item xs={4} md={4}>
          <TextField
            label={`Search by Title`}
            value={searchByTitle}
            className="tour-filters"
            onChange={(e) => dispatch(searchTitleChanged(e.target.value))}
            fullWidth
            size="small"
          />
        </Grid>

        {user_role === "Super" && (
          <Grid item xs={4} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel htmlFor="Location">Location</InputLabel>
              <Select
                id="location"
                label="Location"
                onChange={(e) =>
                  dispatch(searchLocationChanged(e.target.value))
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                  International -
                </ListSubheader>
                {destinationData?.map(
                  (items) =>
                    items.domestic === false && (
                      <MenuItem value={items.name}>{items.name}</MenuItem>
                    )
                )}
                <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                  Domestic -
                </ListSubheader>
                {destinationData?.map(
                  (items) =>
                    items.domestic === true && (
                      <MenuItem value={items.name}>{items.name}</MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default PlaceView;
