import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { TodaysTopDealService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
};

export const fetchTodaysTopDeal = createAsyncThunk(
  "todaysTopDeal/fetchTodaysTopDeal",
  async (viewType, thunkAPI) => {
    const { searchKey, currentPage, currentPageLimit } =
      thunkAPI.getState().todaysTopDeal;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
    };
    const data = await TodaysTopDealService.fetchTodaysTopDeal(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return data;
  }
);

const todaysTopDealSlice = createSlice({
  name: "todaysTopDeal",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTodaysTopDeal.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchTodaysTopDeal.fulfilled, (state, action) => {
      state.data = action.payload.data.activeTopDeals;
      state.total = action.payload.totalData;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  pageLeft,
  pageLimit,
} = todaysTopDealSlice.actions;
export default todaysTopDealSlice.reducer;
