import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/seo-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/seo`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      valueGetter: (params) => (params.row.title ? params.row.title : "N.A"),
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "slug",
      headerName: "Slug",
      valueGetter: (params) => (params.row.slug ? params.row.slug : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "page",
      headerName: "Page",
      valueGetter: (params) => (params.row.page ? params.row.page : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   valueGetter: (params) =>
    //     params.row.page === "destination"
    //       ? params.row.destinationId.name
    //       : "N.A",
    //   ...getCommonColDef(),
    //   sortable: false,
    //   // width: 300,
    //   flex: 1,
    // },
    {
      field: "metaDescription",
      headerName: "Meta Description",
      valueGetter: (params) =>
        params.row.description ? params.row.description : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1.5,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions facility={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
