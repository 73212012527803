import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType, user_role) => {
  const cols = [
    {
      field: "title",
      headerName: "Title",
      renderCell: (param) => (param.row.title ? param.row.title : "NA"),
      headerAlign: "center",
      width: 370,
      sortable: false,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate(
              viewType === "tour-themes"
                ? "/theme-form?formType=edit"
                : "/package-form?formType=edit",
              {
                state: {
                  data: param.row,
                  prevPath: `/tour?view-type=${viewType}`,
                },
              }
            )
          }
          disabled={user_role !== "Super" && viewType === "tour-themes"}
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "destination",
      headerName: "Destination",
      valueGetter: (param) =>
        param.row.destination ? param.row.destination.name : "NA",
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "days",
      headerName: "Days",
      valueGetter: (param) => (param.row.days ? param.row.days : "NA"),
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "basePrice",
      headerName: "Base Price",
      valueGetter: (param) =>
        param.row.basePrice ? param.row.basePrice : "NA",
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "ratings",
      headerName: "Rating",
      valueGetter: (param) => (param.row.ratings ? param.row.ratings : "NA"),
      ...getCommonColDef(),
      sortable: false,
    },

    {
      field: "startLocation",
      headerName: "Start Location",
      valueGetter: (param) =>
        param.row.startlocation ? param.row.startlocation : "NA",
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "endLocation",
      headerName: "End Location",
      valueGetter: (param) =>
        param.row.endlocation ? param.row.endlocation : "NA",
      ...getCommonColDef(),
      sortable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions user_role={user_role} tour={param.row} />,
      ...getCommonColDef(),
      sortable: false,
    },
  ];
  if (viewType === "tour-themes") {
    // cols.unshift(
    //   {
    //     field: "description",
    //     headerName: "Description",
    //     renderCell: (param) => (param.row.description ? param.row.description.replace(/<[^>]+>/g, '') : "NA"),
    //     ...getCommonColDef(),
    //     width: 350,
    //     sortable: false,
    //   },
    // )
    cols.unshift(
      {
        field: "name",
        headerName: "Title",
        renderCell: (param) => (param.row.name ? param.row.name : "NA"),
        ...getCommonColDef(),
        sortable: false,
      },
      {
        field: "priority",
        headerName: "Priority",
        renderCell: (param) => (param.row.priority ? param.row.priority : "NA"),
        ...getCommonColDef(),
        sortable: false,
      }
    );
    cols.splice(2, 1);
    cols.splice(3, 6);
  }
  return cols;
};
