import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoryService } from "./service";

const initialState = {
  total: 0,
  data: [],
  destinationData: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchByTitle: "",
  searchByLocation: "",
};

export const fetchStoryData = createAsyncThunk(
  "story/fetchStory",
  async (viewType, thunkAPI) => {
    const {
      searchKey,
      searchByTitle,
      searchByLocation,
      currentPage,
      currentPageLimit,
    } = thunkAPI.getState().story;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchByTitle,
      searchByLocation,
    };
    const data = await StoryService.fetchStory(payload);
    return data;
  }
);

export const deleteThingsToDo = createAsyncThunk(
  "story/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await StoryService.deleteThingsToDo(id);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    thunkAPI.dispatch(fetchStoryData());
    return { id };
  }
);
export const fetchDestination = createAsyncThunk(
  "story/fetchDestination",
  async (_, thunkAPI) => {
    const { data, error } = await StoryService.fetchDestination();

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data };
  }
);

const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    searchTitleChanged(state, action) {
      state.searchByTitle = action.payload;
    },
    searchLocationChanged(state, action) {
      state.searchByLocation = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByLocation = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStoryData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchStoryData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = "succeeded";
    });
    builder.addCase(fetchDestination.pending, (state) => {
      state.loading = "pending";
      state.destinationData = [];
    });
    builder.addCase(fetchDestination.fulfilled, (state, action) => {
      state.destinationData = action.payload.data;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  pageLeft,
  pageLimit,
  searchLocationChanged,
  searchTitleChanged,
} = storySlice.actions;
export default storySlice.reducer;
