import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./style.css";

const MultiInput = ({ itinerary, setItinerary, label }) => {
  const [value, setvalue] = useState("");
  const [error, seterror] = useState(null);
  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      var newValue = value.trim();
      if (newValue && isValid(newValue)) {
        setItinerary([...itinerary, newValue]);
        setvalue("");
      }
    }
  };
  const handleChange = (evt) => {
    setvalue(evt.target.value);
    seterror(null);
  };

  const handleDelete = (item) => {
    setItinerary(itinerary.filter((i) => i !== item));
  };
  const handlePaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter((email) => !isInitinerary(email));
      setItinerary([...this.itinerary, ...toBeAdded]);
    }
  };
  const isValid = (email) => {
    let error = null;
    // if (isInemailList(email)) {
    //   error = `${email} has already been added.`;
    // }
    // if (!isEmail(email)) {
    //   error = `${email} is not a valid email address.`;
    // }
    // if (error) {
    //   seterror({ error });
    //   return false;
    // }
    return true;
  };
  const isInitinerary = (email) => {
    return itinerary.includes(email);
  };
  const isEmail = (email) => {
    // return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    return true;
  };
  return (
    <>
      {
        <>
          <label>Keywords</label>
          <div className="outer-div">
            {itinerary.map((item) => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </>
      }

      <h5>{label}</h5>
      <input
        className={"input " + (error && " has-error")}
        value={value}
        placeholder="Add Keywords and press `Enter`..."
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      <hr />
      {error && <p className="error">{error}</p>}
    </>
  );
};
export default MultiInput;
