export const defaultHeroSectionEditDetails = {
    title: "",
    subTitle: "",
    imgUrl: "",
  // bottomLand: [
  //   {
  //     subTitle: "",
  //     title: "",
  //     iconUrl: "",
  //   },
  // ],
 
};
