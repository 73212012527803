import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StatsService from "./service";

const initialState = {
  activeUsers: null,
  clients: null,
  enquiries: null,
  packages: null,
};

export const fetchStats = createAsyncThunk(
  "stats/fetchStats",
  async (thunkAPI) => {
    const response = await StatsService.fetchStats();
    if (response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
  
);

const statsSlice = createSlice({
  name: "stats",
  initialState,
  // reducers: {
  //   yearChanged: (state, action) => {
  //     state.selectedYear = action.payload;
  //   },
  //   monthChanged: (state, action) => {
  //     state.selectedMonth = action.payload;
  //   },
  //   dateChanged: (state, action) => {
  //     state.selectedDate = action.payload;
  //   },
  //   searchKeyChanged(state, action) {
  //     state.searchKey = action.payload;
  //   },
  //   clearFilters: (state) => {
  //     state.selectedDate = "";
  //     state.selectedMonth = "";
  //     state.selectedYear = "";
  //     state.searchKey = "";
  //   },
  //   clearSearchKey: (state) => {
  //     state.searchKey = "";
  //   },

  //   clearMonthYear: (state) => {
  //     state.selectedMonth = "";
  //     state.selectedYear = "";
  //   },
  //   clearDate: (state) => {
  //     state.selectedDate = "";
  //   },
  //   clearMonth: (state) => {
  //     state.selectedMonth = "";
  //   },
  //   clearYear: (state) => {
  //     state.selectedYear = "";
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(fetchStats.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchStats.rejected, (state, action) => {
      state.loading = "failed";
    });
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      state.activeUsers = action.payload.data.data.activeUsers;
      state.clients = action.payload.data.data.clients;
      state.enquiries = action.payload.data.data.enquiries;
      state.packages = action.payload.data.data.packages;
      // state.stats = action.payload;
      state.loading = "succeeded";
    });
  },
});

export default statsSlice.reducer;
// export const {
//   yearChanged,
//   monthChanged,
//   dateChanged,
//   clearFilters,
//   clearMonthYear,
//   clearMonth,
//   clearYear,
//   clearDate,
//   clearSearchKey,
//   searchKeyChanged,
// } = statsSlice.actions;
