import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate) => {
  const cols = [
   
    {
      field: "name",
      headerName: "Name",
      renderCell: (param) => (param.row.name ? param.row.name : "NA"),
    flex:2,
      sortable: false,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate(             
                "/nestedGroup-form?formType=edit",
              {
                state: {
                  data: param.row,
                  prevPath: `/nestedGroup`,
                },
              }
            )
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      flex:1,
      sortable: false,
    },

    {
      field: "destination",
      headerName: "Destination",
      renderCell: (param) => (param.row.destinationId?.name ? param.row.destinationId.name : "NA"),
      ...getCommonColDef(),
      flex:1,
      sortable: false,
    },
    {
      field: "packages",
      headerName: "packages",
      valueGetter: (param) =>
      param.row.packages?.length > 0 ? 
      param.row.packages?.map(({ title }) => title).join(", ") : "N.A.",
      ...getCommonColDef(),
      flex:1,
      sortable: false,
    },
   
  

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions group={param.row} />,
      ...getCommonColDef(),
      flex:1,
    },

  
  ];
 

  return cols;
};
