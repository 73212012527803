import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  enquiries,
  TrueFalseOptions,
  cabsOptions,
  hotelRatingOption,
  hotelTypeOption,
} from "./constants";

import CallEnquiryFormService from "./service";

const CallEnquiryDetails = ({
  CallEnquiryDetails: {
    packageId,
    name,
    phoneNo,
    email,
    startLocation,
    endLocation,
    query,
    comments,
    status,
    cab,
    to,
    from,
    isGroup,
    groupSize,
  },
  noOfPersons: { adults, kids, child },
  handleDetailsChange,
  handleNoOfPersonsChange,
  handleHotelCategoryChange,
  hotelsCategory: { rating, type },
  formType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  priceQuotation,
  setPriceQuotation,
}) => {
  const [packageSuggestion, setPackageSuggestion] = useState([]);
  const [groupPackageSuggestion, setGroupPackageSuggestion] = useState([]);
  const handlePackageSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await CallEnquiryFormService.packageSuggestion(
      value
    );
    if (isSuccess) {
      setPackageSuggestion(data?.data);
      if (data?.data?.length < 2)
        handleDetailsChange("packageId", data?.data[0]?._id);
    }
  };
  const handleGroupPackageSuggestion = async () => {
    const { data, isSuccess } =
      await CallEnquiryFormService.groupPackageSuggestion();

    if (isSuccess) {
      setGroupPackageSuggestion(data?.data);
    }
  };

  useEffect(() => {
    handlePackageSuggestion("");
    handleGroupPackageSuggestion();
  }, []);

  return (
    <>
      <Typography className="heading">Update Enquiry Status</Typography>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="User Name"
            value={name}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("name", e.target.value)}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Phone Number"
            value={phoneNo}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("phoneNo", e.target.value)}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="User Email"
            value={email}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("email", e.target.value)}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Start Location"
            value={startLocation}
            variant="outlined"
            fullWidth
            onChange={(e) =>
              handleDetailsChange("startLocation", e.target.value)
            }
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="End Location"
            value={endLocation}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("endLocation", e.target.value)}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            fullWidth
            select
            defaultValue={isGroup}
            label="Group Tour"
            onChange={(e) => handleDetailsChange("isGroup", e.target.value)}
          >
            {TrueFalseOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Group Size"
            type="number"
            fullWidth
            disabled={isGroup === false}
            defaultValue={isGroup === false ? 0 : ""}
            value={isGroup === false ? 0 : groupSize}
            inputProps={{ min: 1 }}
            onChange={(e) => handleDetailsChange("groupSize", e.target.value)}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <TextField
            label="Adult"
            type="number"
            fullWidth
            disabled={isGroup === false}
            defaultValue={isGroup === false ? 0 : ""}
            inputProps={{ min: 0 }}
            value={isGroup === false ? 0 : adults}
            onChange={(e) => handleNoOfPersonsChange("adults", e.target.value)}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <TextField
            label="Child"
            type="number"
            fullWidth
            disabled={isGroup === false}
            defaultValue={isGroup === false ? 0 : ""}
            inputProps={{ min: 0 }}
            value={isGroup === false ? 0 : child}
            onChange={(e) => handleNoOfPersonsChange("child", e.target.value)}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <TextField
            label="Kids"
            type="number"
            fullWidth
            disabled={isGroup === false}
            defaultValue={isGroup === false ? 0 : ""}
            inputProps={{ min: 0 }}
            value={kids}
            onChange={(e) => handleNoOfPersonsChange("kids", e.target.value)}
          />
        </Grid>
        {/* <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Pick Up"
            value={from}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("from", e.target.value)}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            label="Drop"
            value={to}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("to", e.target.value)}
          />
        </Grid> */}
        <Grid item md={6} sm={6} xs={6}>
          <Autocomplete
            id="rating"
            options={hotelRatingOption}
            value={rating}
            defaultValue={rating}
            onChange={(event, newValue) =>
              handleHotelCategoryChange("rating", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Hotel Rating"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <Autocomplete
            id="type"
            options={hotelTypeOption}
            value={type}
            defaultValue={type}
            onChange={(event, newValue) =>
              handleHotelCategoryChange("type", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Hotel Type"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        {formType === "edit" && (
          <>
            <Typography
              sx={{ fontSize: "1rem", marginTop: "0.5rem", color: "gray" }}
            >
              Selected Package
            </Typography>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                value={packageId.title}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
          </>
        )}
        {isGroup === false ? (
          <Grid item md={6} sm={6} xs={6}>
            <Autocomplete
              id="package"
              options={packageSuggestion?.map((option) => option.title)}
              // defaultValue={packageId._id}
              onChange={(event, newValue) => {
                handlePackageSuggestion(newValue);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Package"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        ) : (
          <Grid item md={6} sm={6} xs={6}>
            <TextField
              fullWidth
              select
              label="Select Other Package"
              onChange={(e) => handleDetailsChange("packageId", e.target.value)}
            >
              {groupPackageSuggestion.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item md={6} sm={6} xs={6}>
          <Autocomplete
            id="cab"
            options={cabsOptions}
            value={cab}
            defaultValue={status}
            onChange={(event, newValue) => handleDetailsChange("cab", newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Cab" variant="outlined" fullWidth />
            )}
          />
        </Grid>

        {formType === "edit" && (
          <Grid item md={12} sm={12} xs={12}>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item md={5} sm={5} xs={5}>
                <TextField
                  helperText="Start Date"
                  disabled
                  value={startDate}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={5} sm={5} xs={5}>
                <TextField
                  helperText="End Date"
                  disabled
                  value={endDate}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item md={5} sm={5} xs={5}>
              <TextField
                helperText="Start Date"
                type="date"
                value={startDate}
                variant="outlined"
                fullWidth
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item md={5} sm={5} xs={5}>
              <TextField
                helperText="End Date"
                type="date"
                value={endDate}
                variant="outlined"
                fullWidth
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Query"
            value={query}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("query", e.target.value)}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Price Quotation"
            value={priceQuotation}
            type="number"
            variant="outlined"
            fullWidth
            onChange={(e) => setPriceQuotation(e.target.value)}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Comment"
            value={comments}
            variant="outlined"
            fullWidth
            onChange={(e) => handleDetailsChange("comments", e.target.value)}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Autocomplete
            id="status"
            options={enquiries}
            value={status}
            defaultValue={status}
            // onChange={(event, newValue) => {
            //   handleDestinationSuggestion(newValue);
            // }}
            onChange={(event, newValue) =>
              handleDetailsChange("status", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CallEnquiryDetails;
