import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CallEnquiryService } from "./service";

const initialState = {
  total: 0,
  data: [],
  destinationData: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchByLocation: "",
  status: "",
};

export const fetchCallEnquiryData = createAsyncThunk(
  "callEnquiry/fetchCallEnquiry",
  async ({ startDate, endDate }, thunkAPI) => {
    const {
      searchKey,
      currentPage,
      currentPageLimit,
      status,
      searchByLocation,
    } = thunkAPI.getState().callEnquiry;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchByLocation,
      status,
      startDate,
      endDate,
    };
    const { data, count } = await CallEnquiryService.fetchCallEnquiry(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return { data, count };
  }
);

export const fetchDestination = createAsyncThunk(
  "callEnquiry/fetchDestination",
  async (_, thunkAPI) => {
    const { data, error } = await CallEnquiryService.fetchDestination();
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data };
  }
);

const callEnquirySlice = createSlice({
  name: "callEnquiry",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    searchLocationChanged(state, action) {
      state.searchByLocation = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByLocation = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
    statusChanged(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCallEnquiryData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchCallEnquiryData.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.total = action.payload.data.totalData;
      state.loading = "succeeded";
    });
    builder.addCase(fetchCallEnquiryData.rejected, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchDestination.pending, (state) => {
      state.loading = "pending";
      state.destinationData = [];
    });
    builder.addCase(fetchDestination.fulfilled, (state, action) => {
      state.destinationData = action.payload.data;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchLocationChanged,
  pageLeft,
  pageLimit,
  statusChanged,
} = callEnquirySlice.actions;
export default callEnquirySlice.reducer;
