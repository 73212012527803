import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PlanService from "./service";

const initialState = {
  trainerPlans: [],
  allPlans: [],
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
};

export const fetchAllPlans = createAsyncThunk(
  "plans/fetchAllPlans",
  async (payload, thunkAPI) => {
    const { data, error } = await PlanService.fetchAllPlans(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data, ...payload };
  }
);


export const deletePlan = createAsyncThunk(
  "plans/deletePlan",
  async (payload, thunkAPI) => {
    const { isSuccess, error } = await PlanService.deletePlan(payload);
    if (isSuccess) {
      return payload;
    }
    setTimeout(() => thunkAPI.dispatch(clearError()), 5000);
    return thunkAPI.rejectWithValue(error);
  }
);

export const removeTrainerPlan = createAsyncThunk(
  "plans/removeTrainerPlan",
  async (payload, thunkAPI) => {
    const { isSuccess, error } = await PlanService.removeTrainerPlan(payload);
    if (isSuccess) {
      return payload.planId;
    }
    setTimeout(() => thunkAPI.dispatch(clearError()), 5000);
    return thunkAPI.rejectWithValue(error);
  }
);

export const addTrainerPlan = createAsyncThunk(
  "plans/addTrainerPlan",
  async (payload, thunkAPI) => {
    const { isSuccess, error } = await PlanService.addTrainerPlan(payload);
    if (isSuccess) {
      return payload.plans;
    }
    setTimeout(() => thunkAPI.dispatch(clearError()), 5000);
    return thunkAPI.rejectWithValue(error);
  }
);

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPlans.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchAllPlans.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(fetchAllPlans.fulfilled, (state, action) => {
      const { planType, data } = action.payload;
      if (planType === "ALL_PLANS") {
        state.allPlans = data;
      } else {
        state.trainerPlans = data;
      }
      state.loading = "succeeded";
    });
    builder.addCase(deletePlan.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(deletePlan.fulfilled, (state, action) => {
      state.allPlans = state.allPlans.filter(
        (plan) => plan._id !== action.payload
      );
    });
    builder.addCase(removeTrainerPlan.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(removeTrainerPlan.fulfilled, (state, action) => {
      state.trainerPlans = state.trainerPlans.filter(
        (plan) => plan._id !== action.payload
      );
    });
    builder.addCase(addTrainerPlan.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(addTrainerPlan.fulfilled, (state, action) => {
      state.trainerPlans.push(
        ...state.allPlans.filter((plan) => plan._id === action.payload)
      );
    });
  },
});

export const { clearError } = plansSlice.actions;
export default plansSlice.reducer;
