import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";

const BannerDetails = ({
  BannerDetails: {
    name,
    position,
    imageUrl,
  },
  handleUploadFile,
  handleDetailsChange,
  formType,
}) => {

  const removeFile = () => {
    handleDetailsChange("imageUrl", "");
  };

  return (
    <>
      <Typography className="heading">Our People Details</Typography>
      <TextField
        label="Person Name"
        variant="outlined"
        value={name}
        onChange={(e) => handleDetailsChange("name", e.target.value)}
        fullWidth
      />
      <TextField
        label="Position"
        variant="outlined"
        value={position}
        onChange={(e) => handleDetailsChange("position", e.target.value)}
        fullWidth
      />
      <Grid item>
        <h4>Image</h4>
        {imageUrl ? (
          <>
            <CancelIcon
              sx={{
                position: "relative",
                top: "1.8rem",
                left: "15.9rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={removeFile}
            />
            <Card
              raised
              sx={{
                maxWidth: 280,
                maxHeight: 280,
                margin: "0",
                padding: "0.1em",
              }}
            >
              <CardMedia component="img" image={imageUrl} />
            </Card>
            <br />
            <TextField
              // id="outlined-basic"
              helperText="select images"
              variant="outlined"
              type="file"
              // value={imageUrl}
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          </>
        ) : (
          <TextField
            // id="outlined-basic"
            helperText="select images"
            variant="outlined"
            type="file"
            // value={imageUrl}
            inputProps={{
              multiple: true,
            }}
            onChange={handleUploadFile}
          />
        )}
      </Grid>
    </>
  );
};

export default BannerDetails;
