import { Button, Avatar, FormControlLabel, Switch } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const handleStatus = () => {
   
  };

  const cols = [
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/social-link-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/social-link`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) =>
        params.row.socialName ? params.row.socialName : "N.A",
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        params.row.active === true ? "Active" : "Inactive",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "page",
      headerName: "Page",
      renderCell: (params) => (
        <Avatar src={params.row.socialIcon} alt={params.row.socialIcon} />
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "link",
      headerName: "Link",
      renderCell: (params) =>
        params.row.socialLink ? params.row.socialLink : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1.5,
    },

    // {
    //   field: "updateStatus",
    //   headerName: "Update Status",
    //   renderCell: (params) => (
    //     <FormControlLabel
    //       sx={{
    //         display: "block",
    //       }}
    //       control={
    //         <Switch
    //           checked={params.row.active}
    //           onChange={() => handleStatus()}
    //           name="loading"
    //           color="primary"
    //         />
    //       }
    //       label=""
    //     />
    //   ),
    //   // (params) => (params.row.active ? params.row.active : "N.A")
    //   ...getCommonColDef(),
    //   sortable: false,
    //   // width: 300,
    //   flex: 1,
    // },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions social={param.row} />,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
  ];
  return cols;
};
