import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";

const fetchPackage = async ({
  currentPageLimit,
  currentPage,
  searchByTitle,
  searchByRating,
  searchByLocation,
}) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/packages?limit=${currentPageLimit}&page=${currentPage}&rating=${searchByRating}&name=${searchByTitle}&location=${searchByLocation}`,
      null
    );
    return {
      data: data.data,
      count: data.totalData,
      isSuccess: status === 200,
    };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};
const fetchDestination = async () => {
  try {
    const { data } = await axiosInstance.get(`/admins/destination`, null);
    return { data: data.data };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const fetchThemes = async ({
  currentPageLimit,
  currentPage,
  searchByTitle,
}) => {
  try {
    const { data } = await axiosInstance.get(
      `/themes/get-themes?limit=${currentPageLimit}&page=${currentPage}&title=${searchByTitle}`,
      null
    );
    return { data: data.data, count: data.totalRecords };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const deletePackage = async (id = "") => {
  try {
    const { status, message } = await axiosInstance.delete(
      `/packages/${id}`,
      null
    );

    return { isSuccess: status === 200, message };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const deleteTheme = async (id = "") => {
  try {
    const { status } = await axiosInstance.put(`/themes/${id}`, {
      isFlag: "true",
    });
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const TourService = {
  fetchPackage: cancellableRequest(fetchPackage),
  fetchThemes: cancellableRequest(fetchThemes),
  deletePackage,
  deleteTheme,
  fetchDestination,
};
