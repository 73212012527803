import React, { useState } from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationPopup from "../../shared/ConfirmationPopup";
import { deleteStory } from "./story.slice";

const Actions = ({ story }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        <ConfirmationPopup
          message={`Do you really want to delete the story ?`}
          handleConfirmation={() => dispatch(deleteStory(story._id))}
          render={(handlePopupOpen) => (
            <MenuItem onClick={handlePopupOpen}>{`Delete Story`}</MenuItem>
          )}
        />
      </Menu>
    </>
  );
};

export default Actions;
