import React, { useState, useEffect } from "react";
import { fetchDestination } from "../tours/tour.slice";

import {
  TextField,
  Autocomplete,
  Grid,
  Typography,
  MenuItem,
  Rating,
  Button,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
} from "@mui/material";

import TourService from "./service";
import SelectableDateRange from "./SelectableDateRange";
import { Cancel } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

const PackageDetails = ({
  packageDetails,
  register,
  handleUploadFile,
  setValue,
  control,
  handleMultipleUploadFile,
  formType,
  user_role,
  selectedDateRange,
  handleDateSelection,
  featImage,
  setFeatImage,
  images,
  resetImage,
  nestedGroupingId,
  setNestedGroupingId,
}) => {
  const { destinationData } = useSelector((state) => state.tour);

  const [selectDays, setSelectDays] = useState(
    formType === "edit" ? packageDetails.days : 1
  );
  const [themeSuggestion, setThemeSuggestion] = useState([]);
  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  // const [groupingSuggestion, setGroupingSuggestion] = useState([]);
  const [nestedGroupingOption, setNestedGroupingOption] = useState([]);
  const [nestedGroupValue, setNestedGroupValue] = useState("");
  const [groupTourValue, setGroupTourValue] = useState(
    formType === "edit" ? packageDetails.groupTour : false
  );
  const [popularValue, setPopularValue] = useState(
    formType === "edit" ? packageDetails.popular : false
  );

  useEffect(() => {
    setValue("groupTour", groupTourValue);
  }, [groupTourValue]);

  useEffect(() => {
    setValue("popular", popularValue);
  }, [popularValue]);

  useEffect(() => {
    setValue(
      "destination",
      destinationSuggestion[0] ? destinationSuggestion[0]._id : ""
    );
    setNestedGroupingOption(
      destinationSuggestion[0] ? destinationSuggestion[0].groupPackage : []
    );
  }, [destinationSuggestion]);

  const grupTourOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];
  const todaysTopDealOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];
  const popularOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];
  const handleDays = (value) => {
    setValue("days", value);
    setSelectDays(value);
    setValue("nights", value - 1);
  };

  const handleThemeSuggestion = async (value) => {
    const { data, isSuccess } = await TourService.themeSuggestion(value);
    isSuccess && setThemeSuggestion(data?.data);
  };

  const handleDestinationSuggestion = async (value) => {
    const { data, isSuccess, error } = await TourService.destinationSuggestion(
      value
    );
    isSuccess && setDestinationSuggestion(data?.data);
  };

  useEffect(() => {
    handleThemeSuggestion();
  }, []);

  return (
    <>
      <Typography variant="h5">Package Details</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Package Name"
            variant="outlined"
            {...register("title")}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Start Location"
                fullWidth
                {...register("startlocation")}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                label="End Location"
                fullWidth
                {...register("endlocation")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4}>
              <TextField
                fullWidth
                label="Days"
                type="number"
                defaultValue={packageDetails.days}
                inputProps={{ min: 1 }}
                onChange={(e) => handleDays(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                label="Night"
                type="number"
                disabled
                inputProps={{ min: 1 }}
                fullWidth
                value={selectDays - 1}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                label="Base Price"
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                {...register("basePrice")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            defaultValue={packageDetails.groupTour}
            label="Group Tour"
            onChange={(e) => setGroupTourValue(e.target.value)}
          >
            {grupTourOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label="Group Size"
            type="number"
            fullWidth
            disabled={groupTourValue === false}
            defaultValue={groupTourValue === false ? 0 : ""}
            inputProps={{ min: 1 }}
            {...register("groupSize")}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            defaultValue={packageDetails.todayTopDeal}
            label="Todays top deal"
            {...register("todayTopDeal")}
          >
            {todaysTopDealOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            defaultValue={packageDetails.popular}
            label="Popular"
            onChange={(e) => setPopularValue(e.target.value)}
          >
            {popularOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant="outlined" fullWidth {...register("themeIds")}>
            <InputLabel id="themeIds">Themes</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  id="themeIds"
                  label="themes"
                  multiple
                  defaultvalue={packageDetails.themeIds?.map(
                    (item) => item._id
                  )}
                  className="dropDown"
                >
                  {themeSuggestion.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="themeIds"
              defaultValue={[]}
              control={control}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6}>
          <Autocomplete
            id="destination"
            options={destinationSuggestion?.map((option) => option?.name)}
            inputValue={destinationSuggestion?._id}
            getOptionLabel={(option) => (option ? option : "")}
            defaultValue={packageDetails.destinationName}
            disabled={user_role !== "Super" && formType === "edit"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination"
                onChange={({ target }) =>
                  handleDestinationSuggestion(target.value)
                }
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <TextField
            fullWidth
            select
            disabled={destinationSuggestion.length === 0}
            defaultValue={packageDetails.nestedGroupingName}
            label="Nested Grouping"
            onChange={(e) => setNestedGroupingId(e.target.value)}
          >
            {nestedGroupingOption?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            label="Payment Terms"
            fullWidth
            {...register("paymentTerms")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <FormControl
            {...register("cab")}
            fullWidth
            disabled={groupTourValue === false}
          >
            <InputLabel htmlFor="Cab">Cab</InputLabel>
            <Controller
              render={({ field }) => (
                <Select
                  defaultValue={packageDetails.cab}
                  id="cab"
                  label="cab"
                  {...field}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>Sedan Class - </ListSubheader>
                  <MenuItem value="Swift Dzire(4+1)">Swift Dzire(4+1)</MenuItem>
                  <MenuItem value="Toyota Etios(4+1)">
                    Toyota Etios(4+1)
                  </MenuItem>
                  <ListSubheader>MUV</ListSubheader>
                  <MenuItem value="Toyota Innova(7+1)(6+1)">
                    Toyota Innova(7+1)(6+1)
                  </MenuItem>
                  <MenuItem value="Toyota Innova Crysta(7+1)(6+1)">
                    Toyota Innova Crysta(7+1)(6+1)
                  </MenuItem>
                  <MenuItem value="Marazzo(7+1)">Marazzo(7+1)</MenuItem>
                  <MenuItem value="Toyota Fortuner(7+1)">
                    Toyota Fortuner(7+1)
                  </MenuItem>
                  <MenuItem value="Ertiga(7+1)">Ertiga(7+1)</MenuItem>
                  <ListSubheader>MPV</ListSubheader>
                  <MenuItem value="Tempo Traveller(12+1)">
                    Tempo Traveller(12+1)
                  </MenuItem>
                  <MenuItem value="Tempo Traveller(16+1)">
                    Tempo Traveller(16+1)
                  </MenuItem>
                  <MenuItem value="Luxury Tempo Traveller(11+1)">
                    Luxury Tempo Traveller(11+1)
                  </MenuItem>
                </Select>
              )}
              name="cab"
              defaultValue={[]}
              control={control}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Typography component="legend">Rating</Typography>
          <Rating
            name="rating"
            size="large"
            fullWidth
            defaultValue={packageDetails.ratings}
            onChange={(e, value) => setValue("ratings", value)}
          />
        </Grid>

        {selectedDateRange.startDate && (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                <TextField
                  name="startDate"
                  label="selceted start date"
                  size="small"
                  value={selectedDateRange.startDate}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="endDate"
                  label="selceted end date"
                  size="small"
                  value={selectedDateRange.endDate}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={11} sm={11} md={11}>
          <SelectableDateRange
            selectedDateRange={selectedDateRange}
            handleDateSelection={handleDateSelection}
          />
        </Grid>

        {featImage && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography>Featured Image</Typography>
            <Cancel
              sx={{
                position: "relative",
                top: "1.2rem",
                left: "9.5rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setFeatImage("")}
            />
            <div style={{ height: "10rem", width: "10rem" }}>
              <img src={featImage} width="100%" height="100%"></img>
            </div>
          </Grid>
        )}

        {images?.length > 0 && (
          <Grid item spacing={1} xs={12} sm={12} md={12}>
            <Typography>Image</Typography>
            <div style={{ display: "flex" }}>
              {images?.map((item, index) => (
                <>
                  <Cancel
                    sx={{
                      position: "relative",
                      top: "-0.5rem",
                      left: "7rem",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => resetImage(index)}
                  />
                  <img src={item} height="100px" width="100px"></img>
                </>
              ))}
            </div>
          </Grid>
        )}
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              id="outlined-basic"
              helperText="select featured image"
              variant="outlined"
              type="file"
              onChange={handleUploadFile}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              id="outlined-basic"
              helperText="select multiple image"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleMultipleUploadFile}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PackageDetails;
