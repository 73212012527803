import { Button } from "@mui/material";
import { getCommonColDef } from "../../shared/utils";
import Actions from "./Actions";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      valueGetter: (params) => (params.row.title ? params.row.title : "N.A"),
      sortable: false,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate(`/famous-places-form?formType=edit`, {
              state: {
                data: param.row,
                prevPath: `/famous-place`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    // {
    //   field: "createdBy",
    //   headerName: "Created By",
    //   valueGetter: (params) =>
    //     params.row.createdBy ? params.row.createdBy : "N.A",
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
    {
      field: "destination",
      headerName: "Destination Name",
      valueGetter: (params) =>
        params.row.destinationId !== null
          ? params.row.destinationId?.name
          : "N.A",
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    // {
    //   field: "bestTimeToVisit",
    //   headerName: "Best Time to Visit",
    //   valueGetter: (params) =>
    //     params.row.destinationId
    //       ? params.row.destinationId.bestTimeToVisit
    //       : "N.A",
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
    {
      field: "type",
      headerName: "Type",
      valueGetter: (params) => (params.row.type ? params.row.type : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions famous={param.row} />,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
  ];
  return cols;
};
