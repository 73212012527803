import { axiosInstance } from "../../shared/axios";

const fetchTrainerSessions = async (trainerId) => {
  try {
    const { data } = await axiosInstance.get(
      `/sessionsComplete?trainerId=${trainerId}`
    );
    return data.response;
  } catch (error) {
    return { error: error.response.body || error.message };
  }
};

const updateTrainerCommission = async (trainerId, commision) => {
  try {
    const { status } = await axiosInstance.put(`/admin/updateCommision`, {
      trainerId,
      commision,
    });
    return { isSuccess: status === 200 };
  } catch (error) {
    return { error: error.response.body };
  }
};

const PayoutService = { fetchTrainerSessions, updateTrainerCommission };
export default PayoutService;

