import React, { useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";
import TodaysTopDealFormService from "./service";
import TodaysTopDealDetails from "./TodaysTopDealDetails";

const getSchema = (formType) => {
  const commonSchema = {
    // mainTitle: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const TodaysTopDealForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [todaysTopDealDetails, setTodaysTopDealDetails] = useState({ ...data });

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...todaysTopDealDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        ...data,
      };

      const { error, isSuccess } =
        await TodaysTopDealFormService.editTodaysTopDeal(payload, payload._id);
      isSuccess && navigate("/todaysTopDeal");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setTodaysTopDealDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <TodaysTopDealDetails
          todaysTopDealDetails={todaysTopDealDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default TodaysTopDealForm;
