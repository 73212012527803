import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GroupService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  location: "",
};

export const fetchGroup = createAsyncThunk(
  "group/fecthGroup",
  async (_, thunkAPI) => {
    const { currentPage, currentPageLimit, location } =
      thunkAPI.getState().group;
    const payload = {
      currentPage,
      currentPageLimit,
      location,
    };
    const { data, count, error, isSuccess } = await GroupService.fetchGroup(
      payload
    );
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data, count, isSuccess };
  }
);

export const deleteGroup = createAsyncThunk(
  "group/deleteGroup",
  async ({ id, thunkAPI }) => {
    const { isSuccess, error } = await GroupService.deleteGroup(id);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    thunkAPI.dispatch(fetchGroup());
    return { isSuccess };
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },

    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
    locationChanged(state, action) {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroup.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchGroup.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.count;
      state.loading = "succeeded";
    });
    builder.addCase(fetchGroup.rejected, (state, action) => {
      state.loading = "pending";
    });
  },
});

export const { pageChanged, pageLimit, locationChanged } = groupSlice.actions;
export default groupSlice.reducer;
