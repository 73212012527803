import { axiosInstance } from "../../shared/axios";

const addSeo = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/meta`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editSeo = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/meta?id=${id}`, payload);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const getAllPackage = async () => {
  try {
    const { data, status } = await axiosInstance.get(`/packages`, null);
    return {
      data: data.data,
      isSuccess: status === 200,
    };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const fetchThingsToDo = async () => {
  try {
    const response = await axiosInstance.get(
      `/stories/get-all-stories?type=${"things todo"}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};
const fetchPlacesToVisit = async () => {
  try {
    const response = await axiosInstance.get(
      `/stories/get-all-stories?type=${"places to visit"}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const fetchStory = async () => {
  try {
    const response = await axiosInstance.get(
      `/stories/get-all-stories?type=${"normal"}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const SeoFormService = {
  addSeo,
  editSeo,
  getAllPackage,
  fetchThingsToDo,
  fetchPlacesToVisit,
  fetchStory,
};
export default SeoFormService;
