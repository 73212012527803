import React, { useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";
import Editor from "../../shared/Editor";

import { defaultThemesDetails } from "./constants";

import TourService from "./service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";
import ThemeDetails from "./ThemeDetails";
import { uploadFile } from "../../shared/services";

const getSchema = (formType) => {
  const commonSchema = {
    name: yup.string().required(),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const ThemeForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [description, setDescription] = useState(
    formType === "edit" ? data.description : ""
  );
  const [image, setImage] = useState(data ? data.imgUrl : "");
  const [error, setError] = useState("");
  const [themeId, setThemeId] = useState("");

  const [themeDetails, setThemeDetails] = useState(
    formType === "edit" ? { ...data } : defaultThemesDetails
  );

  useEffect(() => {
    setThemeId(data ? data._id : "");
  }, [data]);

  useEffect(() => {
    setThemeDetails((data) => ({ ...data, ["imgUrl"]: image }));
  }, [image]);

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...themeDetails,
          description,
        },
        { abortEarly: false }
      );

      const payload = {
        name: data.name,
        imgUrl: data.imgUrl,
        description: data.description,
        priority: +data.priority,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await TourService.addTheme(payload)
          : await TourService.editTheme(payload, themeId);
      isSuccess && navigate("/tour?view-type=tour-themes");
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setThemeDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];
    let formData = new FormData();
    formData.append("file", files);
    let payload = formData;

    try {
      const { fileUrl, isSuccess, error } = await uploadFile(payload);
      isSuccess && setImage(fileUrl);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <ThemeDetails
          ThemeDetails={themeDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
          handleUploadFile={handleUploadFile}
          image={image}
          setImage={setImage}
        />

        <Typography style={{ fontWeight: "bold" }}>Description -</Typography>
        <Editor
          content={description}
          setContent={setDescription}
          placeholder={"Description"}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default ThemeForm;
