import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../shared/axios";
import { FaqService, GroupToursService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
};

export const fetchGroupTours = createAsyncThunk(
  "groupTours/fetchGroupTours",
  async (viewType, thunkAPI) => {
    const { searchKey, currentPage, currentPageLimit } =
      thunkAPI.getState().groupTours;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
    };
    const data = await GroupToursService.fetchGroupTours(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return data;
  }
);

// export const list_unlistFAQ = createAsyncThunk(
//   "faq/listUnlistFAQ",

//   async (id, thunkAPI) => {
//     const { isSuccess, error } = await FaqService.listUnlistFAQ(id);
//     if (isSuccess) {
//       return id;
//     }
//     return thunkAPI.rejectWithValue(error);
//   }
// );

export const deleteUser = createAsyncThunk(
  "groupTours/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await GroupToursService.deleteUser(id);
    if (isSuccess) {
      return id;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

// export const logoutUser = createAsyncThunk(
//   "user/logoutUser",
//   async (id, thunkAPI) => {
//     const { isSuccess, error } = await UserService.logoutUser(id);
//     if (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//     return isSuccess;
//   }
// );
// export const remarkUpdate = createAsyncThunk(
//   "user/remarkUpdate",
//   async ({userId, remarkType}, thunkAPI) => {
//     const data = await UserService.remarkUpdate(userId, remarkType);

//     // if (error) {
//     //   return thunkAPI.rejectWithValue(error);
//     // }
//     return data.remarkList.remark;
//   }
// );

const groupToursSlice = createSlice({
  name: "groupTours",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupTours.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchGroupTours.fulfilled, (state, action) => {
      state.data = action.payload.data.activeAllTours;
      state.total = action.payload.totalData;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  pageLeft,
  pageLimit,
} = groupToursSlice.actions;
export default groupToursSlice.reducer;
