import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RobotsFileService } from "./service";

const initialState = {
  data: "",
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
};

export const fetchRobotsFile = createAsyncThunk(
  "robotsFile/fetchRobotsFile",
  async () => {
    const data = await RobotsFileService.fetchRobotsFile();
    return data;
  }
);

export const updateRobotsFile = createAsyncThunk(
  "robotsFile/updateRobotsFile" , 
  async ( data , thunkAPI) => {
    const {isSuccess , error} = await RobotsFileService.updateRobotsFile(data);
    if (isSuccess) {
      thunkAPI.dispatch(fetchRobotsFile());
      return isSuccess;
    }
    return thunkAPI.rejectWithValue(error);
  }
)

const robotsFileSlice = createSlice({
  name: "robotsFile",
  initialState,
  reducers: {
    dataChanged(state , action) {
      state.data = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRobotsFile.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchRobotsFile.fulfilled, (state, action) => {
      state.data = action.payload.data.robottxt;
      state.loading = "succeeded";
    });
  },
});

export const {
  dataChanged,
} = robotsFileSlice.actions;
export default robotsFileSlice.reducer;
