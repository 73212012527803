import { axiosInstance } from "../../shared/axios";

const fetchGroupTours = async ({
  currentPageLimit,
  currentPage,
  searchKey,
}) => {
  try {
    const response = await axiosInstance.get(
      `packages/deals/getToursAndDeals?type=groupTour&page=${currentPage}&limit=${currentPageLimit}&title=${searchKey}`,
      null
    );

    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const GroupToursService = {
  fetchGroupTours,
};
