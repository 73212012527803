import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TourService } from "./service";

const initialState = {
  total: 0,
  data: [],
  destinationData: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchByTitle: "",
  searchByRating: "",
  searchByLocation: "",
};

export const fetchTour = createAsyncThunk(
  "tour/fetchData",
  async (viewType, thunkAPI) => {
    const {
      searchByTitle,
      searchByRating,
      searchByLocation,
      currentPage,
      currentPageLimit,
    } = thunkAPI.getState().tour;
    const payload = {
      currentPage,
      currentPageLimit,
      searchByTitle,
      searchByRating,
      searchByLocation,
    };
    const { data, count, error, isSuccess } =
      viewType === "tour-packages"
        ? await TourService.fetchPackage(payload)
        : await TourService.fetchThemes(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data, count, isSuccess };
  }
);
export const fetchDestination = createAsyncThunk(
  "tour/fetchDestination",
  async (_, thunkAPI) => {
    const { data, error } = await TourService.fetchDestination();

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data };
  }
);

export const deleteTour = createAsyncThunk(
  "tour/deleteData",
  async ({ id, viewType }, thunkAPI) => {
    const { isSuccess, error } =
      viewType === "tour-packages"
        ? await TourService.deletePackage(id)
        : await TourService.deleteTheme(id);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    thunkAPI.dispatch(fetchTour(viewType));
    return { isSuccess };
  }
);

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    searchTitleChanged(state, action) {
      state.searchByTitle = action.payload;
    },
    searchRatingChanged(state, action) {
      state.searchByRating = action.payload;
    },
    searchLocationChanged(state, action) {
      state.searchByLocation = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByLocation = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTour.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchTour.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.count;
      state.loading = "succeeded";
    });
    builder.addCase(fetchTour.rejected, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchDestination.pending, (state) => {
      state.loading = "pending";
      state.destinationData = [];
    });
    builder.addCase(fetchDestination.fulfilled, (state, action) => {
      state.destinationData = action.payload.data;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  searchTitleChanged,
  searchRatingChanged,
  searchLocationChanged,
  pageLeft,
  pageLimit,
} = tourSlice.actions;
export default tourSlice.reducer;
