import { axiosInstance } from "../../shared/axios";

const fetchAllPlans = async ({ trainerId, planType }) => {
  try {
    const url =
      planType === "ALL_PLANS"
        ? "/admin/allPlans"
        : `/admin/allPlans?trainerId=${trainerId}`;
    const { data } = await axiosInstance.get(url);
    return data.response;
  } catch (error) {
    return { error: error.response.body || error.message };
  }
};

const deletePlan = async (planId) => {
  try {
    const { status } = await axiosInstance.delete(
      `/admin/removePlan?planId=${planId}`
    );
    return { isSuccess: status === 200 };
  } catch (error) {
    return { error: error.response?.data?.response?.message || error.message };
  }
};

const addTrainerPlan = async (payload) => {
  try {
    const { status } = await axiosInstance.post(
      `/admin/enrollTrainer/Plan`,
      payload
    );
    return { isSuccess: status === 201 };
  } catch (error) {
    return { error: error.response?.data?.response?.message || error.message };
  }
};

const removeTrainerPlan = async (payload) => {
  try {
    const { status } = await axiosInstance.put(
      `/admin/removeTrainerPlan`,
      payload
    );
    return { isSuccess: status === 200 };
  } catch (error) {
    return { error: error.response?.data?.response?.message || error.message };
  }
};

const PlanService = {
  fetchAllPlans,
  deletePlan,
  addTrainerPlan,
  removeTrainerPlan,
};
export default PlanService;

