import React, { useState } from "react";

import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import ConfirmationPopup from "../../shared/ConfirmationPopup";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { deleteTour } from "./tour.slice";

const Actions = ({ tour, user_role }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        disabled={user_role !== "Super"}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        <ConfirmationPopup
          message={`Do you really want to delete the ${viewType} ?`}
          handleConfirmation={() =>
            dispatch(deleteTour({ id: tour._id, viewType }))
          }
          render={(handlePopupOpen) => (
            <MenuItem onClick={handlePopupOpen}>
              {`Delete ${viewType}`}
            </MenuItem>
          )}
        />
      </Menu>
    </>
  );
};

export default Actions;
