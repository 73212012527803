import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TourEnquiryService } from "./service";

const initialState = {
  total: 0,
  data: [],
  destinationData: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  status: "",
  searchByLocation: "",
};

export const fetchTourEnquiryData = createAsyncThunk(
  "tourEnquiry/fetchTourEnquiry",
  async ({ startDate, endDate }, thunkAPI) => {
    const {
      searchKey,
      currentPage,
      currentPageLimit,
      status,
      searchByLocation,
    } = thunkAPI.getState().tourEnquiry;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchByLocation,
      status,
      startDate,
      endDate,
    };
    const data = await TourEnquiryService.fetchTourEnquiry(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "tourEnquiry/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await TourEnquiryService.deleteUser(id);
    if (isSuccess) {
      return id;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

export const fetchDestination = createAsyncThunk(
  "tourEnquiry/fetchDestination",
  async (_, thunkAPI) => {
    const { data, error } = await TourEnquiryService.fetchDestination();
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { data };
  }
);

const tourEnquirySlice = createSlice({
  name: "tourEnquiry",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    searchLocationChanged(state, action) {
      state.searchByLocation = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByLocation = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
    statusChanged(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTourEnquiryData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchTourEnquiryData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = "succeeded";
    });
    builder.addCase(fetchDestination.pending, (state) => {
      state.loading = "pending";
      state.destinationData = [];
    });
    builder.addCase(fetchDestination.fulfilled, (state, action) => {
      state.destinationData = action.payload.data;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchLocationChanged,
  pageLeft,
  pageLimit,
  statusChanged,
} = tourEnquirySlice.actions;
export default tourEnquirySlice.reducer;
