import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultEnquiryDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import GroupTourEnquiryDetails from "./GroupTourEnquiryDetails";
import TripEnquiryFormService from "./service";
import "./style.css";
import { getFormattedDate } from "../../shared/utils";

const getSchema = (formType) => {
  const commonSchema = {
    // title: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const GroupTourEnquiryForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [enquiryDetails, setEnquiryDetails] = useState(
    formType === "edit" ? { ...data } : defaultEnquiryDetails
  );

  const [groupSize, setGroupSize] = useState(data.packageId?.groupSize);
  const [noOfPersons, setNoOfPersons] = useState(data.noOfPersons);
  const [pickUp, setPickUp] = useState(
    formType === "edit" ? getFormattedDate(data.pickUp) : ""
  );
  const [hotelsCategory, setHotelsCategory] = useState(data.hotelsCategory);

  const [dropOff, setDropOff] = useState(
    formType === "edit" ? getFormattedDate(data.dropOff) : ""
  );
  const [priceQuotation, setPriceQuotation] = useState(
    data.priceQuotation ? data.priceQuotation : ""
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...enquiryDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        packageId: formType === "edit" ? data.packageId._id : data.packageId,
        name: data.name,
        phone: data.phone,
        email: data.email,
        pickUp: pickUp,
        dropOff: dropOff,
        // query: data.query,
        status: data.status,
        comments: data.comments,
        cab: data.cab,
        to: data.to,
        from: data.from,
        hotelCategory: {
          rating: +hotelsCategory.rating,
          type: hotelsCategory.type,
        },
        noOfPersons: {
          adults: +noOfPersons.adults,
          child: +noOfPersons.child,
          kids: +noOfPersons.kids,
        },
        isGroup: data.isGroup,
        groupSize: groupSize,
        priceQuotation: +priceQuotation,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await TripEnquiryFormService.addEnquiry(payload)
          : await TripEnquiryFormService.updateEnquiry(payload, data._id);
      isSuccess && navigate("/group-tour-enquiry");
      setError(error);
      error && handleError(error);
    } catch (error) {
      // handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setEnquiryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleNoOfPersonsChange = (name, value) => {
    setNoOfPersons((prev) => ({ ...prev, [name]: value }));
  };

  const handleHotelCategoryChange = (name, value) => {
    setHotelsCategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <GroupTourEnquiryDetails
          TripEnquiryDetails={enquiryDetails}
          handleDetailsChange={handleDetailsChange}
          handleNoOfPersonsChange={handleNoOfPersonsChange}
          handleHotelCategoryChange={handleHotelCategoryChange}
          hotelsCategory={hotelsCategory}
          priceQuotation={priceQuotation}
          setPriceQuotation={setPriceQuotation}
          noOfPersons={noOfPersons}
          formType={formType}
          pickUp={pickUp}
          dropOff={dropOff}
          setPickUp={setPickUp}
          setDropOff={dropOff}
          groupSize={groupSize}
          setGroupSize={setGroupSize}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default GroupTourEnquiryForm;
