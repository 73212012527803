import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchStory = async ({
  currentPageLimit,
  currentPage,
  searchByTitle = "",
  searchByLocation = "",
}) => {
  try {
    const response = await axiosInstance.get(
      `/stories/get-all-stories?limit=${currentPageLimit}&page=${currentPage}&title=${searchByTitle}&location=${searchByLocation}&type=${"places to visit"}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};
const fetchDestination = async () => {
  try {
    const { data } = await axiosInstance.get(`/admins/destination`, null);
    return { data: data.data };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const deleteFamousPlace = async (id = "") => {
  try {
    const { status } = await axiosInstance.delete(
      `/stories/delete-story/${id}`
    );
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const StoryService = {
  fetchStory,
  deleteFamousPlace,
  fetchDestination,
};
