import { axiosInstance } from "../../shared/axios";

export const fetchMonthlyUsersStats = async ({
  userType = "USER", // "USER" | "TRAINER"
  statType = "ACTIVE", // "ACTIVE" | "REGISTERED"
  month = "",
  year = "",
}) => {
  try {
    const { data } = await axiosInstance.post("/admin/getMonthlyUsers", {
      isTrainer: userType === "TRAINER",
      isLogged: statType === "ACTIVE",
      month,
      year,
    });
    return data.response;
  } catch (e) {
    return { data: [] };
  }
};

const MonitoringService = { fetchMonthlyUsersStats };
export default MonitoringService;

