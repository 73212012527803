import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";

const fetchGroup = async ({ currentPageLimit, currentPage, location }) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/group-packages?page=${currentPage}&limit=${currentPageLimit}&location=${location}`,
      null
    );
    return {
      data: data.data,
      count: data.currentCount,
      isSuccess: status === 200,
    };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const addGroup = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/group-packages`, payload);
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response.data.message || e.message };
  }
};

const editGroup = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(
      `/group-packages/${id}`,
      payload
    );
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const deleteGroup = async (id = "") => {
  try {
    const { status } = await axiosInstance.put(`/group-packages/${id}`, {
      isFlag: "true",
    });

    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const packagesSuggestion = async () => {
  try {
    const { data, status } = await axiosInstance.get(
      `packages/suggestions`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};

export const GroupService = {
  fetchGroup: cancellableRequest(fetchGroup),
  addGroup,
  editGroup,
  deleteGroup,
  packagesSuggestion,
};
