import React, { useEffect, useState } from "react";
import { pages } from "./constants";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardMedia,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

const CodeSnippetDetails = ({
  codeSnippetDetails,
  handleCodeSnippetDetailsChange,
}) => {
  const placementOption = [
    {
      value: "head",
      label: "Within head",
    },
    {
      value: "bottom",
      label: "At the bottom",
    },
  ];

  return (
    <>
      <Typography className="heading">Code Snippet Details</Typography>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Code Name"
            variant="outlined"
            value={codeSnippetDetails.codeName}
            onChange={(e) =>
              handleCodeSnippetDetailsChange("codeName", e.target.value)
            }
            fullWidth
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Code Script"
            variant="outlined"
            multiline
            minRows={2}
            value={codeSnippetDetails.code}
            fullWidth
            onChange={(e) =>
              handleCodeSnippetDetailsChange("code", e.target.value)
            }
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            select
            defaultValue={codeSnippetDetails.codePlacement}
            label="Placement"
            onChange={(e) =>
              handleCodeSnippetDetailsChange("codePlacement", e.target.value)
            }
          >
            {placementOption.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default CodeSnippetDetails;
