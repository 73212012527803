import React, { Fragment, useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";

import DestinationDetails from "./DestinationDetails";

import { defaultDestinationDetails } from "./constant";

import { DestinationService } from "./service";
import { adminRoleGetterService, uploadFile } from "../../shared/services";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";
import Editor from "../../shared/Editor";

const getSchema = (formType) => {
  const commonSchema = {
    priority: yup.number().required().min(0).max(9),
    zonePriority: yup.number().required().min(0).max(4),
    name: yup.string().required(),
    state: yup.string().required(),
    zone: yup.string().required(),
    // imageUrl: yup.string().required(),
    country: yup.string().required(),
    domestic: yup.string().required(),
    bestTimeToVisit: yup.string().required(),
    idealDuration: yup.string().required(),
    visa: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const DestinationForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const user_role = adminRoleGetterService();

  const {
    state: { data },
  } = useLocation();

  const [image, setImage] = useState(data ? data.imageUrl : "");
  const [zonePriority, setZonePriority] = useState(data ? data.zonePriority : "");
  const [error, setError] = useState("");
  const [description, setDescription] = useState(formType === "edit" ? data.description : "");
  const [destinationId, setDestinationId] = useState("");

  useEffect(() => {
    setDestinationId(data ? data._id : "");
  }, [data]);

  useEffect(() => {
    setDestinationDetails((data) => ({ ...data, ["imageUrl"]: image }));
  }, [image]);

  useEffect(() => {
    setDestinationDetails((data) => ({ ...data, "zonePriority": zonePriority }));
  }, [zonePriority]);

  const [destinationDetails, setDestinationDetails] = useState(
    formType === "edit" ? { ...data } : defaultDestinationDetails
  );


  const [inputArr, setInputArr] = useState([
    {
      title: "By Air",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243875002/Vector%20%284%29.png",
      description: formType === "edit" ? data?.howToReach[0]?.description : "" ,
    },
    {
      title: "By Train",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243961183/Vector%20%285%29.png",
      description: formType === "edit" ? data?.howToReach[1]?.description : "" ,
    },
    {
      title: "By Road",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243980422/Vector%20%286%29.png",
      description: formType === "edit" ? data?.howToReach[2]?.description : "" ,
    },
  ]);



  const handleChange = (idx, value) => {
    if (idx === 0) {
      inputArr[0].description = value;
    } else if (idx === 1) {
      inputArr[1].description = value;
    } else {
      inputArr[2].description = value;
    }

    // setHowToReachPayload(inputArr);
  };


  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await getSchema(formType).validate(
        {
          ...destinationDetails,
          zonePriority
        },
        { abortEarly: false }
      );


      const payload = {
        bestTimeToVisit : data.bestTimeToVisit,
        country : data.country,
        domestic : data.domestic,
        idealDuration: data.idealDuration,
        imageUrl : data.imageUrl,
        name : data.name,
        priority : +data.priority,
        state : data.state,
        visa : data.visa,
        website : data.website,
        zone : data.zone,
        zonePriority : +data.zonePriority,
        description: description,
        howToReach: inputArr,
      };  


      const { error, isSuccess } =
        formType === "add"
          ? await DestinationService.addDestination(payload)
          : await DestinationService.editDestination(payload, destinationId);
      isSuccess && navigate("/destination");
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setDestinationDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const { fileUrl, isSuccess, error } = await uploadFile(payload);
      isSuccess && setImage(fileUrl);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <DestinationDetails
          destinationDetails={destinationDetails}
          handleDetailsChange={handleDetailsChange}
          handleUploadFile={handleUploadFile}
          formType={formType}
          user_role={user_role}
          image={image}
          setImage={setImage}
          setZonePriority={setZonePriority}
          zonePriority={zonePriority}
          howToReach={destinationDetails.howToReach ? destinationDetails.howToReach : []}
          handleChange={handleChange}
        />
        <Typography style={{fontWeight:"bold"}}>Description -</Typography>
        <Editor content={description} setContent={setDescription} placeholder={"Description"}/>
        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default DestinationForm;
