import React, { useEffect } from "react";

import { Button, Container, Grid } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";

import DropDown from "./DropDown";

import { months, years } from "./constants";
import { getMonthDays } from "./utils";
import {
  fetchMonthlyUsersStats,
  monthChanged,
  tabChanged,
  yearChanged,
} from "./monitoring.slice";

import "./style.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Monitoring = () => {
  const { data, selectedMonth, selectedTab, selectedYear } = useSelector(
    (state) => state.monitoring
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMonthlyUsersStats());
  }, [dispatch, selectedYear, selectedMonth, selectedTab]);

  const handleMonthSelection = (_, { props: { value } }) =>
    dispatch(monthChanged(value));

  const handleYearSelection = (_, { props: { value } }) =>
    dispatch(yearChanged(value));

  const handleTabSelection = (e) =>
    e.target.nodeName === "BUTTON" && dispatch(tabChanged(e.target.innerText));

  return (
    <Container className="monitoring-container">
      <Grid container onClick={handleTabSelection} gap={2} alignItems="center">
        {["ACTIVE USERS", "ACTIVE TRAINERS", "REGISTERED USERS"].map(
          (label) => (
            <Grid item key={label} xs={5} md={2}>
              <Button variant={label === selectedTab ? "contained" : "text"}>
                {label}
              </Button>
            </Grid>
          )
        )}
        <Grid item xs={5} md={2}>
          <DropDown
            label="Month"
            items={months}
            selectedItem={selectedMonth}
            handleItemSelection={handleMonthSelection}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <DropDown
            label="Year"
            items={years}
            selectedItem={selectedYear}
            handleItemSelection={handleYearSelection}
          />
        </Grid>
      </Grid>
      <Container className="chart-container">
        <Line
          datasetIdKey="line-chart"
          data={{
            labels: getMonthDays(),
            datasets: [
              {
                id: 1,
                backgroundColor: "#afb42b",
                borderColor: "#00796b",
                label: selectedTab,
                data,
              },
            ],
          }}
        />
      </Container>
    </Container>
  );
};

export default Monitoring;

