import React, { useEffect, useState } from "react";
import { pages } from "./constants";
import CancelIcon from "@mui/icons-material/Cancel";
import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";

const SocialLinkDetails = ({
  socialLinkDetails,
  handleUploadFile,
  handleSocialDetailsChange,
}) => {
  const removeFile = () => {
    handleSocialDetailsChange("socialIcon", "");
  };

  return (
    <>
      <Typography className="heading">Social Link Details</Typography>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Title"
            variant="outlined"
            value={socialLinkDetails.socialName}
            onChange={(e) =>
              handleSocialDetailsChange("socialName", e.target.value)
            }
            fullWidth
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Url"
            variant="outlined"
            value={socialLinkDetails.socialLink}
            fullWidth
            onChange={(e) =>
              handleSocialDetailsChange("socialLink", e.target.value)
            }
          />
        </Grid>

        <Grid md={12} sm={12} xs={12} sx={{ marginTop: "2rem" }}>
          <h4>icon</h4>
          {socialLinkDetails.socialIcon ? (
            <>
              <CancelIcon
                sx={{
                  position: "relative",
                  top: "1.8rem",
                  left: "15.9rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={removeFile}
              />
              <Card
                raised
                sx={{
                  maxWidth: 280,
                  maxHeight: 280,
                  margin: "0",
                  padding: "0.1em",
                }}
              >
                <CardMedia
                  component="img"
                  image={socialLinkDetails.socialIcon}
                />
              </Card>
              <br />
              <TextField
                helperText="select images"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                onChange={handleUploadFile}
              />
            </>
          ) : (
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SocialLinkDetails;
