import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultEnquiryDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import TripEnquiryDetails from "./TripEnquiryDetails";
import TripEnquiryFormService from "./service";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {
    // title: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const TripEnquiryForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const [enquiryDetails, setEnquiryDetails] = useState(
    formType === "edit" ? { ...data } : defaultEnquiryDetails
  );
  const [noOfPersons, setNoOfPersons] = useState(data.noOfPersons);
  const [destinationName, setDestinationName] = useState(
    data.destinationId?.name
  );
  const [hotelsCategory, setHotelsCategory] = useState(data.hotelsCategory);
  const [priceQuotation, setPriceQuotation] = useState(
    data.priceQuotation ? data.priceQuotation : ""
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...enquiryDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        // packageId: formType === "edit" ? data.packageId._id : data.packageId,
        name: data.name,
        phone: data.phone,
        email: data.email,
        pickUp: data.pickUp,
        dropOff: data.dropOff,
        destinationId: data.destinationId,
        // query: data.query,
        status: data.status,
        comments: data.comments,
        cab: data.cab,
        to: data.to,
        from: data.from,
        travelDate: data.travelDate,
        returnDate: data.returnDate,
        additionalRequirementText: data.additionalRequirementText,
        hotelCategory: {
          rating: +hotelsCategory.rating,
          type: hotelsCategory.type,
        },
        noOfPersons: {
          adults: +noOfPersons.adults,
          child: +noOfPersons.child,
          kids: +noOfPersons.kids,
        },
        isGroup: data.isGroup,
        // groupSize: groupSize,
        priceQuotation: +priceQuotation,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await TripEnquiryFormService.addEnquiry(payload)
          : await TripEnquiryFormService.updateEnquiry(payload, data._id);
      isSuccess && navigate("/trip-enquiry");
      setError(error);
      error && handleError(error);
    } catch (error) {
      // handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setEnquiryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleNoOfPersonsChange = (name, value) => {
    setNoOfPersons((prev) => ({ ...prev, [name]: value }));
  };

  const handleHotelCategoryChange = (name, value) => {
    setHotelsCategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <TripEnquiryDetails
          TripEnquiryDetails={enquiryDetails}
          handleDetailsChange={handleDetailsChange}
          handleNoOfPersonsChange={handleNoOfPersonsChange}
          handleHotelCategoryChange={handleHotelCategoryChange}
          destinationName={destinationName}
          priceQuotation={priceQuotation}
          setPriceQuotation={setPriceQuotation}
          noOfPersons={noOfPersons}
          hotelsCategory={hotelsCategory}
          formType={formType}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default TripEnquiryForm;
