import { Button, Typography, Grid, Container } from "@mui/material";
import React, {useState} from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import DynamicPackageField from "./DynamicPackageField";
import "./style.css";

function DynamicForm({control, maxItinerary, items, register, setValue }) {
    const [params] = useSearchParams();
    const formType = params.get("formType");

  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "test",
  });


  // }

  return (
    formType === "add" ?
    <>
        <Grid container  sx={{marginTop:"1rem"}}>
          <Grid item xs={6} sm={6} md={10}>
            <Typography variant="h6" fontWeight="bold">Itinerary -</Typography>
          </Grid>

          <Grid item>
         
          {fields.map((item, index) => (
            <li key={item.id}>
              <DynamicPackageField register={register}  index={index}  formType={formType} setValue={setValue}/>
              <div style={{display:'flex', justifyContent:'end', padding:'0.2rem'}}>
              <Button type="button"  variant="outlined" onClick={() => remove(index)}>Delete</Button>
              </div>
              <br/>
              <hr/>
            </li>
          ))}
          </Grid>


  <Container style={{display:'flex', justifyContent:'end', padding:'0.2rem'}}>
            <Button
              type="button"
              variant="outlined"
           
              onClick={() => append({ Key: "test" })}
            >
            Add
            </Button>
            </Container>
        

        </Grid>
   
    </>
    :
    <>

{items.map((item, index) => (


            <li key={item.id}>
              <DynamicPackageField item={item} register={register}  index={index} formType={formType} setValue={setValue}/>
              {/* <Button type="button"  variant="outlined" onClick={() => remove(index)}>Delete</Button> */}
            </li>
          
          ))}          

    </>
  );
}
export default DynamicForm;
