import React, { useState, useEffect } from "react";

import {
  TextField,
  Autocomplete,
  Grid,
  Typography,
  MenuItem,
  Rating,
  Box,
  Paper,
  Chip,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";

import { GroupService } from "./service";
import TourService from "../package-form/service";
import { Controller } from "react-hook-form";
import { Cancel } from "@mui/icons-material";

const GroupDetails = ({
  groupDetails,
  register,
  setValue,
  control,
  formType,
  user_role,
  featImage,
  setFeatImage,
  handleUploadFile,
}) => {
  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [packagesSuggestion, setpackagesSuggestion] = useState([]);

  useEffect(() => {
    setValue(
      "destinationId",
      destinationSuggestion[0] ? destinationSuggestion[0]._id : ""
    );
  }, [destinationSuggestion]);

  // useEffect(() => {
  //   setValue("packages", packagesSuggestion[0] ? packagesSuggestion[0]?._id : null);
  // },[packagesSuggestion])

  const handleDestinationSuggestion = async (value) => {
    const { data, isSuccess, error } = await TourService.destinationSuggestion(
      value
    );
    isSuccess && setDestinationSuggestion(data?.data);
  };
  const handlePackagesSuggestion = async () => {
    const { data, isSuccess, error } = await GroupService.packagesSuggestion();
    isSuccess && setpackagesSuggestion(data?.data);
  };

  useEffect(() => {
    handlePackagesSuggestion();
  }, []);

  return (
    <>
      <Typography variant="h5">Group Details</Typography>
      <Box
        sx={{ marginTop: "1rem", padding: "3rem 1rem" }}
        component={Paper}
        elevation={4}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Group Name"
              variant="outlined"
              {...register("name")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="destination"
              options={destinationSuggestion?.map((option) => option?.name)}
              inputValue={destinationSuggestion?._id}
              getOptionLabel={(option) => (option ? option : "")}
              defaultValue={groupDetails.destinationName}
              disabled={formType === "edit" && user_role !== "Super"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination"
                  onChange={({ target }) =>
                    handleDestinationSuggestion(target.value)
                  }
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          {formType === "edit" && (
            <Grid item xs={12} sm={12} sx={{ marginY: "0.5rem" }}>
              <TextField
                helperText="Previous selected packages"
                variant="outlined"
                aria-readonly
                disabled
                value={groupDetails.packagesName?.map((item) => item)}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth {...register("packages")}>
              <InputLabel id="Packages">Packages</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    id="Packages"
                    label="Packages"
                    multiple
                    defaultvalue={groupDetails.packages?.map(
                      (item) => item._id
                    )}
                  >
                    {packagesSuggestion.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name="packages"
                defaultValue={[]}
                control={control}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              minRows={3}
              {...register("description")}
              fullWidth
            />
          </Grid>

          {featImage && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography>Featured Image</Typography>
              <Cancel
                sx={{
                  position: "relative",
                  top: "1.2rem",
                  left: "9.5rem",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => setFeatImage("")}
              />
              <div style={{ height: "10rem", width: "10rem" }}>
                <img src={featImage} width="100%" height="100%"></img>
              </div>
            </Grid>
          )}

          <Grid item xs={6} sm={6} md={6}>
            <TextField
              id="outlined-basic"
              helperText="select featured image"
              variant="outlined"
              type="file"
              onChange={handleUploadFile}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GroupDetails;
