import { axiosInstance } from "../../shared/axios";

const addCodeSnippet = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/code-snippet`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editCodeSnippet = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/code-snippet/${id}`, payload);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const CodeSnippetFormService = {
  addCodeSnippet,
  editCodeSnippet,
};
export default CodeSnippetFormService;
