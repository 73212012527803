import { axiosInstance } from "../../shared/axios";

const fetchSitemap = async ({
  currentPageLimit,
  currentPage,
  searchKey,
}) => {
  try {
    const response = await axiosInstance.get(
      `/site-map/all?limit=${currentPageLimit}&page=${currentPage}&searchKey=${searchKey}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const updateSitemap = async () => {
  try {
    const { status } = await axiosInstance.put(`/site-map/update`);
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const removeSitemap = async () => {
  try {
    const { status } = await axiosInstance.delete(`/site-map/remove-all`);
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

export const SitemapService = {
  fetchSitemap,
  updateSitemap,
  removeSitemap
};
