import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultCodeSnippetDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { uploadFile } from "../../shared/services";

import CodeSnippetService from "./service";
import "./style.css";
import CodeSnippetDetails from "./CodeSnippetDetails";

const getSchema = (formType) => {
  const commonSchema = {};
  return yup.object().shape(commonSchema);
};

const CodeSnippetForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();
  const { control } = useForm();

  const [codeSnippetDetails, setCodeSnippetDetails] = useState(
    formType === "edit" ? { ...data } : defaultCodeSnippetDetails
  );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate({
        ...codeSnippetDetails,
      });

      const payload = {
        ...data,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await CodeSnippetService.addCodeSnippet(payload)
          : await CodeSnippetService.editCodeSnippet(payload, data._id);
      isSuccess && navigate("/codeSnippet-view");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCodeSnippetDetailsChange = (name, value) => {
    setCodeSnippetDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <CodeSnippetDetails
          codeSnippetDetails={codeSnippetDetails}
          handleCodeSnippetDetailsChange={handleCodeSnippetDetailsChange}
          formType={formType}
          control={control}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default CodeSnippetForm;
