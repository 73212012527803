import React from "react";

import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const HeroSectionDetailsEdit = ({
  HeroSectionEditDetails: { title, subTitle, imgUrl },
  handleDetailsChange,
  handleUploadFile,
  formType,
}) => {
  const removeFile = () => {
    handleDetailsChange("imgUrl", "");
  };

  return (
    <>
      <Typography className="heading">Hero Section Details Form</Typography>
      <TextField
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => handleDetailsChange("title", e.target.value)}
        fullWidth
      />
      <TextField
        label="Subtitle"
        variant="outlined"
        value={subTitle}
        onChange={(e) => handleDetailsChange("subTitle", e.target.value)}
        fullWidth
      />

      <Grid item>
        <h4>Video</h4>
        {imgUrl ? (
          <>
            <CancelIcon
              sx={{
                position: "relative",
                top: "1.8rem",
                left: "15.9rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={removeFile}
            />
            <Card
              raised
              sx={{
                maxWidth: 280,
                maxHeight: 280,
                margin: "0",
                padding: "0.1em",
              }}
            >
              <CardMedia component="video" autoPlay loop muted src={imgUrl} />
            </Card>
            <br />
            <TextField
              helperText="select images"
              variant="outlined"
              type="file"
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          </>
        ) : (
          <TextField
            helperText="select images"
            variant="outlined"
            type="file"
            inputProps={{
              multiple: true,
            }}
            onChange={handleUploadFile}
          />
        )}
      </Grid>
    </>
  );
};

export default HeroSectionDetailsEdit;
