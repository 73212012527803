// export const pages = [
//   "All",
//   "landing",
//   // "destination",
//   "faq",
//   // "theme",
//   "about us",
//   "term",
//   // "package",
//   "contact us",
//   "privacy",
//   // "things todo",
//   // "places to visit",
//   // "story",
// ];

export const pages = [
  "All",
  "landing",
  "faq",
  "about us",
  "term",
  "contact us",
  "privacy",
];
