import React, { useEffect, useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PrivacyEdit from "./PrivacyEdit";
import { PolicyService } from "./service";

const PrivacyForm = () => {

  const [error, setError] = useState("");
  const [privacyData, setPrivacyData] = useState([]);
  const [content, setContent] = useState({});

  const fetchFacilityData = async () => {
    try {
      const data = await PolicyService.fetchPolicy();
      setPrivacyData(data?.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchFacilityData();
  }, []);

  const { control, handleSubmit, register, setValue, getValues, watch } = useForm({
    defaultValues: {
      title: [],
      body: []
    },
  });

  const watchTitle = watch("title")
  const watchBody = watch("body")

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setContent(getValues())
  }, [watchTitle, watchBody]);

  useEffect(() => {
    setValue("title", privacyData?.content?.map(d => d.title ? d.title : ""))
    setValue("body", privacyData?.content?.map(d => d.body))
  }, [privacyData?.content]);

  const addBody = (contentIndex, bodyIndex) => {
    const prevData = [...getValues().body[contentIndex]]
    console.log(prevData);
    setValue(`body.${contentIndex}`, [...prevData, ""])
  }

  const handleFormSubmit = async (payload) => {

    const { title, body } = payload;

    payload = { content: body.map((b, i) => ({ title: title[i], body: body[i] })) }

    console.log(payload);
    const { error, isSuccess } = await PolicyService.updatePolicy(payload);
    if (isSuccess) {
      window.location.href = window.location.href
    }
    error && handleError(error);
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="aboutUs-form-container">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <PrivacyEdit
          control={control}
          privacyData={content}
          register={register}
          setValue={setValue}
          addBody={addBody}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            className="aboutUs-form-button"
            variant="contained"
            sx={{ mr: "23px", mb: "10px" }}
          >
            Update
          </Button>
        </div>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default PrivacyForm;
