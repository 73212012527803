import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";


const fetchDestination = async ({ currentPageLimit, currentPage, searchByTitle, destinationId }) => {
  try {
    const { data } = await axiosInstance.get(
      `/admins/destination?limit=${currentPageLimit}&page=${currentPage}&name=${searchByTitle}&destinationId=${destinationId}`,null
      ); 
    return {data : data.data, total: data.dataCount};
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const addDestination = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/admins/destination`, payload);
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const editDestination = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/admins/destination/${id}`, payload);
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const deleteDestination = async ({id = ""}) => {
  try {
    const { status } = await axiosInstance.delete(`/admins/destination/${id}`, null);

    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const DestinationService = {
  fetchDestination: cancellableRequest(fetchDestination),
  addDestination,
  editDestination,
  deleteDestination,
};
