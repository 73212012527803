import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pages } from "./constant";
import { getColumns } from "./columns-def";
import {
  fetchSeoData,
  pageChanged,
  pageLeft,
  pageLimit,
  storyIdChanged,
  themeIdChanged,
  pageValueChanged,
  packageIdChanged,
  destinationIdChanged,
} from "./seo.slice";
import { fetchDestination } from "../tours/tour.slice";
import "./style.css";
import CallEnquiryFormService from "../call-enquiry-form/service";
import SeoFormService from "../seo-form/service";
import TourService from "../package-form/service";

const SeoView = ({category}) => {
  const {
    data,
    loading,
    total,
    currentPage,
    searchState,
    sortingInfo,
    searchKey,
    currentPageLimit,
    page,
    packageId,
    destinationId,
    themeId,
    stotyId,
  } = useSelector((state) => state.seo);
  const { destinationData } = useSelector((state) => state.tour);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    handlePageChange(category.join(","));
  },[category])

  useEffect(() => {
    handleDestinationSuggestion("");
    handleThemeSuggestion();
    handlePackageSuggestion("");
    handleThingsToDo();
    handlePlacesToVisit();
    handleStory();
  }, []);

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDestination());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSeoData());
  }, [
    dispatch,
    currentPage,
    viewType,
    sortingInfo,
    searchKey,
    currentPageLimit,
    searchState,
    page,
    packageId,
    destinationId,
    themeId,
    stotyId,
  ]);

  const [open, setOpen] = useState(false);
  const [pageValue, setPageValue] = useState("");
  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [themeSuggestion, setThemeSuggestion] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packageSuggestion, setPackageSuggestion] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [thingsToDoData, setThingsToDoData] = useState([]);
  const [placesToVisitData, setPlacesToVisitData] = useState([]);

  const handlePageChange = (value) => {
    setPageValue(value);
    dispatch(pageValueChanged(value));
  };

  const handleStory = async () => {
    const { data } = await SeoFormService.fetchStory();
    data && setStoryData(data);
  };
  const handlePlacesToVisit = async () => {
    const { data } = await SeoFormService.fetchPlacesToVisit();
    data && setPlacesToVisitData(data);
  };
  const handleThingsToDo = async () => {
    const { data } = await SeoFormService.fetchThingsToDo();
    data && setThingsToDoData(data);
  };

  const handlePackageSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await CallEnquiryFormService.packageSuggestion(
      value
    );
    if (isSuccess) {
      setPackageSuggestion(data?.data);
      if (data?.data?.length < 2)
        dispatch(packageIdChanged(data?.data[0]?._id));
    }
  };

  const handleThemeSuggestion = async (value) => {
    const { data, isSuccess } = await TourService.themeSuggestion(value);
    isSuccess && setThemeSuggestion(data?.data);
  };

  const handleDestinationSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await TourService.destinationSuggestion(value);
    if (isSuccess) {
      setDestinationSuggestion(data?.data);
      if (data?.data?.length < 2)
        dispatch(destinationIdChanged(data?.data[0]?._id));
    }
  };

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>SEOs</Typography>
        <Button
          onClick={() =>
            navigate("/seo-form?formType=add", {
              state: {
                destinationData: destinationData,
                prevPath: `/seo`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>
      <Grid
        container
        className="userview-navbar"
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <Grid item md={4} sm={4} xs={4}>
          <TextField
            label="Search by page"
            select
            variant="outlined"
            size="small"
            onChange={(e) => handlePageChange(e.target.value)}
            fullWidth
          >
            {pages.map((option, ind) => (
              <MenuItem key={ind} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4} md={4} sm={4}>
          {pageValue === "destination" && (
            <Autocomplete
              id="destination"
              size="small"
              options={destinationSuggestion?.map((option) => option.name)}
              onChange={(event, newValue) => {
                handleDestinationSuggestion(newValue);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Destination"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}
          {pageValue === "theme" && (
            <TextField
              fullWidth
              select
              label="Select Theme"
              size="small"
              onChange={(e) => dispatch(themeIdChanged(e.target.value))}
            >
              {themeSuggestion.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {pageValue === "package" && (
            <Autocomplete
              id="package"
              size="small"
              options={packageSuggestion?.map((option) => option.title)}
              onChange={(event, newValue) => {
                handlePackageSuggestion(newValue);
              }}
              getOptionLabel={(option) => (option ? option : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Package"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          )}

          {pageValue === "things todo" && (
            <TextField
              fullWidth
              select
              size="small"
              label="Select Things To Do"
              onChange={(e) => dispatch(storyIdChanged(e.target.value))}
            >
              {thingsToDoData?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          )}
          {pageValue === "place to visit" && (
            <TextField
              fullWidth
              select
              size="small"
              label="Select Places To Visit"
              onChange={(e) => dispatch(storyIdChanged(e.target.value))}
            >
              {placesToVisitData?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          )}

          {pageValue === "story" && (
            <TextField
              fullWidth
              select
              size="small"
              label="Select Story"
              onChange={(e) => dispatch(storyIdChanged(e.target.value))}
            >
              {storyData.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default SeoView;
