import React, { useEffect, useState } from "react";
import { TextField, Grid, Typography, Button } from "@mui/material";
import Editor from "../../shared/Editor";
import { Box } from "@mui/system";

const DynamicPackageField = ({ register, index, formType, item, setValue }) => {
  const [content, setContent] = useState(
    formType === "edit" ? item.description : ""
  );

  useEffect(() => {
    if (!!content) setValue(`fieldArray[${index}].description`, content);
  }, [content]);

  return (
    <Box component="card" sx={{ border: "1px  gray" }}>
      <Grid container spacing={1}>
        <Grid item sm={2} xs={2} md={2}>
          <TextField
            label="Day"
            type="number"
            defaultValue={formType === "add" ? index + 1 : item?.day}
            inputProps={{ type: "number", min: 1 }}
            {...register(`fieldArray.${index}.day`)}
            fullWidth
          />
        </Grid>
        <Grid item sm={10} xs={10} md={10}>
          <TextField
            label="Title"
            defaultValue={item?.title}
            {...register(`fieldArray.${index}.title`)}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12}>
          <TextField
            label="Keywords"
            defaultValue={item?.keyword}
            {...register(`fieldArray.${index}.keyword`)}
            fullWidth
          />
        </Grid>
        {/* <Grid item sm={12} xs={12} md={12}>
    <TextField 
        label="Sub Title"
        // {...register(`fieldArray.${index}.subTitle`)}
        // value={"subTitle"}
        onChange={(e) => setValue(`fieldArray.${index}.subTitle`, e.target.value)}
        fullWidth
        />      
    </Grid> */}

        <Grid item sm={12} xs={12} md={12}>
          <Editor
            content={content}
            setContent={setContent}
            placeholder={"fill the description in bullet points"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DynamicPackageField;
