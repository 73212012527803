import { Button, Avatar, FormControlLabel, Switch } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const handleStatus = () => {};

  const cols = [
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/code-snippet-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/codeSnippet-view`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) =>
        params.row.codeName ? params.row.codeName : "N.A",
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        params.row.enabled === true ? "Active" : "Inactive",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "codePlacement",
      headerName: "Code Placement",
      renderCell: (params) =>
        params.row.codePlacement ? params.row.codePlacement : "N.A.",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions codeSnippet={param.row} />,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
  ];
  return cols;
};
