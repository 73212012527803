import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      valueGetter: (params) => (params.row.title ? params.row.title : "N.A"),
      // ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/review-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/review`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "review",
      headerName: "Review",
      headerAlign: "center",
      valueGetter: (params) => (params.row.review ? params.row.review : "N.A"),
      // ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "rating",
      headerName: "Rating",
      // renderCell: (params) =>
      //   params.row.imageUrl ? params.row.imageUrl : "N.A",
      valueGetter: (params) => (params.row.rating ? params.row.rating : "N.A"),
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
    {
      field: "package",
      headerName: "Package Name",
      // renderCell: (params) =>
      //   params.row.imageUrl ? params.row.imageUrl : "N.A",
      valueGetter: (params) =>
        params.row.packageId !== null ? params.row.packageId?.name : "N.A",
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
    {
      field: "destination",
      headerName: "Destination Name",
      // renderCell: (params) =>
      //   params.row.imageUrl ? params.row.imageUrl : "N.A",
      valueGetter: (params) =>
        params.row.destinationId?.name !== null
          ? params.row.destinationId?.name
          : "N.A",
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
   

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions facility={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
