export const defaultPackageDetails = {
  title: "",
  days:"",
  nights:"",
  basePrice:"",
  groupTour:"",
  priority: "",
  groupSize:"",
  featuredImage:"",
  ratings:"",
  startlocation:"",
  endlocation:"",
};
export const defaultThemesDetails = {
name: "",
priority:""
};




