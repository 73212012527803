export const defaultDestinationDetails = {
    priority:0,
    name:"",
    state:"",
    zone:"",
    imageUrl:"",
    country:"",
    domestic:"",
    bestTimeToVisit:"",
    idealDuration:"",
    visa:"",
    website:"", 
}

export const domesticOptions = [
    {
      value: true,
      label: "Active",
    },
    {
      value: false,
      label: "Inactive",
    },
  ];
 export const visaOptions = [
    {
      value: "Required",
      label: "Required",
    },
    {
      value: "Not Required",
      label: "Not Required",
    },
  ];
 export const zoneOptions = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "North-India",
      label: "North-India",
    },
    {
      value: "South-India",
      label: "South-India",
    },
    {
      value: "East-India",
      label: "East-India",
    },
    {
      value: "West-India",
      label: "West-India",
    },
    {
      value: "West-India",
      label: "West-India",
    },
    {
      value: "North-East India",
      label: "North-East India",
    },
  ];

