import React, { useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";
import Editor from "../../shared/Editor";

import { defaultFaqDetails } from "./constants";

import FaqService from "../faq/service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";
import FaqDetails from "./FaqDetails";
import FaqFormService from "./service";
import { fetchDestination } from "../faq/faq.slice";
import { useDispatch, useSelector } from "react-redux";

const getSchema = (formType) => {
  const commonSchema = {
    // mainTitle: yup
    //   .string()
    //   .required()
    //   .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),
    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const FaqForm = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  const { destinationData } = useSelector((state) => state.faq);

  const [faqDetails, setFaqDetails] = useState(
    formType === "edit" ? { ...data } : defaultFaqDetails
  );
  const [typeDetails, setTypeDetails] = useState({});

  const [destinationId, setDestinationId] = useState(
    formType === "edit" ? data.destinationId?.destinationId : ""
  );
  const [type, setType] = useState(formType === "edit" ? data.type : "");

  useEffect(() => {
    dispatch(fetchDestination());
  }, []);

  useEffect(() => {
    if (typeDetails === "General") {
      setType(typeDetails);
      setDestinationId("");
    } else {
      setType(typeDetails?.name);
      setDestinationId(typeDetails?._id);
    }
  }, [typeDetails]);

  useEffect(() => {
    type === "General" && setDestinationId("");
  }, [type]);

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...faqDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        ...data,
        ...(destinationId !== undefined && { destinationId: destinationId }),
        ...(type && { type: type }),
      };

      const { error, isSuccess } =
        formType === "add"
          ? await FaqFormService.addFaq(payload)
          : await FaqFormService.editFaq(payload, payload._id);
      isSuccess && navigate("/faq");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setFaqDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <FaqDetails
          FaqDetails={faqDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
          destinationData={destinationData}
          type={type}
          setTypeDetails={setTypeDetails}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default FaqForm;
