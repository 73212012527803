import React, { useEffect, useState } from "react";

import { Button, Container, Snackbar, Typography } from "@mui/material";
import * as yup from "yup";
import Editor from "../../shared/Editor";

import { defaultBannerDetails } from "./constants";

import BannerService from "../banner/service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";
import BannerDetails from "./BannerDetails";
import OurPeopleFormService from "./service";
import { uploadFile } from "../../shared/services";

const getSchema = (formType) => {
  const commonSchema = {
    mainTitle: yup
      .string()
      .required()
      .matches(/^[a-zA-Z\s]*$/, "Enter Correct Title"),

    // description: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const BannerForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();

  console.log("data-",data);


  const [bannerDetails, setBannerDetails] = useState(
    formType === "edit" ? { ...data } : defaultBannerDetails
  );
  const [destinationId, setDestinationId] = useState("");

  useEffect(() => {
    setDestinationId(data ? data._id : "");
  }, [data]);

  // const [description, setDescription] = useState(
  //   formType === "edit" ? data.description : ""
  // );

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // const data = await getSchema(formType).validate(
      //   {
      //     ...bannerDetails,
      //   },
      //   { abortEarly: false }
      // );
      const payload = {
        ...bannerDetails,
      };
      const { error, isSuccess } =
        formType === "add"
          ? await OurPeopleFormService.addPeople(payload)
          : await OurPeopleFormService.editOurPeople(payload, destinationId);
      isSuccess && navigate("/statistics");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setBannerDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload); 
      setBannerDetails((data) => ({ ...data, ["iconUrl"]: res?.fileUrl }));
    } catch (error) {}
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <BannerDetails
          BannerDetails={bannerDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
          handleUploadFile={handleUploadFile}
        />

        {/* <Typography style={{ fontWeight: "bold" }}>Description -</Typography> */}
        {/* <Editor
          content={description}
          setContent={setDescription}
          placeholder={"Description"}
        /> */}

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default BannerForm;
