import { axiosInstance } from "../../shared/axios";

const fetchTrainerAchievements = async (trainerId = "") => {
  try {
    const { data } = await axiosInstance.post(`/admin/getCertificate`, {
      trainerId,
    });
    return data.response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


const deleteTrainerAchievement = async (achievemntId = "") => {
  try {
    const { status } = await axiosInstance.delete(
      `/admin/deleteCertificate?certificateId=${achievemntId}`
    );
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

const addTrainerAchievement = async (payload) => {
  try {
    const { status } = await axiosInstance.post("/admin/addCertificate", payload, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

const AchievementService = {
  fetchTrainerAchievements,
  deleteTrainerAchievement,
  addTrainerAchievement,
};

export default AchievementService;
