import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderCell: (params) => (params.row.name ? params.row.name : "-"),
      ...getCommonColDef(),
      width: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      valueGetter: (param) => (param.row.email ? param.row.email : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      valueGetter: (param) => param.row.role,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (param) => param.row.inviteAction.toLowerCase(),
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
    // {
    //   field: "edit",
    //   headerName: "View/Edit",
    //   renderCell: (param) => (
    //     <Button
    //       onClick={() =>
    //         navigate("/employee-details?formType=edit", {
    //           state: {
    //             data: param.row,
    //             prevPath: `/employee-details?view-type=${viewType}`,
    //           },
    //         })
    //       }
    //       variant="contained"
    //     >
    //       View / Edit
    //     </Button>
    //   ),
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions employees={param.row} />,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
  ];
  if (viewType === "tour-themes") {
    cols.splice(1, 1);
    cols.splice(2, 1);
  }
  return cols;
};
