import { axiosInstance } from "../../shared/axios";

const fetchContactDetails = async () => {
    try {
        const { data, status } = await axiosInstance.get(
            `/contact-details/get`, null
        );
        return { data: data.data, isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};

const updateContactDetails = async (payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `/contact-details/update?id=6516b495773ad82472da35a2`, payload
        );
        return { isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};



export const ContactUsService = {
    fetchContactDetails,
    updateContactDetails
};
