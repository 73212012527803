import { axiosInstance } from "../../shared/axios";

const addPackage = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/packages`, payload);
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response?.data?.message || e.message };
  }
};

const editPackage = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/packages/${id}`, payload);
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};
const addTheme = async (payload) => {
  try {
    const { status } = await axiosInstance.post(
      `/themes/create-theme`,
      payload
    );
    return { isSuccess: status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const editTheme = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/themes/${id}`, payload);
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const themeSuggestion = async (keyword) => {
  try {
    const { data, status } = await axiosInstance.get(
      `themes/suggestions`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};
const destinationSuggestion = async (keyword = "") => {
  try {
    const { data, status } = await axiosInstance.get(
      `destination/suggestions?limit=100&page=0&keyword=${keyword}`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};
const groupSuggestion = async (keyword) => {
  try {
    const { data, status } = await axiosInstance.get(
      `packages/suggestions?keyword=${keyword}`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};

const TourService = {
  addPackage,
  editPackage,
  addTheme,
  editTheme,
  themeSuggestion,
  destinationSuggestion,
  groupSuggestion,
};
export default TourService;
