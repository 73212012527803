import { axiosInstance } from "../../shared/axios";

const getHeroSectionDetailsView = async () => {
  // try {
  //   const response = await axiosInstance.get(`/landing`);
  //   if (response.status === 200 || response.status === 201) {

  //     return response;
  //   }
  // } catch (e) {

  //   return {
  //     error:
  //       e.response?.data?.message ||
  //       e.response?.data?.response?.message ||
  //       e.message,
  //   };
  try {
    const response = await axiosInstance.get(`/landing`);
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
  // }
};

// const HeroSectionViewFormService = { getHeroSectionDetailsView };
// export default HeroSectionViewFormService;

export const HeroSectionViewFormService = {
  // fetchUsers: cancellableRequest(fetchUsers),
  // fetchTrainers: cancellableRequest(fetchTrainers),
  getHeroSectionDetailsView,
  // undoUser,
  // toggleUserStatus,
  // updateBlockStatus,
  // logoutUser,
  // remarkUpdate,
  // fetchEmployees,
};
