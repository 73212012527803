import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReviewService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchByDestination: "",
  searchByPackage: "",
};

export const fetchReviewData = createAsyncThunk(
  "review/fetchReview",
  async (viewType, thunkAPI) => {
    const {
      searchKey,
      searchByDestination,
      searchByPackage,
      currentPage,
      currentPageLimit,
    } = thunkAPI.getState().review;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchByDestination,
      searchByPackage,
    };
    const data = await ReviewService.fetchReview(payload);
    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "review/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await ReviewService.deleteUser(id);
    if (isSuccess) {
      return id;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    searchPackageChanged(state, action) {
      state.searchByPackage = action.payload;
    },
    searchDestinationChanged(state, action) {
      state.searchByDestination = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.searchByPackage = "";
      state.searchByDestination = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReviewData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchReviewData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalData;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  pageLeft,
  pageLimit,
  searchDestinationChanged,
  searchPackageChanged,
} = reviewSlice.actions;
export default reviewSlice.reducer;
