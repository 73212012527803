import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { enquiries } from "./constants";

const EnquiryDetails = ({
  EnquiryDetails: { comment, status, query, firstName, phoneNo },
  handleDetailsChange,
  formType,
}) => {
  return (
    <>
      <Typography className="heading">Update Enquiry Status</Typography>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => handleDetailsChange("firstName", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Phone No."
            variant="outlined"
            value={phoneNo}
            onChange={(e) => handleDetailsChange("phoneNo", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            variant="outlined"
            label="Query"
            value={query}
            fullWidth
            multiline
            onChange={(e) => handleDetailsChange("query", e.target.value)}
            rows={2}
            sx={{ backgroundColor: "#f9f4f4" }}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            label="Comment"
            variant="outlined"
            value={comment}
            onChange={(e) => handleDetailsChange("comment", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Autocomplete
            id="status"
            options={enquiries}
            value={status}
            defaultValue={status}
            // onChange={(event, newValue) => {
            //   handleDestinationSuggestion(newValue);
            // }}
            onChange={(event, newValue) =>
              handleDetailsChange("status", newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EnquiryDetails;
