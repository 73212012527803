import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "title",
      headerName: "Title",
      valueGetter: (params) => (params.row.title ? params.row.title : "N.A"),
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/facility-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/facility`,
              },
            })
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: (param) =>
        param.row.description !== "<p><br></p>"
          ? param.row.description.replace(/<[^>]+>/g, "")
          : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
    {
      field: "imageUrl",
      headerName: "Image",
      // renderCell: (params) =>
      //   params.row.imageUrl ? params.row.imageUrl : "N.A",
      renderCell: (params) =>
        params.row.icon ? (
          <a href={`${params.row.icon}`}>Download Image</a>
        ) : (
          "No Image Available"
        ),
      ...getCommonColDef(),
      // width: 100,
      sortable: false,
      flex: 1,
    },
    

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions facility={param.row} />,
      ...getCommonColDef(),
      sortable: false,
      flex: 1,
    },
  ];
  return cols;
};
