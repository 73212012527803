import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Card,
  CardMedia,
  Grid,
  Rating,
  TextField,
  Typography,
  Button,
  Box,
} from "@mui/material";
import TourService from "../package-form/service";
import StoryFormService from "./service";
import MultiInput from "../../shared/SelectMultiOptions";
import CancelIcon from "@mui/icons-material/Cancel";
import { Cancel } from "@mui/icons-material";
import Editor from "../../shared/Editor";

const ThingDetails = ({
  storyDetails,
  prevDestinationName,
  prevTitleName,
  prevUserName,
  prevContentName,
  prevFeaturedImage,
  prevImages,
  image,
  imageGallery,
  resetImage,
  prevCreatedByName,
  prevTypeName,
  prevTagsName,
  handleDetailsChange,
  handleUploadFile,
  handleMultipleUploadFile,
  howToReach,
  setHowToReachPayload,
  formType,
  handleChange,
  content,
  setContent,
}) => {
  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [userSuggestion, setUserSuggestion] = useState([]);

  const [destination, setDestination] = useState("");
  const [user, setUser] = useState("");

  const options = ["things todo", "places to visit", "normal"];

  const [keywords, setKeywords] = useState(prevTagsName);

  useEffect(() => {
    handleDetailsChange("tags", keywords);
  }, [keywords]);

  const handleDestinationSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await TourService.destinationSuggestion(value);
    if (isSuccess) {
      setDestinationSuggestion(data?.data);
      if (data?.data?.length < 2)
        handleDetailsChange("destinationId", data?.data[0]?._id);
    }
  };

  const removeFile = () => {
    handleDetailsChange("image", "");
  };

  const handleUserSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await StoryFormService.profileSuggestion(value);
    if (isSuccess) {
      setUserSuggestion(data?.data);
      if (data?.data?.length < 2)
        handleDetailsChange("profileId", data?.data[0]?._id);
    }
  };

  const [changed, setChanged] = useState(false);

  useEffect(() => {}, [changed]);

  useEffect(() => {
    handleDestinationSuggestion(destination);
    handleUserSuggestion(user);
  }, []);

  return (
    <>
      <Typography className="heading">Things to Do Details</Typography>

      <>
        <TextField
          label="Things to Do Title"
          variant="outlined"
          defaultValue={prevTitleName}
          onChange={(e) => handleDetailsChange("title", e.target.value)}
          fullWidth
        />

        <TextField
          label="Best time to visit"
          variant="outlined"
          defaultValue={storyDetails.bestTimeToVisit}
          onChange={(e) =>
            handleDetailsChange("bestTimeToVisit", e.target.value)
          }
          fullWidth
        />

        <Autocomplete
          id="destination"
          options={destinationSuggestion?.map((option) => option.name)}
          defaultValue={prevDestinationName.name}
          onChange={(event, newValue) => {
            handleDestinationSuggestion(newValue);
          }}
          getOptionLabel={(option) => (option ? option : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Destination"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Editor
          content={content}
          setContent={setContent}
          placeholder={"Content"}
        />

        {/* <Autocomplete
          id="user"
          options={userSuggestion?.map((option) => option.name)}
          defaultValue={prevUserName.name}
          onChange={(event, newValue) => {
            handleUserSuggestion(newValue);
          }}
          getOptionLabel={(option) => (option ? option : "")}
          renderInput={(params) => (
            <TextField {...params} label="User" variant="outlined" fullWidth />
          )}
        /> */}

        {image && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography>Featured Image</Typography>
            <Cancel
              sx={{
                position: "relative",
                top: "1.2rem",
                left: "9.5rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={removeFile}
            />
            <div style={{ height: "10rem", width: "10rem" }}>
              <img src={image} width="100%" height="100%"></img>
            </div>
          </Grid>
        )}
        {imageGallery.length > 0 && (
          <Grid item spacing={1} xs={12} sm={12} md={12}>
            <Typography>Images</Typography>
            <div style={{ display: "flex" }}>
              {imageGallery.map((item, index) => (
                <>
                  <Cancel
                    sx={{
                      position: "relative",
                      top: "-0.5rem",
                      left: "7rem",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => resetImage(index)}
                  />
                  <img src={item} height="100px" width="100px"></img>
                </>
              ))}
            </div>
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "1rem",
          }}
        >
          <TextField
            type="file"
            helperText="Featured Image"
            onChange={handleUploadFile}
          />
          <TextField
            type="file"
            inputProps={{
              multiple: true,
            }}
            helperText="Images"
            onChange={handleMultipleUploadFile}
          />
        </Box>

        <MultiInput
          onChange={(e) => setKeywords(e.target.value)}
          setItinerary={setKeywords}
          itinerary={keywords}
          label="Add Tags"
        />

        <h5>How to Reach</h5>
        <TextField
          label="By Air"
          variant="outlined"
          defaultValue={howToReach[0]?.description}
          onChange={(e) => handleChange(0, e.target.value)}
          fullWidth
          multiline
          rows={3}
        />
        <TextField
          label="By Train"
          variant="outlined"
          defaultValue={howToReach[1]?.description}
          onChange={(e) => handleChange(1, e.target.value)}
          fullWidth
          multiline
          rows={3}
        />
        <TextField
          label="By Road"
          variant="outlined"
          defaultValue={howToReach[2]?.description}
          onChange={(e) => handleChange(2, e.target.value)}
          fullWidth
          multiline
          rows={3}
        />
      </>
    </>
  );
};

export default ThingDetails;
