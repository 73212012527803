import { axiosInstance } from "../../shared/axios";

const addStory = async (payload, id) => {
  try {
    const { status } = await axiosInstance.post(`/stories/add-story`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editStory = async (payload, id) => {
  try {
    const { status } = await axiosInstance.patch(
      `/stories/update-story/${id}`,
      payload
    );
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const profileSuggestion = async (keyword) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/user/suggestions?keyword=${keyword}`,
      null
    );
    return { data, isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.message };
  }
};

const StoryFormService = {
  addStory,
  editStory,
  profileSuggestion,
};
export default StoryFormService;
