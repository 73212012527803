import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cancelPlanSub from "./service";

const initialState = {
  plan: null,
  planDetail: null, 
  cancelPlanSuccess: false,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  fetchError: "",
  cancelError: "",
};


export const fetchPlan = createAsyncThunk(
  "planSubscription/fetchPlan",
  async (_, thunkAPI) => {
    const { data, error } = await cancelPlanSub.fetchPlan();
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return data.requestList;
  }
);
export const viewPlan = createAsyncThunk(
  "planSubscription/viewPlan",
  async (id, thunkAPI) => {
    const { data, error } = await cancelPlanSub.viewPlan(id);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return data.subscriptionDetails;
  }
);

export const cancelPlan = createAsyncThunk(
  "planSubscription/cancelplan",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await cancelPlanSub.cancelPlan(id);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return isSuccess;
  }
);

const cancelPlanSlice = createSlice({
  name: "planSubscription",
  initialState,
  reducers: {
    clearFetchError: (state) => {
      state.fetchError = "";
    },
    clearCancelError: (state) => {
      state.cancelError = "";
    },
    clearIsSuccess: (state) => {
      state.cancelPlanSuccess = false;
      state.planDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlan.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchPlan.rejected, (state, action) => {
      state.loading = "failed";
      state.plan = null;
      state.fetchError = action.payload;
    });
    builder.addCase(fetchPlan.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.plan = action.payload;
    });
    builder.addCase(viewPlan.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(viewPlan.rejected, (state, action) => {
      state.loading = "failed";
      state.planDetail = null;
      state.fetchError = action.payload;
    });
    builder.addCase(viewPlan.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.planDetail = action.payload[0];
    });
    builder.addCase(cancelPlan.rejected, (state, action) => {
      state.loading = "failed";
      state.cancelError = action.payload;
    });
    builder.addCase(cancelPlan.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.cancelPlanSuccess = action.payload;
      state.plan = null;
    });
  },
});

export default cancelPlanSlice.reducer;
export const { clearFetchError, clearCancelError, clearIsSuccess } =
  cancelPlanSlice.actions;
