import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchTripEnquiry = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  status,
  searchByLocation,
  startDate,
  endDate,
}) => {
  try {
    const response = await axiosInstance.get(
      `/packages/group/enquiry?type=plan&limit=${currentPageLimit}&page=${currentPage}&from=${startDate}&to=${endDate}&search=${searchKey}&locationName=${searchByLocation}&status=${
        status === "all" ? "" : status
      }`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const fetchDestination = async () => {
  try {
    const { data } = await axiosInstance.get(`/admins/destination`, null);
    return { data: data.data };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const TripEnquiryService = {
  fetchTripEnquiry,
  fetchDestination,
};
