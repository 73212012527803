import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchTour,
  fetchDestination,
  pageChanged,
  pageLeft,
  pageLimit,
  searchTitleChanged,
  searchRatingChanged,
  searchLocationChanged,
} from "./tour.slice";
import { getColumns } from "./columns-def";
import "./style.css";
import { adminRoleGetterService } from "../../shared/services";

const TourView = () => {
  const {
    data,
    error,
    destinationData,
    total,
    loading,
    currentPage,
    searchByTitle,
    serchByRating,
    searchByLocation,
    currentPageLimit,
  } = useSelector((state) => state.tour);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");
  const user_role = adminRoleGetterService();

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchDestination());
  }, [dispatch]);

  useEffect(() => {
    if (user_role !== "Super") {
      dispatch(searchLocationChanged(user_role));
    }
    dispatch(fetchTour(viewType));
  }, [
    dispatch,
    viewType,
    currentPage,
    searchByTitle,
    serchByRating,
    searchByLocation,
    currentPageLimit,
  ]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>
          {viewType === "tour-packages" ? "Tour Packages" : "Tour Themes"}
        </Typography>
        {user_role === "Super" ? (
          <Button
            onClick={() =>
              navigate(
                viewType === "tour-packages"
                  ? "/package-form?formType=add"
                  : "/theme-form?formType=add",
                {
                  state: {
                    prevPath: `/tour?view-type=${viewType}`,
                  },
                }
              )
            }
            variant="contained"
          >
            Add New
          </Button>
        ) : (
          <Button
            onClick={() =>
              navigate(
                viewType === "tour-packages" && "/package-form?formType=add",

                {
                  state: {
                    prevPath: `/tour?view-type=${viewType}`,
                  },
                }
              )
            }
            variant="contained"
            disabled={viewType === "tour-themes"}
            sx={{ display: viewType === "tour-themes" && "none" }}
          >
            Add New
          </Button>
        )}
      </Grid>
      <Grid container className="userview-navbar" gap={1}>
        {viewType === "tour-packages" ? (
          <>
            <Grid item xs={4} md={4}>
              <TextField
                label={`Search by Title`}
                value={searchByTitle}
                className="tour-filters"
                onChange={(e) => dispatch(searchTitleChanged(e.target.value))}
                fullWidth
                size="small"
              />
            </Grid>
            {user_role === "Super" && (
              <Grid item xs={4} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="Location">Location</InputLabel>
                  <Select
                    id="location"
                    label="Location"
                    onChange={(e) =>
                      dispatch(searchLocationChanged(e.target.value))
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    <ListSubheader
                      sx={{ color: "#f39b60", fontWeight: "bold" }}
                    >
                      International -
                    </ListSubheader>
                    {destinationData?.map(
                      (items) =>
                        items.domestic === false && (
                          <MenuItem value={items.name}>{items.name}</MenuItem>
                        )
                    )}
                    <ListSubheader
                      sx={{ color: "#f39b60", fontWeight: "bold" }}
                    >
                      Domestic -
                    </ListSubheader>
                    {destinationData?.map(
                      (items) =>
                        items.domestic === true && (
                          <MenuItem value={items.name}>{items.name}</MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12} md={12}>
            <TextField
              label={`Search by Title`}
              value={searchByTitle}
              className="tour-filters"
              onChange={(e) => dispatch(searchTitleChanged(e.target.value))}
              fullWidth
              size="small"
            />
          </Grid>
        )}
        <Grid item></Grid>
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType, user_role)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
      <Snackbar
        open={error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      />
    </Container>
  );
};

export default TourView;
