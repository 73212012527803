export const defaultEnquiryDetails = {
  packageId: "",
  name: "",
  phone: "",
  email: "",
  comments: "",
  status: "",
  cab: "",
  from: "",
  to: "",
  isGroup: false,
};

export const enquiries = ["answered", "requested", "converted"];

export const TrueFalseOptions = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
];

export const cabsOptions = [
  "Swift Dzire(4+1)",
  "Toyota Etios(4+1)",
  "Toyota Innova(7+1)(6+1)",
  "Toyota Innova Crysta(7+1)(6+1)",
  "Marazzo(7+1)",
  "Toyota Fortuner(7+1)",
  "Ertiga(7+1)",
  "Tempo Traveller(12+1)",
  "Tempo Traveller(16+1)",
  "Luxury Tempo Traveller(11+1)",
];
export const hotelRatingOption = [3, 4, 5];
export const hotelTypeOption = ["deluxe", "luxury"];
