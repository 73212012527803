import React, { useState, useEffect } from "react";

import {
  TextField,
  Autocomplete,
  Chip,
  Grid,
  Typography,
  MenuItem,
  IconButton,
  InputAdornment,
  Rating,
  Container,
  TextareaAutosize,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
} from "@mui/material";
import { Box } from "@mui/system";

const FaqDetails = ({
  FaqDetails: { question, answer, isFlag },
  handleDetailsChange,
  destinationData,
  setTypeDetails,
  type,
  formType,
}) => {
  return (
    <>
      <Typography className="heading">FAQ Details</Typography>

      {formType === "edit" && (
        <TextField
          label="selected type"
          variant="outlined"
          value={type}
          disabled
          fullWidth
        />
      )}

      <FormControl fullWidth>
        <InputLabel htmlFor="Location">Type</InputLabel>
        <Select
          id="location"
          label="Type"
          defaultValue={type}
          onChange={(e) => setTypeDetails(e.target.value)}
        >
          <MenuItem value="General">General</MenuItem>

          <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
            International -
          </ListSubheader>
          {destinationData?.map(
            (items) =>
              items.domestic === false && (
                <MenuItem value={items}>{items.name}</MenuItem>
              )
          )}
          <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
            Domestic -
          </ListSubheader>
          {destinationData?.map(
            (items) =>
              items.domestic === true && (
                <MenuItem value={items}>{items.name}</MenuItem>
              )
          )}
        </Select>
      </FormControl>

      <TextField
        label="Question"
        variant="outlined"
        value={question}
        onChange={(e) => handleDetailsChange("question", e.target.value)}
        fullWidth
      />
      <TextField
        label="Answer"
        variant="outlined"
        value={answer}
        onChange={(e) => handleDetailsChange("answer", e.target.value)}
        fullWidth
        multiline
        rows={2}
        maxRows={4}
      />

      <FormGroup>
        <FormControlLabel
          label="List this FAQ?"
          control={
            <Checkbox
              defaultChecked={isFlag}
              onChange={(e) => handleDetailsChange("isFlag", e.target.checked)}
            />
          }
        />
      </FormGroup>

      {/* <TextareaAutosize
        label="Answer"
        variant="outlined"
        value={answer}
        onChange={(e) => handleDetailsChange("answer", e.target.value)}
        fullWidth
      ></TextareaAutosize> */}
    </>
  );
};

export default FaqDetails;
