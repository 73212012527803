import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PayoutService from "./service";

const initialState = {
  dueSessions: {
    data: [
      {
        _id: "62c3c32510047d2093fe7ae6",
        date: "2022-07-05T08:09:01.935Z",
        userData: {
          name: "vartika mangal",
        },
      },
      {
        _id: "62c3c35d463577eb55ae673c",
        date: "2022-07-03T08:09:01.935Z",
        userData: {
          name: "tarun prajapa",
        },
      },
    ],
    commission: 300,
  },
  paidSessions: {
    data: [
      {
        _id: "62c3c32510047d2093fe7ae6",
        date: "2022-07-05T08:09:01.935Z",
        userData: {
          name: "vartika mangal",
        },
      },
      {
        _id: "62c3c35d463577eb55ae673c",
        date: "2022-07-03T08:09:01.935Z",
        userData: {
          name: "tarun prajapa",
        },
      },
    ],
    amount: 10000,
  },
  selectedTab: "DUE",
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
};

export const fetchTrainerSessions = createAsyncThunk(
  "payout/fetchTrainerSessions",
  async (trainerId, thunkAPI) => {
    const { sessions, commission, error } =
      await PayoutService.fetchTrainerSessions(trainerId);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return { sessions, commission };
  }
);

export const updateTrainerCommission = createAsyncThunk(
  "payout/updateTrainerCommission",
  async ({ trainerId, commission }, thunkAPI) => {
    const { error } = await PayoutService.updateTrainerCommission(
      trainerId,
      commission
    );
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return commission;
  }
);

const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    pageLeft: (state) => {
      state.sessions = [];
    },
    tabChanged: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerSessions.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchTrainerSessions.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(fetchTrainerSessions.fulfilled, (state, action) => {
      const { sessions, commission } = action.payload;
      state.sessions = sessions;
      state.commission = commission;
      state.loading = "succeeded";
    });
    builder.addCase(updateTrainerCommission.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(updateTrainerCommission.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.commission = action.payload;
    });
  },
});

export const { pageLeft, tabChanged } = payoutSlice.actions;
export default payoutSlice.reducer;

