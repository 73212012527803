import { axiosInstance } from "../../shared/axios";

const addBanner = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/banner/add`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editBanner = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/more-about/update/${id}`, payload);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const BannerFormService = { addBanner, editBanner };
export default BannerFormService;
