import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getBlockState } from "./utils";
import {
  getFormattedDate,
  getCommonColDef,
  getFormattedOnlyDate,
  getFormattedTime,
} from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "queryID",
      headerName: "Query Id",
      valueGetter: (params) =>
        params.row.queryID ? params.row.queryID : "N.A",
      ...getCommonColDef(),
      width: 150,
      sortable: false,
      // flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => (params.row.name ? params.row.name : "N.A"),
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      // flex: 1,
    },
    {
      field: "destination",
      headerName: "State",
      valueGetter: (params) =>
        params.row.packageId ? params.row.packageId.destination?.name : "N.A",
      ...getCommonColDef(),
      // width: 500,
      sortable: false,
      // flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      // headerAlign: "center",
      valueGetter: (params) => (params.row.status ? params.row.status : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },
    {
      field: "resolveQuery",
      headerName: "Update Status",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate("/tour-enquiry-form?formType=edit", {
              state: {
                data: param.row,
                prevPath: `/tour-enquiry`,
              },
            })
          }
          variant="outlined"
        >
          Update
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      // flex: 2,
    },

    {
      field: "createdAt",
      headerName: "Created Date",
      valueGetter: (params) =>
        params.row.createdAt
          ? getFormattedOnlyDate(params.row.createdAt)
          : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
    },
    {
      field: "createdTime",
      headerName: "Created Time",
      valueGetter: (params) =>
        params.row.createdAt ? getFormattedTime(params.row.createdAt) : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
    },

    {
      field: "phone",
      headerName: "Phone",
      valueGetter: (params) => (params.row.phone ? params.row.phone : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      // flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      // headerAlign: "center",
      valueGetter: (params) => (params.row.email ? params.row.email : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      // flex: 1,
    },
    {
      field: "hotelRating",
      headerName: "Hotel Rating",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.hotelsCategory.rating
          ? params.row.hotelsCategory.rating + " Star"
          : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },
    {
      field: "hotelType",
      headerName: "Hotel Type",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.hotelsCategory.type ? params.row.hotelsCategory.type : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      // flex: 1,
    },
    {
      field: "travelDate",
      headerName: "Travel Date",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.travelDate ? params.row.travelDate.split("T")[0] : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },
    {
      field: "returnDate",
      headerName: "Return Date",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.returnDate ? params.row.returnDate.split("T")[0] : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },
    // {
    //   field: "fromTo",
    //   headerName: "From - To",
    //   // headerAlign: "center",
    //   valueGetter: (params) =>
    //     params.row.from ? `${params.row.from} <-> ${params.row.to}` : "N.A",
    //   ...getCommonColDef(),
    //   sortable: false,
    //   width: 400,
    //   // flex: 1,
    // },

    {
      field: "pickUpDropOff",
      headerName: "PickUp - Drop",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.pickUp
          ? `${params.row.pickUp} <-> ${params.row.dropOff}`
          : "N.A",
      // ...getCommonColDef(),
      sortable: false,
      width: 200,
      // flex: 1,
    },

    {
      field: "adult",
      headerName: "No. of Adult",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.noOfPersons ? params.row.noOfPersons.adults : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },
    {
      field: "child",
      headerName: "No. of Child",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.noOfPersons ? params.row.noOfPersons.child : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },

    {
      field: "kids",
      headerName: "No. of Kids",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.noOfPersons ? params.row.noOfPersons.kids : "N.A",
      ...getCommonColDef(),
      sortable: false,
      width: 150,
      // flex: 1,
    },

    {
      field: "comment",
      headerName: "Comment",
      // headerAlign: "center",
      valueGetter: (params) =>
        params.row.comment ? params.row.comment : "N.A",
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      // flex: 1,
    },

    {
      field: "additionalRequirementText",
      headerName: "Additional Requirement",
      headerAlign: "center",
      valueGetter: (params) =>
        params.row.additionalRequirementText
          ? params.row.additionalRequirementText
          : "N.A",
      // ...getCommonColDef(),
      sortable: false,
      width: 300,
      // flex: 1,
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (param) => <Actions facility={param.row} />,
    //   ...getCommonColDef(),
    //   sortable: false,
    //   flex: 1,
    // },
  ];
  return cols;
};
