import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchReview = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  searchByDestination,
  searchByPackage,
}) => {
  try {
    const response = await axiosInstance.get(
      `/reviews?limit=${currentPageLimit}&page=${currentPage}&packageName=${searchByPackage}&destinationName=${searchByDestination}&rating=${searchKey}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const ReviewService = {
  fetchReview,
};
