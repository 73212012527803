import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MonitoringService from "./service";

const initialState = {
  data: [],
  selectedTab: "ACTIVE USERS",
  selectedMonth: "",
  selectedYear: "",
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
};

export const fetchMonthlyUsersStats = createAsyncThunk(
  "monitoring/fetchMonthlyUsersStats",
  async (_, thunkAPI) => {
    const { selectedMonth, selectedTab, selectedYear } =
      thunkAPI.getState().monitoring;
    const { data, error } = await MonitoringService.fetchMonthlyUsersStats({
      userType: selectedTab === "ACTIVE TRAINERS" ? "TRAINER" : "USER",
      statType: selectedTab === "REGISTERED USERS" ? "REGISTERED" : "ACTIVE",
      month: selectedMonth,
      year: selectedYear,
    });
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return data;
  }
);

const monitoringSlice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    yearChanged: (state, action) => {
      state.selectedYear = action.payload;
    },
    monthChanged: (state, action) => {
      state.selectedMonth = action.payload;
    },
    tabChanged: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonthlyUsersStats.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchMonthlyUsersStats.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(fetchMonthlyUsersStats.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = "succeeded";
    });
  },
});

export default monitoringSlice.reducer;
export const { yearChanged, monthChanged, tabChanged } =
  monitoringSlice.actions;

  