import { getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate, viewType) => {
  const cols = [
    {
      field: "url",
      headerName: "URL",
      valueGetter: (params) => (params.row.url ? params.row.url : "N.A"),
      ...getCommonColDef(),
      // width: 500,
      align: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      valueGetter: (params) => (params.row.priority ? params.row.priority : "N.A"),
      ...getCommonColDef(),
      sortable: false,
      // width: 300,
      flex: 1,
    },
  ];
  return cols;
};
