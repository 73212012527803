import React, { useState } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import ConfirmationPopup from "../../shared/ConfirmationPopup";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { getBlockState, getActiveState } from "./utils";
import { list_unlistFAQ } from "./service";
import { fetchFaqData } from "./faq.slice";

const Actions = ({ faq }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  // const [error, setError] = useState("");

  // const [carActive, setCarActive] = useState(false);

  // // dispatch(list_unlistFAQ(faq._id));

  // const handleListUnlistChange = async (event) => {
  //   setCarActive((prevValue) => !prevValue);


  //   var payload = {
  //     isFlag: carActive,
  //   };

  //   const { isSuccess, error } = await list_unlistFAQ(payload, faq._id);
  //   if (isSuccess === true) {
  //     dispatch(fetchFaqData);
  //   } else {
  //     setError(error);
  //     setTimeout(() => {
  //       setError("");
  //     }, 2000);
  //     // alert("error");
  //   }
  // };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox name={faq._id} defaultChecked={faq.isFlag} disabled />
          }
        />
      </FormGroup>
      {/* <Snackbar
        open={error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      /> */}
    </>
  );
};

export default Actions;
