import { axiosInstance } from "../../shared/axios";

const addFaq = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/landing/pages/faq`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editFaq = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(
      `/landing/pages/faq/${id}`,
      payload
    );
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const FaqFormService = { addFaq, editFaq };
export default FaqFormService;
