import { axiosInstance } from "../../shared/axios";

const fetchTerms = async () => {
    try {
        const { data, status } = await axiosInstance.get(
            `/terms-conditions/get`, null
        );
        return { data: data.data, isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};

const updateTerms = async (payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `/terms-conditions/update?id=6516a91611e7b7f060095540`, payload
        );
        return { isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};



export const TermsService = {
    fetchTerms,
    updateTerms
};
