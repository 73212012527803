import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultHeroSectionEditDetails } from "./constants";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { uploadFile } from "../../shared/services";
import HeroSectionDetailsEdit from "./HeroSectionDetailsEdit";
import HeroSectionEditFormService from "./service";
import "./style.css";

const getSchema = (formType) => {
  const commonSchema = {};
  return yup.object().shape(commonSchema);
};

const HeroSectionFormEdit = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");

  const selectorData = useSelector((state) => state?.heroSection?.heroSection);

  const [data, setData] = useState(selectorData?.landingData);

  const [heroSectionEditDetails, setHeroSectionEditDetails] = useState(
    formType === "edit" ? { ...data } : defaultHeroSectionEditDetails
  );

  const landingPayload = {
    landingData: {
      title: heroSectionEditDetails.title,
      subTitle: heroSectionEditDetails.subTitle,
      imgUrl: heroSectionEditDetails.imgUrl,
    },
  };

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const { isSuccess } =
        await HeroSectionEditFormService.editHeroSectionForm(landingPayload);
      isSuccess && navigate("/hero-section");
      setError(error);
      error && handleError(error);
    } catch (error) {
      handleError(error.errors[0]);
    }
  };

  const handleDetailsChange = (name, value) => {
    setHeroSectionEditDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setHeroSectionEditDetails((data) => ({
        ...data,
        ["imgUrl"]: res?.fileUrl,
      }));
    } catch (error) {}
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <HeroSectionDetailsEdit
          HeroSectionEditDetails={heroSectionEditDetails}
          handleDetailsChange={handleDetailsChange}
          formType={formType}
          handleUploadFile={handleUploadFile}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default HeroSectionFormEdit;
