import {
  Container,
  Grid,
  Paper,
  Snackbar,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";

const TermsEdit = ({
  privacyData,
  addBody,
  control
}) => {

  const { title: titleFields, body: bodyFields } = privacyData;

  return (
    <>
      <Container sx={{ padding: "0.5rem" }}>
        <Box
          component={Paper}
          elevation={8}
          p={2}
          marginTop={4}
          marginBottom={2}
        >
          <Typography sx={{ fontSize: "1.1rem", marginY: "0.5rem" }}>
            Terms and Conditions
          </Typography>
          {
            titleFields?.map((p, index) => (
              <Grid container spacing={2} mt={3} >
                <Grid item xs={6} sm={12} md={12}>
                  {index != 0 ?
                    <Controller
                      render={({ field }) =>
                        <TextField
                          helperText="Title"
                          fullWidth
                          size="small"
                          variant="standard"
                          {...field}
                        />}
                      defaultValue={p}
                      name={`title.${index}`}
                      control={control}
                    /> : null}
                  {
                    Object.values(bodyFields?.[index])?.map((b, i) => (
                      <Controller
                        render={({ field }) =>
                          <TextField
                            helperText={`Body ${i + 1}`}
                            fullWidth
                            size="small"
                            variant="standard"
                            sx={{mt:1}}
                            rows={4}
                            {...field}
                          />}
                        control={control}
                        defaultValue={b}
                        name={`body.${index}.${i}`}
                      />
                    ))
                  }
                  <Button variant="outlined" sx={{mt:1}} onClick={() => addBody(index, p?.body?.length)} >Add Body</Button>
                </Grid>
              </Grid>))
          }
        </Box>
      </Container>
    </>
  );
};

export default TermsEdit;