import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SeoService } from "./service";

const initialState = {
  total: 0,
  data: [],
  page: "",
  packageId: "",
  destinationId: "",
  themeId: "",
  stotyId: "",
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchState: "",
};

export const fetchSeoData = createAsyncThunk(
  "seo/fetchSeo",
  async (viewType, thunkAPI) => {
    const {
      searchKey,
      currentPage,
      currentPageLimit,
      searchState,
      page,
      packageId,
      destinationId,
      themeId,
      stotyId,
    } = thunkAPI.getState().seo;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchState,
      page,
      packageId,
      destinationId,
      themeId,
      stotyId,
    };
    const data = await SeoService.fetchSeo(payload);
    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "seo/deleteUser",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await SeoService.deleteUser(id);
    if (isSuccess) {
      return id;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const seoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
      state.page = "";
    },
    searchStateChanged(state, action) {
      state.searchState = action.payload;
    },

    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
    pageValueChanged(state, action) {
      if (action.payload === "All") {
        state.page = ["landing","faq","contact us", "term" , "privacy" , "about us"].join(",");
        state.packageId = "";
        state.destinationId = "";
        state.themeId = "";
        state.stotyId = "";
      } else {
        state.page = action.payload;
        state.packageId = "";
        state.destinationId = "";
        state.themeId = "";
        state.stotyId = "";
      }
    },
    packageIdChanged(state, action) {
      state.packageId = action.payload;
    },
    destinationIdChanged(state, action) {
      state.destinationId = action.payload;
    },
    themeIdChanged(state, action) {
      state.themeId = action.payload;
    },
    storyIdChanged(state, action) {
      state.stotyId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSeoData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchSeoData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchStateChanged,
  pageLeft,
  pageLimit,
  storyIdChanged,
  themeIdChanged,
  pageValueChanged,
  packageIdChanged,
  destinationIdChanged,
} = seoSlice.actions;
export default seoSlice.reducer;
