import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchFaq = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  searchByLocation,
}) => {
  try {
    const response = await axiosInstance.get(
      `/landing/pages/faq?limit=${currentPageLimit}&page=${currentPage}&title=${searchKey}&type=${searchByLocation}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const list_unlistFAQ = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(
      `/landing/pages/faq/${id}`,
      payload
    );
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

const fetchDestination = async () => {
  try {
    const { data } = await axiosInstance.get(`/admins/destination`, null);
    return { data: data.data };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const FaqService = {
  fetchFaq,
  fetchDestination,
};
