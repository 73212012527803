import React, { useEffect, useState } from "react";

import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getColumns } from "./columns-def";
import {
  fetchCodeSnippetData,
  pageChanged,
  pageLeft,
  pageLimit,
} from "./codeSnippet.slice";

import "./style.css";

const CodeSnippetView = () => {
  const { data, loading, total, currentPage, currentPageLimit } = useSelector(
    (state) => state.codeSnippet
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCodeSnippetData());
  }, [dispatch, currentPage, viewType, currentPageLimit]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Code Snippet</Typography>
        <Button
          onClick={() =>
            navigate("/code-snippet-form?formType=add", {
              state: {
                data: data,
                prevPath: `/codeSnippet-view`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>

      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default CodeSnippetView;
