import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  heroSection: {},
};

export const heroSectionSlice = createSlice({
  name: "HeroSection",
  initialState,
  reducers: {
    storeHeroSection: (state, action) => {
      state.heroSection = action.payload;
    },
  },
});

export const { storeHeroSection } = heroSectionSlice.actions;

export default heroSectionSlice.reducer;
