import { axiosInstance } from "../../shared/axios";

const fetchSeo = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  searchState,
  page,
  packageId,
  destinationId,
  themeId,
  stotyId,
}) => {
  try {
    const response = await axiosInstance.get(
      `/meta?limit=${currentPageLimit}&pageNo=${currentPage}&page=${page}&destinationId=${destinationId}&themeId=${themeId}&packageId=${packageId}&storyId=${stotyId}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const SeoService = {
  fetchSeo,
};
