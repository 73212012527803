import { axiosInstance } from "../../shared/axios";
import qs from "qs";

const fetchRobotsFile = async () => {
  try {
    const response = await axiosInstance.get(
      `/robot-txt/get`,
      null ,
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const updateRobotsFile = async (payload) => {
  try {
    const { status } = await axiosInstance.put(`/robot-txt/update?id=65017d4f10c693163497b4e3` , qs.stringify(payload) , {
      headers : {
        "Content-type": "application/x-www-form-urlencoded",
      }
    });
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

export const RobotsFileService = {
  fetchRobotsFile,
  updateRobotsFile,
};
