import React, { useEffect, useState } from "react";

import { Container, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../shared/Loader";

import { fetchStats } from "./stats.slice";

import "./style.css";

const Statistics = () => {
  const stats = useSelector((state) => state.stat);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStats());
  }, []);

  if (stats?.loading !== "succeeded") {
    return <Loader />;
  }

  return (
    <Container className="stats">
      <Grid container marginBottom={4} gap={1}>
        <Grid item md={7} xs={12} sm={12}>
          <Typography className="stats-header">Statistics</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Grid item xs={3}>
          <Paper
            sx={{
              bgcolor: "bisque",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
              height: "8rem",
            }}
            elevation={4}
          >
            <Typography
              variant="h4"
              color="black"
              sx={{ fontWeight: "bold", fontSize: "3rem" }}
              component="div"
            >
              {stats?.activeUsers}
            </Typography>
            <Typography variant="body1" color="black" component="div">
              Active Users
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper
            sx={{
              bgcolor: "bisque",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
              height: "8rem",
            }}
            elevation={4}
          >
            <Typography
              variant="h4"
              color="black"
              sx={{ fontWeight: "bold", fontSize: "3rem" }}
              component="div"
            >
              {stats?.clients}
            </Typography>
            <Typography variant="body1" color="black" component="div">
              Converted Clients
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper
            sx={{
              bgcolor: "bisque",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
              height: "8rem",
            }}
            elevation={4}
          >
            <Typography
              variant="h4"
              color="black"
              sx={{ fontWeight: "bold", fontSize: "3rem" }}
              component="div"
            >
              {stats?.enquiries}
            </Typography>
            <Typography variant="body1" color="black" component="div">
              No. of Enquiries
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper
            sx={{
              bgcolor: "bisque",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
              height: "8rem",
            }}
            elevation={4}
          >
            <Typography
              variant="h4"
              color="black"
              sx={{ fontWeight: "bold", fontSize: "3rem" }}
              component="div"
            >
              {stats?.packages}
            </Typography>
            <Typography variant="body1" color="black" component="div">
              Total Packages
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Statistics;
