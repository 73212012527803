import React, { useState, useEffect } from "react";

import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  Rating,
  Container,
  Link,
} from "@mui/material";
import { zoneOptions, visaOptions, domesticOptions } from "./constant";
import { DestinationService } from "./service";
import { Cancel } from "@mui/icons-material";

const DestinationDetails = ({
  destinationDetails: {
    priority,
    name,
    state,
    zone,
    imageUrl,
    country,
    domestic,
    bestTimeToVisit,
    idealDuration,
    visa,
    website,
  },
  handleChange,
  handleDetailsChange,
  handleUploadFile,
  formType,
  user_role,
  image,
  setImage,
  zonePriority,
  setZonePriority,
  howToReach,
}) => {
  return (
    <>
      <Typography className="heading">Destination Details</Typography>
      <Grid container spacing={1}>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => handleDetailsChange("name", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="State"
            variant="outlined"
            value={state}
            onChange={(e) => handleDetailsChange("state", e.target.value)}
            fullWidth
            disabled={user_role !== "Super" && formType === "edit"}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Zone"
            select
            variant="outlined"
            value={zone}
            onChange={(e) => handleDetailsChange("zone", e.target.value)}
            fullWidth
          >
            {zoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Country"
            variant="outlined"
            value={country}
            onChange={(e) => handleDetailsChange("country", e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Domestic"
            select
            variant="outlined"
            value={domestic}
            onChange={(e) => handleDetailsChange("domestic", e.target.value)}
            fullWidth
          >
            {domesticOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Visa"
            select
            variant="outlined"
            value={visa}
            onChange={(e) => handleDetailsChange("visa", e.target.value)}
            fullWidth
          >
            {visaOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Best Time To Visit"
            fullWidth
            variant="outlined"
            value={bestTimeToVisit}
            onChange={(e) =>
              handleDetailsChange("bestTimeToVisit", e.target.value)
            }
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Ideal Duration"
            fullWidth
            value={idealDuration}
            variant="outlined"
            onChange={(e) =>
              handleDetailsChange("idealDuration", e.target.value)
            }
          />
        </Grid>

        {/* <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Website"
            fullWidth
            value={website}
            variant="outlined"
            onChange={(e) => handleDetailsChange("website", e.target.value)}
          />
        </Grid> */}

        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Popular Destination Priority"
            type="number"
            fullWidth
            value={priority}
            inputProps={{
              min: 0,
              max: 9,
            }}
            variant="outlined"
            onChange={(e) => handleDetailsChange("priority", e.target.value)}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <TextField
            label="Zonal Priority"
            type="number"
            fullWidth
            inputProps={{
              min: 0,
              max: 4,
            }}
            helperText="Set range 0-4"
            value={zonePriority}
            variant="outlined"
            onChange={(e) => setZonePriority(e.target.value)}
          />
        </Grid>
        {image && (
          <Grid item xs={12} sm={12} md={12}>
            <Typography>Image</Typography>
            <Cancel
              sx={{
                position: "relative",
                top: "1.2rem",
                left: "9.5rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setImage("")}
            />
            <div style={{ height: "10rem", width: "10rem" }}>
              <img src={image} width="100%" height="100%"></img>
            </div>
          </Grid>
        )}

        <Grid item md={6} sm={6} xs={12}>
          <TextField
            id="outlined-basic"
            helperText="select images"
            variant="outlined"
            type="file"
            inputProps={{
              multiple: true,
            }}
            onChange={handleUploadFile}
          />
        </Grid>

        <Grid container spacing={2} p={2} marginTop={2}>
          <h4>How to Reach</h4>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              label="By Air"
              variant="outlined"
              defaultValue={howToReach[0]?.description}
              onChange={(e) => handleChange(0, e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              label="By Train"
              variant="outlined"
              defaultValue={howToReach[1]?.description}
              onChange={(e) => handleChange(1, e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              label="By Road"
              variant="outlined"
              defaultValue={howToReach[2]?.description}
              onChange={(e) => handleChange(2, e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DestinationDetails;
