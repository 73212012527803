import { axiosInstance } from "../../shared/axios";

const fetchPolicy = async () => {
    try {
        const { data, status } = await axiosInstance.get(
            `/privacy-policy/get`, null
        );
        return { data: data.data, isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};

const updatePolicy = async (payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `privacy-policy/update?id=6516a0b3e23cbdc0e3515bd5`, payload
        );
        return { isSuccess: status === 200 };
    } catch (e) {
        return {
            error: e.response.data || e.message,
        };
    }
};



export const PolicyService = {
    fetchPolicy,
    updatePolicy
};
