import React, { useEffect, useState } from "react";
import { Box, Button, Container, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ContactUsService } from "./service";

const ContactForm = () => {

    const [error, setError] = useState("");
    const [contactData, setContactData] = useState({});

    const fetchFacilityData = async () => {
        try {
            const data = await ContactUsService.fetchContactDetails();
            setContactData(data?.data?.[0]);
        } catch (error) { }
    };

    useEffect(() => {
        fetchFacilityData();
    }, []);

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            email: contactData.email,
            primaryPhone: contactData.primaryPhone,
            secondaryPhone: contactData.secondaryPhone,
            address: contactData.address,
            aboutUs: contactData.aboutUs,
        },
    });

    const handleFormSubmit = async (payload) => {
        const { error, isSuccess } = await ContactUsService.updateContactDetails(payload);
        if (isSuccess) {
            window.location.href = window.location.href
        }
        error && handleError(error);
    };

    const handleError = (error) => {
        setError(error);
        setTimeout(() => {
            setError("");
        }, 2000);
    };

    const formObj = {
        email: contactData?.email,
        primaryPhone: contactData?.primaryPhone,
        secondaryPhone: contactData?.secondaryPhone,
        address: contactData?.address,
        aboutUs: contactData?.aboutUs,
    }

    useEffect(() => {
        for (const key in formObj) {
            setValue(key, formObj[key])
        }
    }, [contactData])


    return (
        <Container className="aboutUs-form-container">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Container sx={{ padding: "0.5rem" }}>
                    <Box
                        component={Paper}
                        elevation={8}
                        p={2}
                        marginTop={4}
                        marginBottom={2}
                    >
                        <Typography sx={{ fontSize: "1.1rem", marginY: "0.5rem", mb:3 }}>
                            Contact Details
                        </Typography>
                        {
                            Object.keys(formObj).map((key) => (
                                <Controller
                                    render={({ field }) =>
                                        <TextField
                                            helperText={key == "primaryPhone" ? "Primary phone" : key == "secondaryPhone" ? "Secondary phone" : key}
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            {...field}
                                        />}
                                    name={key}
                                    control={control}
                                />
                            ))
                        }
                    </Box>
                </Container>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="submit"
                        className="aboutUs-form-button"
                        variant="contained"
                        sx={{ mr: "23px", mb: "10px" }}
                    >
                        Update
                    </Button>
                </div>
            </form>
            <Snackbar
                open={!!error}
                message={error}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            />
        </Container>
    );
};

export default ContactForm;
