import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { roles } from "./constant.js";
import { sendInvite } from "./service";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getSchema } from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { fetchEmployeeData } from "./employee.slice.js";

function InviteEmployeePopup({ open, setOpen }) {
  const [error, setError] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: 370,
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(getSchema()),
  });

  const dispatch = useDispatch();

  const handleInvite = async (data) => {
    try {
      const response = await sendInvite(data);
      handleClose();
      dispatch(fetchEmployeeData());

      if (response.status === 200 || response.status === 201) {
        handleClose();
        setError(response.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (e) {
      setError(e);
      setTimeout(() => {
        setError("");
      }, 2000);
      // alert("error");
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} sx={{ width: "7rem" }}>
        Invite
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Invite Admin
          </Typography>
          <form onSubmit={handleSubmit(handleInvite)}>
            <TextField
              label="Email"
              placeholder="name@email.com"
              {...register("email")}
              onChange={(event) => setValue("email", event.target.value)}
              required
              fullWidth
              id="fullWidth"
              sx={{ position: "relative", top: "2rem" }}
              error={errors.email}
              helperText={errors.email?.message}
            />
            <Autocomplete
              // disablePortal
              // id="combo-box-demo"
              onChange={(event, value) => setValue("role", value)}
              options={roles}
              sx={{ width: 333, position: "relative", top: "4rem" }}
              renderInput={(params) => (
                <TextField {...params} label="Role" required />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ position: "relative", top: "6rem", left: "13rem" }}
            >
              Send Invite
            </Button>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={error}
        message={error}
        severity="error"
        autoHideDuration={2000}
      />
    </div>
  );
}

export default InviteEmployeePopup;
