import { axiosInstance } from "../../shared/axios";

const updateEnquiry = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/callEnquiry/${id}`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};
const addEnquiry = async (payload) => {
  try {
    const { status, error } = await axiosInstance.post(`/callEnquiry`, payload);

    return { isSuccess: status === 201 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const packageSuggestion = async (keyword) => {
  try {
    const { data, status } = await axiosInstance.get(
      `packages?&name=${keyword}`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};
const groupPackageSuggestion = async () => {
  try {
    const { data, status } = await axiosInstance.get(
      `packages?group=true`,
      null
    );
    return { data, isSuccess: status === 200 };
  } catch (e) {
    return { error: e.message };
  }
};

const CallEnquiryFormService = {
  addEnquiry,
  updateEnquiry,
  packageSuggestion,
  groupPackageSuggestion,
};
export default CallEnquiryFormService;
