import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchReviewData,
  pageChanged,
  pageLeft,
  pageLimit,
  searchDestinationChanged,
  searchKeyChanged,
  searchPackageChanged,
  sortingChanged,
} from "./review.slice";
import { getColumns } from "./columns-def";
import "./style.css";

const ReviewView = () => {
  const {
    data,
    loading,
    total,
    currentPage,
    searchKey,
    searchByDestination,
    searchByPackage,
    currentPageLimit,
    remarkStatus,
  } = useSelector((state) => state.review);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReviewData());
  }, [
    dispatch,
    currentPage,
    viewType,
    searchKey,
    searchByDestination,
    searchByPackage,
    currentPageLimit,
    remarkStatus,
  ]);

  const [open, setOpen] = useState(false);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Reviews</Typography>
        <Button
          sx={{ width: "7rem" }}
          onClick={() =>
            navigate("/review-form?formType=add", {
              state: {
                prevPath: `/review`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>
      <Grid
        container
        className="userview-navbar"
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <TextField
          label={`Search by Destination`}
          value={searchByDestination}
          size="small"
          onChange={(e) => dispatch(searchDestinationChanged(e.target.value))}
          fullWidth
        />
      </Grid>

      <DataGrid
        rows={data ? data : []}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default ReviewView;
