import { Button, Avatar } from "@mui/material";
import Actions from "./Actions";
import { getFormattedDate, getCommonColDef } from "../../shared/utils";
import "./style.css";

export const getColumns = (navigate) => {
  const cols = [
   
    {
      field: "name",
      headerName: "Name",
      renderCell: (param) => (param.row.name ? param.row.name : "NA"),
      ...getCommonColDef(),
      width: 250,
      sortable: false,
    },
    {
      field: "state",
      headerName: "State",
      valueGetter: (param) => (param.row.state ? param.row.state : "NA"),
      ...getCommonColDef(),
      sortable: false,
    },
    {
      field: "idealDuration",
      headerName: "Ideal Duration",
      valueGetter: (param) =>
        param.row.idealDuration ? param.row.idealDuration : "NA",
      ...getCommonColDef(),
      sortable: false,
    },
     {
      field: "edit",
      headerName: "View/Edit",
      renderCell: (param) => (
        <Button
          onClick={() =>
            navigate(             
                "/destination-form?formType=edit",
              {
                state: {
                  data: param.row,
                  prevPath: `/destination`,
                },
              }
            )
          }
          variant="outlined"
        >
          View / Edit
        </Button>
      ),
      ...getCommonColDef(),
      sortable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      renderCell: (param) => <Actions destination={param.row} />,
      ...getCommonColDef(),
    },

  
  ];
 

  return cols;
};
