import React, { useEffect, useState } from "react";

import {
  Autocomplete, Grid,
  Rating,
  TextField,
  Typography
} from "@mui/material";
import TourService from "../package-form/service";
import ReviewFormService from "./service";
import Editor from "../../shared/Editor";

const ReviewDetails = ({
  prevDestinationName,
  title,
  prevPackageName,
  review,
  rating,
  content,
  setContent,
  handleDetailsChange,
  formType,
}) => {

  const [destinationSuggestion, setDestinationSuggestion] = useState([]);
  const [packageSuggestion, setPackageSuggestion] = useState([]);

  const [destination, setDestination] = useState("");
  const [packages, setPackage] = useState("");

  const handleDestinationSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await TourService.destinationSuggestion(value);
    if (isSuccess) {
      setDestinationSuggestion(data?.data);
      if (data?.data?.length < 2)
        handleDetailsChange("destinationId", data?.data[0]?._id);
    }
  };

  const handlePackageSuggestion = async (value) => {
    if (value === null) {
      value = "";
    }
    const { data, isSuccess } = await ReviewFormService.packageSuggestion(
      value
    );
    if (isSuccess) {
      setPackageSuggestion(data?.filterData?.themePackages);
      if (data?.filterData?.themePackages?.length < 2)
        handleDetailsChange(
          "packageId",
          data?.filterData?.themePackages[0]?._id
        );
    }
  };

  useEffect(() => {
    handleDestinationSuggestion(destination);
    handlePackageSuggestion(packages);
  }, []);

  return (
    <>
      <Typography className="heading">Review Details</Typography>
      <TextField
        label="Review Title"
        variant="outlined"
        defaultValue={title}
        onChange={(e) => handleDetailsChange("title", e.target.value)}
        fullWidth
      />
{/* 
      <TextField
        label="Review Description"
        variant="outlined"
        defaultValue={review}
        onChange={(e) => handleDetailsChange("review", e.target.value)}
        fullWidth
        multiline
        rows={3}
      /> */}
      <Editor content={content} setContent={setContent} placeholder={"Review"}/>

      <Grid item xs={12} sm={12} md={12}>
        <Typography component="legend">Rating</Typography>
        <Rating
          name="rating"
          size="large"
          fullWidth
          defaultValue={rating}
          onChange={(e, value) => handleDetailsChange("rating", value)}
        />
      </Grid>

      <Autocomplete
        id="destination"
        options={destinationSuggestion?.map((option) => option.name)}
        defaultValue={prevDestinationName.name}
        onChange={(event, newValue) => {
          handleDestinationSuggestion(newValue);
        }}
        getOptionLabel={(option) => (option ? option : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Destination"
            variant="outlined"
            fullWidth
          />
        )}
      />

      <Autocomplete
        id="package"
        options={packageSuggestion?.map((option) => option.name)}
        defaultValue={prevPackageName === null ? "" : prevPackageName.name}
        onChange={(event, newValue) => {
          handlePackageSuggestion(newValue);
        }}
        getOptionLabel={(option) => (option ? option : "")}
        renderInput={(params) => (
          <TextField {...params} label="Package" variant="outlined" fullWidth />
        )}
      />
    </>
  );
};

export default ReviewDetails;
