import { axiosInstance } from "../../shared/axios";

const editHeroSectionForm = async (payload) => {
  try {
    const response = await axiosInstance.put(`/landing`, payload);
    return { isSuccess: response?.status === 200 || response?.status === 201 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const HeroSectionEditFormService = { editHeroSectionForm };
export default HeroSectionEditFormService;
