import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchGroupTourEnquiryData,
  fetchDestination,
  pageChanged,
  pageLeft,
  pageLimit,
  searchKeyChanged,
  sortingChanged,
  statusChanged,
  searchLocationChanged,
} from "./groupTourEnquiry.slice";
import { getColumns } from "./columns-def";
import "./style.css";

const GroupTourEnquiryView = () => {
  const {
    data,
    loading,
    total,
    destinationData,
    searchByLocation,
    currentPage,
    sortingInfo,
    searchKey,
    currentPageLimit,
    remarkStatus,
    status,
  } = useSelector((state) => state.groupTourEnquiry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndate] = useState("");

  const viewType = params.get("view-type");

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchDestination());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGroupTourEnquiryData({ startDate, endDate }));
  }, [
    dispatch,
    currentPage,
    viewType,
    sortingInfo,
    searchKey,
    searchByLocation,
    currentPageLimit,
    remarkStatus,
    status,
    endDate,
  ]);

  const [open, setOpen] = useState(false);

  const statusOptions = [
    {
      lable: "Answered",
      value: "answered",
    },
    {
      lable: "Requested",
      value: "requested",
    },
    {
      lable: "Converted",
      value: "converted",
    },
    {
      lable: "All",
      value: "all",
    },
  ];

  const handleStartDateSelection = ({ target: { value } }) => {
    setStartDate(value);
  };
  const handleEndDateSelection = ({ target: { value } }) => {
    setEndate(value);
  };

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Group Tour Enquiries</Typography>
      </Grid>
      <Grid container spacing={1} sx={{ display: "flex", margin: "1rem 0" }}>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label={`Search by Name, Email, and Query id`}
            value={searchKey}
            onChange={(e) => dispatch(searchKeyChanged(e.target.value))}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={4} sm={4}>
          <FormControl fullWidth size="small">
            <InputLabel htmlFor="Location">State</InputLabel>
            <Select
              id="location"
              label="Location"
              onChange={(e) => dispatch(searchLocationChanged(e.target.value))}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                International -
              </ListSubheader>
              {destinationData?.map(
                (items) =>
                  items.domestic === false && (
                    <MenuItem value={items.name}>{items.name}</MenuItem>
                  )
              )}
              <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                Domestic -
              </ListSubheader>
              {destinationData?.map(
                (items) =>
                  items.domestic === true && (
                    <MenuItem value={items.name}>{items.name}</MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            label={`Search by Status`}
            select
            value={status}
            onChange={(e) => dispatch(statusChanged(e.target.value))}
            size="small"
            fullWidth
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.lable}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item md={4} xs={4} sm={4}>
          <Typography>From</Typography>
          <TextField
            type="date"
            id="filter"
            name="startDate"
            variant="outlined"
            value={startDate}
            size="small"
            fullWidth
            onChange={handleStartDateSelection}
          />
        </Grid>
        <Grid item md={4} xs={4} sm={4}>
          <Typography>To</Typography>
          <TextField
            type="date"
            id="filter"
            name="endDate"
            variant="outlined"
            value={endDate}
            size="small"
            fullWidth
            onChange={handleEndDateSelection}
          />
        </Grid>
      </Grid>

      <DataGrid
        rows={data ? data : []}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
        style={{ height: "65vh" }}
      />
    </Container>
  );
};

export default GroupTourEnquiryView;
