import { axiosInstance } from "../../shared/axios";

const fetchSocialData = async ({
  currentPageLimit,
  currentPage,
  searchKey,
  searchState,
}) => {
  try {
    const response = await axiosInstance.get(
      `/socialmedia?limit=${currentPageLimit}&page=${currentPage}`,
      null
    );
    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

const deleteSocial = async (id = "") => {
  try {
    const { status } = await axiosInstance.delete(`/socialmedia/${id}`, null);
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};
const updateStatus = async (id = "") => {
  try {
    const { status } = await axiosInstance.delete(
      `/socialmedia/active/${id}`,
      null
    );
    return { isSuccess: status === 200 };
  } catch (e) {
    return {
      error: e.response.data || e.message,
    };
  }
};

export const SocialLinksService = {
  fetchSocialData,
  deleteSocial,
  updateStatus,
};
