import React, { useState } from "react";

import { Button, Container, Snackbar } from "@mui/material";
import * as yup from "yup";

import { defaultStoryDetails } from "./constants";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import StoryDetails from "./StoryDetails";
import StoryFormService from "./service";
import "./style.css";
import { uploadFile, uploadMultiFile } from "../../shared/services";
import {
  adminIdGetterService,
  adminRoleGetterService,
} from "../../shared/services";

const getSchema = (formType) => {
  const commonSchema = {
    // title: yup.string().required(),
    // // description: yup.string().required(),
    // destinationId: yup.string().required(),
    // profileId: yup.string().required(),
  };
  return yup.object().shape(commonSchema);
};

const StoryForm = () => {
  const [params] = useSearchParams();
  const formType = params.get("formType");
  const {
    state: { data },
  } = useLocation();
  const user_role = adminRoleGetterService();
  const adminId = adminIdGetterService();

  const [storyDetails, setStoryDetails] = useState(
    formType === "edit" ? { ...data } : defaultStoryDetails
  );
  const [profileId, setProfileId] = useState(
    formType === "edit" ? data.profileId._id : ""
  );

  const [content, setContent] = useState(
    formType === "edit" ? data.content : ""
  );

  const [imageGallery, setImageGallery] = useState(
    formType === "edit" ? data.imageGallery : []
  );

  const [inputArr, setInputArr] = useState([
    {
      title: "By Air",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243875002/Vector%20%284%29.png",
      description: formType === "edit" ? data?.howToReach[0]?.description : "",
    },
    {
      title: "By Train",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243961183/Vector%20%285%29.png",
      description: formType === "edit" ? data?.howToReach[0]?.description : "",
    },
    {
      title: "By Road",
      icon: "https://travel-hangout.s3.ap-south-1.amazonaws.com/Travelangouts/1675243980422/Vector%20%286%29.png",
      description: formType === "edit" ? data?.howToReach[0]?.description : "",
    },
  ]);

  const handleChange = (idx, value) => {
    if (idx === 0) {
      inputArr[0].description = value;
    } else if (idx === 1) {
      inputArr[1].description = value;
    } else {
      inputArr[2].description = value;
    }

    // setHowToReachPayload(inputArr);
  };

  if (data === undefined) {
    var prevDestinationName = "";
    var prevUserName = "";
    var prevTitleName = "";
    // var prevContentName = "";
    // var prevImageName = "";
    var prevCreatedByName = "";
    var prevTypeName = "";
    var prevTagsName = [];
  } else {
    var prevDestinationName = data.destinationId;
    var prevUserName = data.profileId;
    // var prevContentName = data.content;
    // var prevImageName = data.image;
    var prevCreatedByName = data.createdBy;
    var prevTypeName = data.type;
    var prevTagsName = data.tags;
    var prevTitleName = data.title;
  }

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await getSchema(formType).validate(
        {
          ...storyDetails,
        },
        { abortEarly: false }
      );
      const payload = {
        ...data,
        profileId: !!profileId ? profileId : adminId,
        imageGallery: imageGallery,
        howToReach: inputArr,
        content: content,
        createdBy: adminId,
      };

      const { error, isSuccess } =
        formType === "add"
          ? await StoryFormService.addStory(payload)
          : await StoryFormService.editStory(payload, payload._id);
      isSuccess && navigate("/story");
      setError(error);
      error && handleError(error);
    } catch (error) {
      // handleError(error.errors[0]);
    }
  };

  const handleUploadFile = async ({ target }) => {
    const files = target.files[0];

    let formData = new FormData();

    formData.append("file", files);

    let payload = formData;

    try {
      const res = await uploadFile(payload);
      setStoryDetails((data) => ({ ...data, ["image"]: res?.fileUrl }));
    } catch (error) {}
  };

  //

  const handleMultipleUploadFile = async ({ target }) => {
    const files = target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    try {
      const { fileUrl, isSuccess, error } = await uploadMultiFile(formData);
      let files = [];
      fileUrl.map((item) => {
        files.push(item.fileLocation);
      });
      isSuccess && imagesHandler(files);
      error && handleError(error);
    } catch (error) {
      handleError(error);
    }
  };

  const imagesHandler = (files) => {
    if (imageGallery.length === 0) {
      setImageGallery(files);
    } else {
      setImageGallery([...imageGallery, ...files]);
    }
  };

  const resetImage = (index) => {
    const newImages = imageGallery.filter((item, ind) => ind !== index);
    setImageGallery(newImages);
  };
  //

  const handleDetailsChange = (name, value) => {
    setStoryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <Container className="trainer-form-container">
      <form onSubmit={handleFormSubmit}>
        <StoryDetails
          handleDetailsChange={handleDetailsChange}
          handleUploadFile={handleUploadFile}
          formType={formType}
          user_role={user_role}
          prevDestinationName={prevDestinationName}
          prevTitleName={prevTitleName}
          prevUserName={prevUserName}
          // prevContentName={prevContentName}
          // prevImageName={prevImageName}
          image={storyDetails.image}
          prevCreatedByName={prevCreatedByName}
          prevTypeName={prevTypeName}
          prevTagsName={prevTagsName}
          howToReach={storyDetails.howToReach}
          storyDetails={storyDetails}
          handleChange={handleChange}
          content={content}
          setContent={setContent}
          setProfileId={setProfileId}
          getSchema={getSchema}
          imageGallery={imageGallery}
          resetImage={resetImage}
          handleMultipleUploadFile={handleMultipleUploadFile}
        />

        <Button
          type="submit"
          className="trainer-form-button"
          variant="contained"
        >
          Submit
        </Button>
      </form>
      <Snackbar
        open={!!error}
        message={error}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />
    </Container>
  );
};

export default StoryForm;
