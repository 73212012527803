import { axiosInstance } from "../../shared/axios";

const addPeople = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/statistics/add-card`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editOurPeople = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/statistics/update/${id}`, payload);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const OurPeopleFormService = { addPeople, editOurPeople };
export default OurPeopleFormService;
