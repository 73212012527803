import React, { useState, useEffect } from "react";

import {
  TextField,
  Autocomplete,
  Chip,
  Grid,
  Typography,
  MenuItem,
  IconButton,
  InputAdornment,
  Rating,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { Cancel } from "@mui/icons-material";


const ThemeDetails = ({
    ThemeDetails: {
    name,
    priority,
  },
  handleDetailsChange,
  formType,
  image,
  setImage,
  handleUploadFile
}) => {


  return (
    <>      

      <Typography className="heading">Theme Details</Typography>
      <TextField
        label="Theme Name"
        variant="outlined"
        value={name}
        onChange={(e) => handleDetailsChange("name", e.target.value)}
        fullWidth
      />


            <TextField
            label="Priority"
            type="number"
            fullWidth
            value={priority}
            variant="outlined"
            onChange={(e) => handleDetailsChange("priority", e.target.value)}
            />
       

{image && (
        <Container>
            <Typography>Image</Typography>
            <Cancel
              sx={{
                position: "relative",
                top: "1.2rem",
                left: "9.5rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => setImage("")}
            />
            <div style={{ height: "10rem", width: "10rem" }}>
              <img src={image} width="100%" height="100%"></img>
            </div>

            </Container>     
        )}
      
      <Container>
        <TextField
          id="outlined-basic"
          helperText="select images"
          variant="outlined"
          type="file"
          inputProps={{
            multiple: true,
          }}
          onChange={handleUploadFile}
        />
      </Container>
    </>
  );
};

export default ThemeDetails;
