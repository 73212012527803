import { axiosInstance } from "../../shared/axios";

const fetchPlan = async () => {
  try {
    const { data } = await axiosInstance.get(
      `/subscription/requestList`
    );
   
    return { data: data.response };
  } catch (e) {
    return { error: e.response.status };
  }
};

const viewPlan = async (id) => {
  try {
    const { data } = await axiosInstance.get(
      `/admin/subscriptionDetails?planId=${id}`
    );
    return { data: data.response };
  } catch (e) {
    return { error: e.response.status };
  }
};

const cancelPlan = async (payload) => {
  try {
    const { status } = await axiosInstance.delete(
      `/admin/cancelSubscription?planId=${payload}`
    );
    return { isSuccess: status === 200 };
  } catch (e) {
    return { error: e.response?.status };
  }
};

const cancelPlanSub = {
  fetchPlan,
  viewPlan,
  cancelPlan,
};
export default cancelPlanSub;
