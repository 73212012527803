import { axiosInstance } from "../../shared/axios";

const addSocial = async (payload) => {
  try {
    const { status } = await axiosInstance.post(`/socialmedia`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editSocial = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(`/socialmedia/${id}`, payload);
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const SeoFormService = {
  addSocial,
  editSocial,
};
export default SeoFormService;
