import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchData,
  fetchDestination,
  pageChanged,
  pageLeft,
  pageLimit,
  searchLocationChanged,
  searchTitleChanged,
} from "./destination.slice";
import { getColumns } from "./columns-def";
import "./style.css";
import { adminRoleGetterService } from "../../shared/services";

const DestinationView = () => {
  const { data, loading, total, currentPage, searchByTitle, currentPageLimit, searchByLocation, destinationData } =
    useSelector((state) => state.destination);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");
  const user_role = adminRoleGetterService();

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDestination())
  }, []);

  useEffect(() => {
    if (user_role !== "Super") {
      dispatch(searchTitleChanged(user_role));
    }
    dispatch(fetchData());
  }, [dispatch, currentPage, viewType, searchByTitle, currentPageLimit, searchByLocation]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>Destinations</Typography>

        {user_role === "Super" &&
        <Button
          sx={{
            width: "7rem",
          }}
          onClick={() =>
            navigate("/destination-form?formType=add", {
              state: {
                prevPath: `/destination`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>}
      </Grid>

      <Grid
        // item
        // xs={6}
        // md={3}
        // className="userview-navbar"
        sx={{
          display: "flex",
          margin: "1rem 0",
        }}
      >
        {user_role === "Super" && (
          <>
            <TextField
              label={`Search by Name`}
              value={searchByTitle}
              onChange={(e) => dispatch(searchTitleChanged(e.target.value))}
              fullWidth
              size="small"
              sx={{ width: "80%", marginRight: "7rem" }}
            />
            <Grid item xs={4} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel htmlFor="Location">Location</InputLabel>
                <Select
                  id="location"
                  label="Location"
                  onChange={(e) =>
                    dispatch(searchLocationChanged(e.target.value))
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <ListSubheader
                    sx={{ color: "#f39b60", fontWeight: "bold" }}
                  >
                    International -
                  </ListSubheader>
                  {destinationData?.map(
                    (items) =>
                      items.domestic === false && (
                        <MenuItem value={items._id}>{items.name}</MenuItem>
                      )
                  )}
                  <ListSubheader
                    sx={{ color: "#f39b60", fontWeight: "bold" }}
                  >
                    Domestic -
                  </ListSubheader>
                  {destinationData?.map(
                    (items) =>
                      items.domestic === true && (
                        <MenuItem value={items._id}>{items.name}</MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <DataGrid
        rows={data}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default DestinationView;
