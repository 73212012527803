import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";

const BannerDetails = ({
  BannerDetails: {
    imageUrl,
    title,
    description,
    redirectUrl
  },
  handleUploadFile,
  handleDetailsChange,
  formType,
}) => {


  const removeFile = () => {
    handleDetailsChange("imageUrl", "");
  };

  return (
    <>
      <Typography className="heading">More About Us Details</Typography>
      <TextField
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => handleDetailsChange("title", e.target.value)}
        fullWidth
      />

      <TextField
        label="Description"
        variant="outlined"
        value={description}
        onChange={(e) => handleDetailsChange("description", e.target.value)}
        fullWidth
      />

      <TextField
        label="Redirect URL"
        variant="outlined"
        value={redirectUrl}
        onChange={(e) => handleDetailsChange("redirectUrl", e.target.value)}
        fullWidth
      />
       {/* <TextField
        label="url"
        variant="outlined"
        value={subTitle}
        onChange={(e) => handleDetailsChange("subTitle", e.target.value)}
        fullWidth
      /> */}

      <Grid item>
        <h4>Image</h4>
        {imageUrl ? (
          <>
            <CancelIcon
              sx={{
                position: "relative",
                top: "1.8rem",
                left: "15.9rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={removeFile}
            />
            <Card
              raised
              sx={{
                maxWidth: 280,
                maxHeight: 280,
                margin: "0",
                padding: "0.1em",
              }}
            >
              <CardMedia component="img" image={imageUrl} />
            </Card>
            <br />
            <TextField
              // id="outlined-basic"
              helperText="select images"
              variant="outlined"
              type="file"
              // value={imageUrl}
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          </>
        ) : (
          <TextField
            // id="outlined-basic"
            helperText="select images"
            variant="outlined"
            type="file"
            // value={imageUrl}
            inputProps={{
              multiple: true,
            }}
            onChange={handleUploadFile}
          />
        )}
      </Grid>

      {/* <TextField
        // id="outlined-basic"
        helperText="select images"
        variant="outlined"
        type="file"
        value={imageUrl}
        // inputProps={{
        //   multiple: true,
        // }}
        onChange={handleUploadFile}
      /> */}

      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            label="Background Color"
            variant="outlined"
            value={backgroundColor}
            defaultValue="#000000"
            disabled
            sx={{ marginRight: "0.5rem" }}
          />

          <input
            type="color"
            // styles="position: relative; left: 1rem; width: 2rem;"
            // styles="width: 2rem !important;"
            value={backgroundColor}
            defaultValue="#000000"
            onChange={(e) => handleBackgroundColor(e)}
          />
        </Grid> */}

        {/* <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        > */}
          {/* <TextField
            label="Foreground Color"
            variant="outlined"
            value={foreGround}
            disabled
            defaultValue="#000000"
            sx={{ marginRight: "0.5rem" }}
          />

          <input
            type="color"
            value={foreGround}
            onChange={(e) => handleForegroundColor(e)}
            defaultValue="#000000"
          />
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "15rem", position: "relative" }}
            label="Background Opacity"
            variant="outlined"
            value={backgroundOpacity}
            type="number"
            inputProps={{
              maxLength: "1",
              step: "0.1",
              min: "0.0",
              max: "1.0",
            }}
            onChange={(e) =>
              handleDetailsChange("backgroundOpacity", parseInt(e.target.value))
            }
            // fullWidth
          />
        </Grid> */}
        {/* <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "15rem", position: "relative" }}
            label="Foreground Opacity"
            value={foreGroundOpacity}
            variant="outlined"
            type="number"
            inputProps={{
              maxLength: "1",
              step: "0.1",
              min: "0.0",
              max: "1.0",
            }}
            onChange={(e) =>
              handleDetailsChange("foreGroundOpacity", parseInt(e.target.value))
            }
            // fullWidth
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default BannerDetails;
