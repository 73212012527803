import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";

const BannerDetails = ({
  BannerDetails: {
    title,
    description,
    iconUrl,
    numbers
  },
  handleUploadFile,
  handleDetailsChange,
  formType,
}) => {

  const removeFile = () => {
    handleDetailsChange("iconUrl", "");
  };

  return (
    <>
      <Typography className="heading">Statistics Card Details</Typography>
      <TextField
        label="Title"
        variant="outlined"
        value={title}
        onChange={(e) => handleDetailsChange("title", e.target.value)}
        fullWidth
      />
      <TextField
        label="Matrices"
        variant="outlined"
        value={numbers}
        onChange={(e) => handleDetailsChange("numbers", e.target.value)}
        fullWidth
      />
      <TextField
        label="Description"
        variant="outlined"
        value={description}
        onChange={(e) => handleDetailsChange("description", e.target.value)}
        fullWidth
      />
      <Grid item>
        <h4>Image</h4>
        {iconUrl ? (
          <>
            <CancelIcon
              sx={{
                position: "relative",
                top: "1.8rem",
                left: "15.9rem",
                color: "black",
                cursor: "pointer",
              }}
              onClick={removeFile}
            />
            <Card
              raised
              sx={{
                maxWidth: 280,
                maxHeight: 280,
                margin: "0",
                padding: "0.1em",
              }}
            >
              <img src={iconUrl} />
            </Card>
            <br />
            <TextField
              // id="outlined-basic"
              helperText="select images"
              variant="outlined"
              type="file"
              // value={iconUrl}
              inputProps={{
                multiple: true,
              }}
              onChange={handleUploadFile}
            />
          </>
        ) : (
          <TextField
            // id="outlined-basic"
            helperText="select images"
            variant="outlined"
            type="file"
            // value={iconUrl}
            inputProps={{
              multiple: true,
            }}
            onChange={handleUploadFile}
          />
        )}
      </Grid>
    </>
  );
};

export default BannerDetails;
