import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Container,
  TextField,
  Grid,
  Snackbar,
  Typography,
  MenuItem,
  ListSubheader,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchFaqData,
  fetchDestination,
  pageChanged,
  pageLeft,
  pageLimit,
  searchKeyChanged,
  searchLocationChanged,
} from "./faq.slice";
import { getColumns } from "./columns-def";
import "./style.css";

const FaqView = () => {
  const {
    data,
    destinationData,
    loading,
    total,
    currentPage,
    sortingInfo,
    searchKey,
    searchByLocation,
    currentPageLimit,
    remarkStatus,
  } = useSelector((state) => state.faq);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const viewType = params.get("view-type");

  useEffect(() => {
    return () => dispatch(pageLeft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDestination());
  }, []);

  useEffect(() => {
    dispatch(fetchFaqData());
  }, [
    dispatch,
    currentPage,
    viewType,
    sortingInfo,
    searchKey,
    searchByLocation,
    currentPageLimit,
    remarkStatus,
  ]);

  return (
    <Container className="userview-container">
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "2rem" }}>FAQs</Typography>
        <Button
          sx={{ width: "7rem" }}
          onClick={() =>
            navigate("/faq-form?formType=add", {
              state: {
                prevPath: `/faq`,
              },
            })
          }
          variant="contained"
        >
          Add New
        </Button>
      </Grid>

      <Grid
        container
        className="userview-navbar"
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between !important" }}
      >
        <Grid item xs={4} md={4}>
          <TextField
            label={`Search by Question`}
            value={searchKey}
            onChange={(e) => dispatch(searchKeyChanged(e.target.value))}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={4} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel htmlFor="Location">Type</InputLabel>
            <Select
              id="location"
              label="Type"
              onChange={(e) => dispatch(searchLocationChanged(e.target.value))}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="General">General</MenuItem>

              <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                International -
              </ListSubheader>
              {destinationData?.map(
                (items) =>
                  items.domestic === false && (
                    <MenuItem value={items.name}>{items.name}</MenuItem>
                  )
              )}
              <ListSubheader sx={{ color: "#f39b60", fontWeight: "bold" }}>
                Domestic -
              </ListSubheader>
              {destinationData?.map(
                (items) =>
                  items.domestic === true && (
                    <MenuItem value={items.name}>{items.name}</MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <DataGrid
        rows={data ? data : []}
        columns={getColumns(navigate, viewType)}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableColumnMenu
        loading={loading === "pending"}
        paginationMode="server"
        pageSize={currentPageLimit}
        page={currentPage}
        rowCount={total}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
        onPageChange={(newPage) => dispatch(pageChanged(newPage))}
        disableSelectionOnClick
      />
    </Container>
  );
};

export default FaqView;
