import { axiosInstance } from "../../shared/axios";

const addReview = async (payload, id) => {
  try {
    const { status } = await axiosInstance.post(`/reviews/add/${id}`, payload);
    return { isSuccess: status === 201 || status === 200 };
  } catch (e) {

    return {
      error:
        e.response?.data?.message ||
        e.response?.data?.response?.message ||
        e.message,
    };
  }
};

const editReview = async (payload, id) => {
  try {
    const { status } = await axiosInstance.put(
      `/reviews/update/${id}`,
      payload
    );
    return { isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.response?.data?.response?.message || e.message };
  }
};

const packageSuggestion = async (keyword) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/user?suggestion=${keyword}`,
      null
    );
    return { data, isSuccess: status === 200 || status === 201 };
  } catch (e) {
    return { error: e.message };
  }
};

const ReviewFormService = { addReview, editReview, packageSuggestion };
export default ReviewFormService;
