import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SocialLinksService } from "./service";

const initialState = {
  total: 0,
  data: [],
  currentPage: 0,
  currentPageLimit: 25,
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
  searchKey: "",
  searchState: "",
};

export const fetchSocialData = createAsyncThunk(
  "social/fetchSocialData",
  async (viewType, thunkAPI) => {
    const { searchKey, currentPage, currentPageLimit, searchState } =
      thunkAPI.getState().socialLink;
    const payload = {
      currentPage,
      currentPageLimit,
      searchKey,
      searchState,
    };
    const data = await SocialLinksService.fetchSocialData(payload);
    return data;
  }
);

export const deleteSocial = createAsyncThunk(
  "social/deleteSocial",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await SocialLinksService.deleteSocial(id);
    if (isSuccess) {
      thunkAPI.dispatch(fetchSocialData());
    }
    return thunkAPI.rejectWithValue(error);
  }
);
export const updateStatus = createAsyncThunk(
  "social/updateStatus",
  async (id, thunkAPI) => {
    const { isSuccess, error } = await SocialLinksService.updateStatus(id);
    if (isSuccess) {
      thunkAPI.dispatch(fetchSocialData());
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    sortingChanged(state, action) {
      state.sortingInfo = action.payload;
    },
    searchKeyChanged(state, action) {
      state.searchKey = action.payload;
    },
    pageLeft(state) {
      state.searchKey = "";
    },
    searchStateChanged(state, action) {
      state.searchState = action.payload;
    },
    pageLeft(state) {
      state.searchState = "";
    },
    pageLimit(state, action) {
      state.currentPageLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSocialData.pending, (state) => {
      state.loading = "pending";
      state.data = [];
    });
    builder.addCase(fetchSocialData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.totalData;
      state.loading = "succeeded";
    });
  },
});

export const {
  pageChanged,
  sortingChanged,
  searchKeyChanged,
  searchStateChanged,
  pageLeft,
  pageLimit,
} = socialSlice.actions;
export default socialSlice.reducer;
