import { cancellableRequest } from "../../shared/utils";
import { axiosInstance } from "../../shared/axios";
import { getBlockState } from "./utils";
import { adminInfoGetterService } from "../../shared/services";

const fetchTodaysTopDeal = async ({
  currentPageLimit,
  currentPage,
  searchKey,
}) => {
  try {
    const response = await axiosInstance.get(
      `packages/deals/getToursAndDeals?type=topDeals&page=${currentPage}&limit=${currentPageLimit}&title=${searchKey}`,
      null
    );

    return response.data;
  } catch (e) {
    return {
      error: e,
    };
  }
};

export const TodaysTopDealService = {
  fetchTodaysTopDeal,
};
