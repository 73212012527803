import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AchievementService from "./service";

const initialState = {
  achievements: [],
  loading: "idle", // "idle" | "pending" | "succeeded" | "failed",
  error: "",
};

export const fetchTrainerAchievements = createAsyncThunk(
  "achievement/fetchTrainerAchievements",
  async (trainerId, thunkAPI) => {
    const { data, error } = await AchievementService.fetchTrainerAchievements(trainerId);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return data;
  }
);


export const deleteTrainerAchievement = createAsyncThunk(
  "achievement/deleteTrainerAchievement",
  async (achievementId, thunkAPI) => {
    const { isSuccess, error } = await AchievementService.deleteTrainerAchievement(achievementId);
    if (isSuccess) {
      return achievementId;
    }
    return thunkAPI.rejectWithValue(error);
  }
);

const achievementSlice = createSlice({
  name: "achievement",
  initialState,
  reducers: {
    pageLeft: (state) => {
      state.achievement = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainerAchievements.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchTrainerAchievements.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    builder.addCase(fetchTrainerAchievements.fulfilled, (state, action) => {
      state.achievements = action.payload;
      state.loading = "succeeded";
    });
    builder.addCase(deleteTrainerAchievement.fulfilled, (state, action) => {
      state.achievements = state.achievements.filter(
        (achievement) => achievement._id !== action.payload
      );
    });
  },
});

export default achievementSlice.reducer;
export const { pageLeft } = achievementSlice.actions;
