export const defaultBannerDetails = {
  mainTitle: "",
  imageUrl: "",
  supTitle: "",
  subTitle: "",
  backgroundOpacity: null,
  backgroundColor: "",
  foreGround: "",
  foreGroundOpacity: null,
};
